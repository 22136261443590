import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { reportRuleFrequencyOptions } from 'shared/fixtures/data/report-rule.data';
import {
  ReportRuleAction,
  ReportRuleContentGroupType,
  ReportRuleProperty,
} from 'shared/models/data/report-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardReportsDescriptionProps extends BaseComponentProps {}

export const DashboardReportsDescription: FunctionComponent<
  DashboardReportsDescriptionProps
> = () => {
  const [params] = useSearchParams();

  const contentGroupType = useMemo<ReportRuleContentGroupType>(() => {
    return params.get('type') as ReportRuleContentGroupType;
  }, [params.get('type')]);

  const timeframe = useMemo(() => {
    if (params.has('frequencyType')) {
      const frequencyType = params.get('frequencyType');

      return (
        reportRuleFrequencyOptions?.find(
          (option) => option.value === frequencyType
        )?.label || ''
      );
    } else {
      return '';
    }
  }, [params]);

  const filters = useMemo(() => {
    if (params.get('filters')) {
      try {
        return JSON.parse(params.get('filters') as string);
      } catch (err) {
        return [];
      }
    }

    return [];
  }, [params]);

  const renderConfiguration = () => {
    return (
      <ul>
        {filters.map((filter: ReportRuleProperty) => (
          <li>
            {filter.type}:{' '}
            <b>{filter.values?.map((value) => value.label).join(',')}</b>
          </li>
        ))}
        <li>
          Timeframe: <b>{timeframe}</b>
        </li>
      </ul>
    );
  };

  return (
    <div
      className={`dashboard-reports-description ${
        contentGroupType === ReportRuleContentGroupType.RISK
          ? 'dashboard-reports-risk-description'
          : ''
      }`}
    >
      <div className="dashboard-reports-description-title">
        {params.get('name')}
      </div>
      <div className="dashboard-reports-description-body">
        {params.get('description')}
      </div>
      <div className="dashboard-reports-description-configuration">
        {renderConfiguration()}
      </div>
    </div>
  );
};
