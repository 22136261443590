import { AuthorizedContent, useFeatureEntitlements } from '@frontegg/react';
import { Switch, Button, Grid } from '@mui/material';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import CardWrapper from 'Common/components/CardWrapper';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiskCustomizationConfig,
  RiskScoreValuesModel,
  RuleOperatorType,
  RuleProperty,
  RulePropertyInRange,
  RuleStatus,
} from 'Settings/interfaces/RiskCustomizationConfig';
import { RiskCustomizationConfigTypes } from 'Settings/interfaces/RiskCustomizationConfigTypes.enum';
import { settingFiltersLabelMapper } from 'Settings/interfaces/settings-shared-data';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { SlaConfigRuleValues } from 'shared/models/data/sla-config-rule.model';

interface RiskAndSlaCardProps {
  name: string;
  description?: string;
  properties?: any;
  status: RuleStatus;
  id: string;
  useDeleteMutation: UseMutation<any>;
  onEditClick: any;
  attributes?: RiskScoreValuesModel;
  slas?: Partial<SlaConfigRuleValues>;
  onSwitchClicked: any;
  requiredWritePermission: ApplicationPermission;
  isDefault?: boolean;
  disabledTooltipContent: string;
}

export const RiskAndSlaCard: React.FC<RiskAndSlaCardProps> = ({
  name,
  description,
  properties,
  status,
  useDeleteMutation,
  id,
  onEditClick,
  attributes,
  onSwitchClicked,
  requiredWritePermission,
  slas,
  isDefault,
  disabledTooltipContent,
}: RiskAndSlaCardProps) => {
  const { t: translation } = useTranslation();
  const [isEnabled, setIsEnabled] = useState<boolean>(
    status === RuleStatus.ENABLED
  );

  const { isEntitled: isEntitledToIntelligenceFeature } =
    useFeatureEntitlements(SystemFeatureFlag.FINDING_VIEW_INTELLIGENCE_TAB);

  const getLabel = (type?: string) => {
    return (
      settingFiltersLabelMapper.find((item) => item.id === type)?.label || ''
    );
  };

  const renderChip = (label: string, value: string) => (
    <div className="chip">
      <div className="key">
        <p className="body-1">{label}:</p>
      </div>
      <div className="value">
        <p className="label-5 inline-label">{value}</p>
      </div>
    </div>
  );

  const renderAndCondition = (condition: string) => (
    <div>
      <p className="body-1">{condition}</p>
    </div>
  );

  const renderInProperty = (
    item: RuleProperty,
    index: number,
    properties: RuleProperty[]
  ) => {
    const listItems = item.values as Array<any>;
    return (
      <>
        {listItems.map((value, valueIndex) => (
          <React.Fragment key={`${index}-${valueIndex}`}>
            {renderChip(getLabel(item.type as string), value.label)}
            {(index < properties.length - 1 ||
              valueIndex < listItems.length - 1) &&
              renderAndCondition('AND')}
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderInRangeProperty = (
    item: RuleProperty,
    index: number,
    properties: RuleProperty[]
  ) => {
    const rangeValues = item.values as RulePropertyInRange;
    if (!rangeValues) return;
    return (
      <>
        {renderChip(
          getLabel(item.type as string),
          `${rangeValues.start} - ${rangeValues.end}`
        )}
        {index < properties.length - 1 && renderAndCondition('AND')}
      </>
    );
  };
  const renderProperty = (
    item: RuleProperty,
    index: number,
    properties: RuleProperty[]
  ) => {
    switch (item.operator) {
      case RuleOperatorType.IN:
      case RuleOperatorType.HAS_ACCESS:
        return renderInProperty(item, index, properties);
      case RuleOperatorType.IN_RANGE:
        return renderInRangeProperty(item, index, properties);
      default:
        return <></>;
    }
  };
  const renderProperties = (properties: RuleProperty[]) => {
    return properties.map((item: RuleProperty, index: number) => (
      <React.Fragment key={index}>
        {renderProperty(item, index, properties)}
      </React.Fragment>
    ));
  };
  const ActionButton = ({
    isAuthorized,
    isDefault,
    onClick,
    iconType,
  }: any) => (
    <Button
      onClick={onClick}
      className="data-grid-action-button"
      disabled={!isAuthorized || isDefault}
    >
      <OpusSvgIcon type={iconType} />
    </Button>
  );

  const renderSlaChips = () => {
    if (slas)
      return (
        <div className="sla-list">
          <p className="body-2">SLA</p>
          <div className="chips-container">
            {Object.keys(slas)
              .map((key) => Number(key))
              .sort((a, b) => b - a)
              .map((key) => (
                <div className="sla-chip" key={key}>
                  <CommonSeverityChip severityNumber={key} displayShortLabel />
                  <p className="label-4">
                    {Math.floor(
                      (slas[key as unknown as keyof SlaConfigRuleValues] || 0) /
                        3600
                    )}
                    h
                  </p>
                </div>
              ))}
          </div>
        </div>
      );
    return <></>;
  };

  return (
    <>
      <CardWrapper className="display-card">
        <div className="header">
          <Grid display={'flex'} alignItems="center" gap="5px">
            <p className="label-4">{name}</p>
            {isDefault && (
              <div className="default-tag">
                <p className="label-4">{'Default'}</p>
              </div>
            )}
          </Grid>

          {description && <p className="body-1  description">{description}</p>}
        </div>
        <div className="footer-section">
          <div className="filter-and-attributes">
            <div className="filters">
              <p className="body-2">Filters</p>
              <div className="chips-container">
                {properties && properties.length > 0 ? (
                  renderProperties(properties)
                ) : (
                  <p className="body-2">{translation(`common.missingValue`)}</p>
                )}
                <div className="divider"></div>

                {attributes && (
                  <div className="attributes">
                    <p className="body-2">Attributes</p>
                    <div className="chips-container">
                      {RiskCustomizationConfig.filter(
                        (itemConfig) =>
                          itemConfig.type !==
                            RiskCustomizationConfigTypes.vulnerabilityIntelligence ||
                          isEntitledToIntelligenceFeature
                      ).map((item, index) => (
                        <CommonTooltip
                          title={
                            <p className="opus-body-text-1 tooltip-content">
                              {translation(item.title)}
                            </p>
                          }
                        >
                          <div key={index} className="attr-item">
                            <OpusSvgIcon type={item.icon}></OpusSvgIcon>
                            <p>
                              {attributes?.factors
                                ? attributes?.factors[
                                    item.type as keyof typeof RiskCustomizationConfigTypes
                                  ].weight
                                : '0'}
                            </p>
                          </div>
                        </CommonTooltip>
                      ))}
                    </div>
                  </div>
                )}

                {renderSlaChips()}
              </div>
            </div>
          </div>

          <div className="actions-section">
            {isDefault && (
              <CommonTooltip
                title={
                  <p className="opus-body-text-1 tooltip-content">
                    {disabledTooltipContent}
                  </p>
                }
                placement="top"
              >
                <div className="information-badge">
                  <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                </div>
              </CommonTooltip>
            )}
            <AuthorizedContent
              requiredPermissions={[requiredWritePermission]}
              render={(isAuthorized) => (
                <Switch
                  classes={{
                    root: 'opus-switch-field-1 ignore-row-click',
                  }}
                  defaultChecked={isEnabled}
                  onChange={(event, checked) => {
                    setIsEnabled(!isEnabled);
                    onSwitchClicked(checked, id);
                  }}
                  disabled={!isAuthorized || isDefault}
                />
              )}
            ></AuthorizedContent>

            <AuthorizedContent
              requiredPermissions={[requiredWritePermission]}
              render={(isAuthorized) => (
                <ActionButton
                  isAuthorized={isAuthorized}
                  isDefault={false}
                  onClick={() => onEditClick(id)}
                  iconType={SVG_ICON_TYPES.EDIT_ICON}
                />
              )}
            ></AuthorizedContent>

            <AuthorizedContent
              requiredPermissions={[requiredWritePermission]}
              render={(isAuthorized) => (
                <DeleteWithConfirm
                  id={id}
                  useMutation={useDeleteMutation}
                  disabled={isDefault || !isAuthorized}
                  title={translation('common.areYouSure')}
                  callback={function (): void {}}
                  message={`Are you sure you want to delete "${name}"?`}
                >
                  <ActionButton
                    isAuthorized={isAuthorized}
                    isDefault={isDefault}
                    onClick={() => {}}
                    iconType={SVG_ICON_TYPES.TRASH_ICON_OUTLINED}
                  ></ActionButton>
                </DeleteWithConfirm>
              )}
            ></AuthorizedContent>
          </div>
        </div>
      </CardWrapper>
    </>
  );
};
export default RiskAndSlaCard;
