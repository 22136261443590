import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  AutocompleteClasses,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { AutocompleteOption } from 'FindingDetails/store/api';
import CommonIconMui from '../IconMui';
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useFetchFilterByTypeMutation } from 'shared/store/api';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CommonCheckbox } from 'shared/components/CommonCheckbox/CommonCheckbox';

interface AutocompleteProps {
  label?: string;
  model: string;
  onChangeCallBack: (
    model: string,
    value: Array<AutocompleteOption> | AutocompleteOption
  ) => void;
  onOpen?: () => void;
  mapper?: (item: any) => any;
  getIcon?: (option: AutocompleteOption) => React.ReactElement;
  initialSelectedValues?: AutocompleteOption[] | AutocompleteOption;
  optionList?: AutocompleteOption[] | null;
  url?: string;
  single?: boolean;
  classes?: Partial<AutocompleteClasses> & { label?: string };
  urlPrefix?: string;
  placeholder?: string;
  values?: AutocompleteOption[] | AutocompleteOption;
  icons?: {
    ChipRemoveIcon?: any;
    DropdownIcon?: any;
  };
  enableCheckbox?: boolean;
  displayAllTags?: boolean;
  freeSolo?: boolean;
  limitTags?: number;
  areOptionsLoaded?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  getOptionDisabled?: (option: any) => boolean;
  loading?: boolean;
  loadingText?: string;
  onSearch?: (searchKey: string) => void;
  id?: string;
}

const CustomAutocomplete = styled(MuiAutocomplete)({
  '&': {
    width: '100%',
    marginTop: '0 !important',
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #CDD7E2',
      borderRadius: '5px',
      color: '#000000',
      height: '32px',
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 500,
    },
    '& span.MuiAutocomplete-tag': {
      padding: '4px 8px',
    },
  },
});

const CustomOptionsPaper = styled(Paper)({
  '&': {
    borderRadius: '8px',
    background: '#FFFFFF',
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  },
});

export default function Autocomplete({
  label,
  model,
  onChangeCallBack,
  mapper,
  initialSelectedValues = [],
  getIcon,
  optionList = null,
  url,
  single = false,
  classes = {},
  urlPrefix = '',
  placeholder = '',
  onOpen,
  values,
  icons,
  enableCheckbox = false,
  displayAllTags = false,
  freeSolo = false,
  limitTags = 2,
  areOptionsLoaded = false,
  disabled = false,
  readonly = false,
  loadingText,
  getOptionDisabled,
  loading,
  onSearch,
  id,
}: AutocompleteProps) {
  const { t: translation } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);

  const [inputValue, setInputValue] = useState<string>(() => {
    if (single) {
      const singleValue = values as AutocompleteOption;

      return singleValue?.value || '';
    }

    return '';
  });

  useEffect(() => {
    if (single && onSearch) {
      const singleValue = values as AutocompleteOption;

      setInputValue(singleValue?.value || '');
    }
  }, [values]);

  let textFieldRef = useRef<HTMLInputElement>(null);

  const itemMapper = (item: AutocompleteOption) => {
    if (!mapper) return item;
    return {
      ...item,
      label: mapper(item.label ? item.label : item.value),
    };
  };
  const [fetchUniqueOptions, { data: uniqueOptions, isLoading }] =
    useFetchFilterByTypeMutation();

  useEffect(() => {
    if (
      isOpen &&
      !optionList?.length &&
      !uniqueOptions?.length &&
      !areOptionsLoaded
    ) {
      fetchUniqueOptions({
        type: urlPrefix + (url || model),
      });
    }
  }, [optionList, isOpen]);

  useEffect(() => {
    if (values) {
      setSelectedValues(values);
    }
  }, [values]);

  let initialItems = useMemo(() => {
    return mapper && Array.isArray(initialSelectedValues)
      ? initialSelectedValues?.map(itemMapper)
      : initialSelectedValues;
  }, [initialSelectedValues]);

  const [selectedValues, setSelectedValues] = React.useState<
    AutocompleteOption[] | AutocompleteOption
  >(initialItems);

  const handleOpen = () => {
    setIsOpen(true);

    onOpen && onOpen();
  };

  const setData = (options: AutocompleteOption[]) => {
    let newData = options;
    if (mapper) {
      newData = options.map(itemMapper);
    }

    setOptions(newData);
    return newData;
  };

  useEffect(() => {
    let newData;

    if (optionList) {
      newData = setData(optionList);
    }

    if (uniqueOptions && !optionList) {
      newData = setData(uniqueOptions);
    }

    if (newData && selectedValues && !freeSolo && !onSearch) {
      if (Array.isArray(selectedValues)) {
        const currentSelectedValues = selectedValues.map(
          (option) => option.value
        );
        const selectedOptions = newData.filter((option: AutocompleteOption) =>
          currentSelectedValues.includes(option.value)
        );

        setSelectedValues(selectedOptions);
      }
    }
  }, [uniqueOptions, optionList]);

  const onChange = (
    selectedValues: AutocompleteOption[] | AutocompleteOption
  ) => {
    const selectedValueList = selectedValues as Array<AutocompleteOption>;

    const updatedSelectedValues = selectedValueList.length
      ? selectedValueList.map((selectedValue) => {
          if (typeof selectedValue === 'string') {
            return {
              value: selectedValue,
              label: selectedValue,
            };
          }

          return selectedValue;
        })
      : selectedValueList;

    setSelectedValues(updatedSelectedValues);
    onChangeCallBack(model, updatedSelectedValues);

    if (single) {
      setIsOpen(false);

      if (onSearch) {
        const selectedOption = selectedValues as AutocompleteOption;
        setInputValue(selectedOption.label || selectedOption.value);
      }

      if (textFieldRef.current) {
        (textFieldRef.current as HTMLInputElement).blur();
      }
    }
  };

  useEffect(() => {
    let initialItems =
      mapper && Array.isArray(initialSelectedValues)
        ? initialSelectedValues?.map(itemMapper)
        : initialSelectedValues;
    setSelectedValues(initialItems);
  }, []);

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        placeholder={
          placeholder?.length ? placeholder : translation('common.search')
        }
        className="auto-complete-input"
        title={
          !Array.isArray(selectedValues)
            ? selectedValues?.label || selectedValues?.value
            : ''
        }
        disabled={disabled}
        inputRef={textFieldRef}
        InputProps={{
          ...params.InputProps,
          readOnly: true,
          endAdornment: (
            <React.Fragment>
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              <Button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className="auto-complete-expand-button"
                disabled={disabled}
              >
                {icons?.DropdownIcon || (
                  <OpusSvgIcon
                    type={
                      isOpen
                        ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                        : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
                    }
                  />
                )}
              </Button>
            </React.Fragment>
          ),
          ...(single
            ? {
                startAdornment:
                  getIcon && (selectedValues as AutocompleteOption)?.value ? (
                    getIcon(selectedValues as AutocompleteOption)
                  ) : (
                    <></>
                  ),
              }
            : {}),
        }}
        inputProps={{
          ...params.inputProps,
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            if (onSearch) {
              const searchKey = event.target.value;
              onSearch(searchKey);
              setInputValue(searchKey);
              params.inputProps.onChange && params.inputProps.onChange(event);
            } else {
              params.inputProps.onChange && params.inputProps.onChange(event);
            }
          },
          value: onSearch ? inputValue : params.inputProps.value,
        }}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Backspace' && onSearch && inputValue.length > 0) {
            event.stopPropagation();
          }
        }}
      />
    );
  };

  const renderTags = (
    tagValue: unknown[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => {
    if (displayAllTags) {
      return (
        <>
          {tagValue.map((option, index) => {
            const displayedOption =
              typeof option === 'string'
                ? {
                    value: option,
                    label: option,
                  }
                : (option as AutocompleteOption);
            return (
              <Chip
                {...getTagProps({ index })}
                label={
                  displayedOption.label
                    ? displayedOption.label
                    : displayedOption.value
                }
                title={
                  displayedOption.label
                    ? displayedOption.label
                    : displayedOption.value
                }
                avatar={
                  getIcon ? (
                    getIcon(option as AutocompleteOption)
                  ) : (
                    <React.Fragment />
                  )
                }
                deleteIcon={
                  icons?.ChipRemoveIcon || (
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_TIMES_ICON} />
                  )
                }
              />
            );
          })}
        </>
      );
    }
    return (
      <>
        {tagValue.slice(0, limitTags).map((option, index) => {
          const displayedOption =
            typeof option === 'string'
              ? {
                  value: option,
                  label: option,
                }
              : (option as AutocompleteOption);
          return (
            <Chip
              {...getTagProps({ index })}
              label={
                displayedOption.label
                  ? displayedOption.label
                  : displayedOption.value
              }
              title={
                displayedOption.label
                  ? displayedOption.label
                  : displayedOption.value
              }
              avatar={
                getIcon ? (
                  getIcon(option as AutocompleteOption)
                ) : (
                  <React.Fragment />
                )
              }
              deleteIcon={
                icons?.ChipRemoveIcon || (
                  <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_TIMES_ICON} />
                )
              }
            />
          );
        })}
        {tagValue.length > limitTags && (
          <Chip
            {...getTagProps({ index: 1 })}
            label={`${tagValue.length - limitTags}+`}
            deleteIcon={
              icons?.ChipRemoveIcon || (
                <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_TIMES_ICON} />
              )
            }
          />
        )}
      </>
    );
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: unknown
  ) => {
    const displayedOption = option as AutocompleteOption;
    const selected = Array.isArray(selectedValues)
      ? selectedValues.find((item) => item?.value === displayedOption.value)
      : selectedValues?.value === displayedOption.value;
    const disabled = getOptionDisabled ? getOptionDisabled(option) : false;
    const label = displayedOption.label
      ? displayedOption.label
      : displayedOption.value;

    return disabled ? (
      <div>
        <li
          className={`auto-complete-option-box 
        ${disabled ? 'auto-complete-option-box-disabled' : ''}
        `}
          title={label}
        >
          <Stack direction="row" spacing={1}>
            {getIcon && getIcon(option as AutocompleteOption)}
            <Typography>{label}</Typography>
          </Stack>
          {selected && !enableCheckbox && (
            <CommonIconMui icon="done" size={20} color="#6664ED" />
          )}
        </li>
      </div>
    ) : (
      <li
        {...props}
        className={`auto-complete-option-box ${
          selected ? 'auto-complete-selected-option' : ''
        } 
        ${single ? 'auto-complete-option-box-single' : ''}`}
        title={label}
      >
        {enableCheckbox && !single ? (
          <CommonCheckbox checked={Boolean(selected)} />
        ) : (
          <></>
        )}
        <Stack direction="row" spacing={1}>
          {getIcon && getIcon(option as AutocompleteOption)}
          <Typography>{label}</Typography>
        </Stack>
        {selected && !enableCheckbox && (
          <CommonIconMui icon="done" size={20} color="#6664ED" />
        )}
      </li>
    );
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreCase: true,
    stringify: (item: unknown) => {
      const option = item as AutocompleteOption;
      return option.label ? option.label : option.value || '';
    },
  });

  const disableSearch = (options: any) => {
    return options;
  };

  return (
    <div className="auto-complete-container">
      <Typography className={classes.label || 'auto-complete-label-select'}>
        {label}
      </Typography>
      <CustomAutocomplete
        disabled={disabled}
        limitTags={limitTags}
        multiple={!single}
        disableCloseOnSelect
        options={options}
        getOptionLabel={(option: any) => option?.label || ''}
        open={isOpen}
        openOnFocus={true}
        onOpen={handleOpen}
        onClose={() => setIsOpen(false)}
        PaperComponent={CustomOptionsPaper}
        value={
          single
            ? selectedValues
            : (selectedValues as any).length
            ? selectedValues
            : []
        }
        isOptionEqualToValue={(option, value) => {
          return (
            (option as AutocompleteOption).value ===
            (value as AutocompleteOption).value
          );
        }}
        onChange={(event: any, newSelectedValues: any) => {
          onChange(newSelectedValues as AutocompleteOption[]);
        }}
        filterOptions={onSearch ? disableSearch : filterOptions}
        renderInput={renderInput}
        renderTags={renderTags}
        renderOption={renderOption}
        noOptionsText={translation('common.noOptions')}
        classes={{
          ...({
            ...classes,
            root: `${classes.root || ''} ${
              readonly ? 'auto-complete-root-readonly' : ''
            }`,
          } || {}),
        }}
        getOptionDisabled={getOptionDisabled}
        freeSolo={freeSolo}
        readOnly={readonly}
        loading={loading}
        loadingText={loadingText}
        id={id}
      />
    </div>
  );
}
