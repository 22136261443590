export enum SVG_ICON_TYPES {
  ALERT_SHIELD_ICON = 'ALERT_SHIELD_ICON',
  AWS_MAIN_ICON = 'AWS_MAIN_ICON',
  AWS_CONFIG_MAIN_ICON = 'AWS_CONFIG_MAIN_ICON',
  AZURE_MAIN_ICON = 'AZURE_MAIN_ICON',
  RHOMBUS_WITH_A_HOLE_ICON = 'RHOMBUS_WITH_A_HOLE_ICON',
  OPUS_MAIN_ICON = 'OPUS_MAIN_LOGO_ICON',
  TIMES_ICON = 'TIMES_ICON',
  CONTAINER_ICON = 'CONTAINER_ICON',
  GOOGLE_CLOUD_MAIN_ICON = 'GOOGLE_CLOUD_MAIN_ICON',
  GROUP_WITH_PEOPLE_ICON = 'GROUP_WITH_PEOPLE_ICON',
  PLATFORM_ICON = 'PLATFORM_ICON',
  RED_FLAG_ICON = 'RED_FLAG_ICON',
  SETTINGS_COG_ICON = 'SETTINGS_COG_ICON',
  SETTINGS_WHEEL_ICON = 'SETTINGS_WHEEL_ICON',
  CLOSE = 'CLOSE',
  ARROW_UP = 'ARROW_UP',
  ARROW_DOWN = 'ARROW_DOWN',
  STACK_ICON = 'STACKS_ICON',
  SUCCESS_CHECK_ICON = 'SUCCESS_CHECK_ICON',
  SEVERITY_ICON = 'SEVERITY_ICON',
  CHECKED_ICON = 'CHECKED_ICON',
  UNCHECKED_ICON = 'UNCHECKED_ICON',
  TRIGGER_ICON = 'TRIGGER_ICON',
  EMPTY_TRIGGER_ICON = 'EMPTY_TRIGGER_ICON',
  SELECTED_TRIGGER_ICON = 'SELECTED_TRIGGER_ICON',
  RULE_CONTROL_ICON = 'RULE_CONTROL_ICON',
  TRASH_ICON = 'TRASH_ICON',
  INFO_ICON = 'INFO_ICON',
  PLUS_BUTTON_ICON = 'PLUS_BUTTON_ICON',
  PLUS_ICON = 'PLUS_ICON',
  SHIELD_CHECK_ICON = 'SHIELD_CHECK_ICON',
  GIT_ICON = 'GIT_ICON',
  GIT_PULL_REQUEST_ICON = 'GIT_PULL_REQUEST_ICON',
  TERRAFORM_ICON = 'TERRAFORM_ICON',
  CLOUD_SOURCE_ICON = 'CLOUD_SOURCE_ICON',
  IAC_SOURCE_ICON = 'IAC_SOURCE_ICON',
  CONTACT_US = 'CONTACT_US',
  SLACK_MAIN_ICON = 'SLACK_MAIN_ICON',
  GMAIL_MAIN_ICON = 'GMAIL_MAIN_ICON',
  ONE_LOGIN_ICON = 'ONE_LOGIN_ICON',
  ANCHOR_ICON = 'ANCHOR_ICON',
  APPLICATION_ICON = 'APPLICATION_ICON',
  GUARD_RAILS_COUNTER_ICON = 'GUARD_RAILS_ICON',
  FINDINGS_COUNTER_ICON = 'FINDINGS_COUNTER_ICON',
  CODE_VS_CLOUD_COUNTER_ICON = 'CODE_VS_CLOUD_COUNTER_ICON',
  SLA_COUNTER_ICON = 'SLA_COUNTER_ICON',
  ERROR_BASE_ICON = 'ERROR_BASE_ICON',
  SUCCESS_BASE_ICON = 'SUCCESS_BASE_ICON',
  CARET_RIGHT_ICON = 'CARET_RIGHT_ICON',
  CARET_LEFT_ICON = 'CARET_LEFT_ICON',
  CARET_DOWN_ICON = 'CARET_DOWN_ICON',
  SORT_GROUP_ICON = 'SORT_GROUP_ICON',
  FILTER_TOGGLE_ICON = 'FILTER_TOGGLE_ICON',
  CIRCULAR_PLUS_BUTTON_ICON = 'CIRCULAR_PLUS_BUTTON_ICON',
  SORT_ASCENDING_ICON = 'SORT_ASCENDING_ICON',
  SORT_DESCENDING_ICON = 'SORT_DESCENDING_ICON',
  FILTER_CLOSE_ICON = 'FILTER_CLOSE_ICON',
  TRIANGLE_EXCLAMATION_ICON = 'TRIANGLE_EXCLAMATION_ICON',
  GITHUB_MAIN_ICON = 'GITHUB_MAIN_ICON',
  JIRA_MAIN_ICON = 'JIRA_MAIN_ICON',
  MEDIA_PLAY_ICON = 'MEDIA_PLAY_ICON',
  AZURE_DEVOPS_ICON = 'AZURE_DEVOPS_ICON',
  BRAKE_WARNING_ICON = 'BRAKE_WARNING_ICON',
  CALENDAR_STAR_ICON = 'CALENDAR_STAR_ICON',
  CALENDAR_ICON = 'CALENDAR_ICON',
  CHECK_ICON = 'CHECK_ICON',
  TOOLS_ICON = 'TOOLS_ICON',
  NOTES_ICON = 'NOTES_ICON',
  ARROW_DOWN_ICON = 'ARROW_DOWN_ICON',
  ARROW_UP_ICON = 'ARROW_UP_ICON',
  SIMPLE_CALENDAR_ICON = 'SIMPLE_CALENDAR_ICON',
  MAGNIFYING_ZOOM = 'MAGNIFYING_ZOOM',
  GEAR_ICON = 'GEAR_ICON',
  CHEVRON_UP_ICON = 'CHEVRON_UP_ICON',
  CHEVRON_DOWN_ICON = 'CHEVRON_DOWN_ICON',
  CHEVRON_RIGHT_ICON = 'CHEVRON_RIGHT_ICON',
  CHECKED_CHECKBOX_ICON = 'CHECKED_CHECKBOX_ICON',
  UNCHECKED_CHECKBOX_ICON = 'UNCHECKED_CHECKBOX_ICON',
  INDETERMINATE_CHECKBOX_ICON = 'INDETERMINATE_CHECKBOX_ICON',
  EXPAND_MORE_ICON = 'EXPAND_MORE_ICON',
  BUCKET_ICON = 'BUCKET_ICON',
  CLOCK_ICON = 'CLOCK_ICON',
  ELLIPSIS_ICON = 'ELLIPSIS_ICON',
  CAMPAIGN_ICON = 'CAMPAIGN_ICON',
  TICKET_ICON = 'TICKET_ICON',
  TIMES_SECONDARY_ICON = 'TIMES_SECONDARY_ICON',
  CIRCLE_TIMES_ICON = 'CIRCLE_TIMES_ICON',
  STOP_WATCH_ICON = 'STOP_WATCH_ICON',
  MAGNIFYING_GLASS_ICON = 'MAGNIFYING_GLASS_ICON',
  LIFECYCLE_CODE_ICON = 'LIFECYCLE_CODE_ICON',
  LIFECYCLE_RUNTIME_ICON = 'LIFECYCLE_RUNTIME_ICON',
  LIFECYCLE_ARTIFACT_ICON = 'LIFECYCLE_ARTIFACT_ICON',
  CODE_FORK_ICON = 'CODE_FORK_ICON',
  USER_ICON = 'USER_ICON',
  USER_ADD_ICON = 'USER_ADD_ICON',
  CHART_NETWORK_ICON = 'CHART_NETWORK_ICON',
  DATABASE_ICON = 'DATABASE_ICON',
  SATELLITE_DISH_ICON = 'SATELLITE_DISH_ICON',
  USER_GROUP_ICON = 'USER_GROUP_ICON',
  PEOPLE_GROUP_ICON = 'PEOPLE_GROUP_ICON',
  HEXAGON_EXCLAMATION_ICON = 'HEXAGON_EXCLAMATION_ICON',
  HUG_ICON = 'HUG_ICON',
  INFO_CIRCLE_ICON = 'INFO_CIRCLE_ICON',
  CLOSE_ICON = 'CLOSE_ICON',
  ALTERNATIVE_PLUS_ICON = 'ALTERNATIVE_PLUS_ICON',
  ALTERNATIVE_TRASH_ICON = 'ALTERNATIVE_TRASH_ICON',
  CIRCLE_EXCLAMATION_ICON = 'CIRCLE_EXCLAMATION_ICON',
  CIRCLE_EXCLAMATION_BASE_ICON = 'CIRCLE_EXCLAMATION_BASE_ICON',
  CROWDSTRIKE_ICON = 'CROWDSTRIKE_ICON',
  CYERA_ICON = 'CYERA_ICON',
  ADD_TEAM_ICON = 'ADD_TEAM_ICON',
  TRASH_ICON_OUTLINED = 'TRASH_ICON_OUTLINED',
  COPY_ICON = 'COPY_ICON',
  COPY_ICON_GRAY = 'COPY_ICON_GRAY',
  RADIO_BUTTON_CHECKED = 'RADIO_BUTTON_CHECKED',
  RADIO_BUTTON_UNCHECKED = 'RADIO_BUTTON_UNCHECKED',
  MESSAGE_ICON = 'MESSAGE_ICON',
  CLOUD_ICON = 'CLOUD_ICON',
  SHIELD_VIRUS_ICON = 'SHIELD_VIRUS_ICON',
  CLIPBOARD_LIST_CHECK_ICON = 'CLIPBOARD_LIST_CHECK_ICON',
  ENVELOPE_ICON = 'ENVELOPE_ICON',
  ARROW_LEFT_ICON = 'ARROW_LEFT_ICON',
  LIST_CHECK_ICON = 'LIST_CHECK_ICON',
  BADGE_CHECK_ICON = 'BADGE_CHECK_ICON',
  ELLIPSIS_STROKE_ICON = 'ELLIPSIS_STROKE_ICON',
  SECURITY_HUB_ICON = 'SECURITY_HUB_ICON',
  WIZ_ICON = 'WIZ_ICON',
  ORCA_ICON = 'ORCA_ICON',
  GITHUB_ICON = 'GITHUB_ICON',
  JIRA_ICON = 'JIRA_ICON',
  BOOK_SPARKLES_ICON = 'BOOK_SPARKLES_ICON',
  CLIPBOARD_COPY_ICON = 'CLIPBOARD_COPY_ICON',
  SHIELD_KEYHOLE_ICON = 'SHIELD_KEYHOLE_ICON',
  EDIT_ICON = 'EDIT_ICON',
  STAR_ICON = 'STAR_ICON',
  DEPLOY_ICON = 'DEPLOY_ICON',
  PLUG_ICON = 'PLUG_ICON',
  BRIEFCASE_ICON = 'BRIEFCASE_ICON',
  CROSSHAIRS_ICON = 'CROSSHAIRS_ICON',
  SQUARE_SMALL_ICON = 'SQUARE_SMALL_ICON',
  SLIDERS_ICON = 'SLIDERS_ICON',
  MICROSOFT_TEAMS_ICON = 'MICROSOFT_TEAMS_ICON',
  SITEMAP_ICON = 'SITEMAP_ICON',
  SITEMAP_BLACK_ICON = 'SITEMAP_BLACK_ICON',
  LIGHT_EMERGENCY_ICON = 'LIGHT_EMERGENCY_ICON',
  CIRCLE_INFO_ICON = 'CIRCLE_INFO_ICON',
  RADIO_BUTTON_CHECKED_ICON = 'RADIO_BUTTON_CHECKED_ICON',
  RADIO_BUTTON_UNCHECKED_ICON = 'RADIO_BUTTON_UNCHECKED_ICON',
  RERUN_ICON = 'RERUN_ICON',
  LIGHTBULB_ICON = 'LIGHTBULB_ICON',
  HOURGLASS_ICON = 'HOURGLASS_ICON',
  RADAR_ICON = 'RADAR_ICON',
  RADAR_GRAY_ICON = 'RADAR_GRAY_ICON',
  LINK_SLASH_ICON = 'LINK_SLASH_ICON',
  TAGS_ICON = 'TAGS_ICON',
  LOCK_OPEN_ICON = 'LOCK_OPEN_ICON',
  SCREWDRIVER_WRENCH_ICON = 'SCREWDRIVER_WRENCH_ICON',
  SIREN_ON_ICON = 'SIREN_ON_ICON',
  TELESCOPE_ICON = 'TELESCOPE_ICON',
  CHART_SIMPLE_ICON = 'CHART_SIMPLE_ICON',
  CHART_PIE_ICON = 'CHART_PIE_ICON',
  BULLHORN_ICON = 'BULLHORN_ICON',
  CHECK_TO_SLOT_ICON = 'CHECK_TO_SLOT_ICON',
  FILE_EXCLAMATION_ICON = 'FILE_EXCLAMATION_ICON',
  BALLOT_CHECK_ICON = 'BALLOT_CHECK_ICON',
  CLIPBOARD_CHECK_ICON = 'CLIPBOARD_CHECK_ICON',
  PERCENTAGE_ICON = 'PERCENTAGE_ICON',
  POSTAL_ICON = 'POSTAL_ICON',
  BUG_ICON = 'BUG_ICON',
  CUBES_ICON = 'CUBES_ICON',
  FINGERPRINT_ICON = 'FINGERPRINT_ICON',
  GRADUATION_CAP_ICON = 'GRADUATION_CAP_ICON',
  SENSOR_ICON = 'SENSOR_ICON',
  BOX_ICON = 'BOX_ICON',
  SQUARE_CODE_ICON = 'SQUARE_CODE_ICON',
  FILE_CODE_ICON = 'FILE_CODE_ICON',
  USER_PEN_ICON = 'USER_PEN_ICON',
  VISIBILITY_ICON = 'VISIBILITY_ICON',
  MULTI_TICKET_ICON = 'MULTI_TICKET_ICON',
  FEATHER_ICON = 'FEATHER_ICON',
  CIRCLE_CHECK_ICON = 'CIRCLE_CHECK_ICON',
  CALENDAR_CLOCK_ICON = 'CALENDAR_CLOCK_ICON',
  HOURGLASS_CLOCK_ICON = 'HOURGLASS_CLOCK_ICON',
  GAUGE_CIRCLE_BOLT_ICON = 'GAUGE_CIRCLE_BOLT_ICON',
  ARROW_UP_RIGHT_FROM_SQUARE_ICON = 'ARROW_UP_RIGHT_FROM_SQUARE_ICON',
  EYES_ICON = 'EYES_ICON',
  SQUARE_INFO_ICON = 'SQUARE_INFO_ICON',
  CODE_ICON = 'CODE_ICON',
  SQUARE_TERMINAL_ICON = 'SQUARE_TERMINAL_ICON',
  OCTAGON_CHECK_ICON = 'OCTAGON_CHECK_ICON',
  CURLY_BRACKETS_ICON = 'CURLY_BRACKETS_ICON',
  CARET_BOLD_RIGHT_ICON = 'CARET_BOLD_RIGHT_ICON',
  CARET_BOLD_DOWN_ICON = 'CARET_BOLD_DOWN_ICON',
  STAR_CHRISTMAS_ICON = 'STAR_CHRISTMAS_ICON',
  CUBE_ICON = 'CUBE_ICON',
  SERVER_ICON = 'SERVER_ICON',
  BOLT_LIGHTNING_ICON = 'BOLT_LIGHTNING_ICON',
  SQUARE_DASHED_CIRCLE_PLUS_ICON = 'SQUARE_DASHED_CIRCLE_PLUS_ICON',
  FLOPPY_DISK_ICON = 'FLOPPY_DISK_ICON',
  NETWORK_WIRED_ICON = 'NETWORK_WIRED_ICON',
  SENSOR_CLOUD_ICON = 'SENSOR_CLOUD_ICON',
  CIRCLE_DOT_ICON = 'CIRCLE_DOT_ICON',
  CIRCLE_ICON = 'CIRCLE_ICON',
  PLAY_ICON = 'PLAY_ICON',
  FILE_CSV_ICON = 'FILE_CSV_ICON',
  FILE_IMPORT_ICON = 'FILE_IMPORT_ICON',
  UPLOAD_ICON = 'UPLOAD_ICON',
  RIBBON_ICON = 'RIBBON_ICON',
  ARROW_TREND_UP = 'ARROW_TREND_UP',
  SHIELD_EXLAMATION_ICON = 'SHIELD_EXLAMATION_ICON',
  MESSAGES_ICON = 'MESSAGES_ICON',
  LAPTOP_CODE_ICON = 'LAPTOP_CODE_ICON',
  UNLOCK_ICON = 'UNLOCK_ICON',
  BIOHAZARD_ICON = 'BIOHAZARD_ICON',
  SKULL_ICON = 'SKULL_ICON',
  HOOD_CLOAK_ICON = 'HOOD_CLOAK_ICON',
  SACK_DOLLAR_ICON = 'SACK_DOLLAR_ICON',
  WRENCH_ICON = 'WRENCH_ICON',
  ELLIPSE_ICON = 'ELLIPSE_ICON',
  CLIPBOARD_LIST_ICON = 'CLIPBOARD_LIST_ICON',
  FILE_ICON = 'FILE_ICON',
  BOMB_ICON = 'BOMB_ICON',
  BROKEN_CHAIN_ICON = 'BROKEN_CHAIN_ICON',
  MASK_ICON = 'MASK_ICON',
  INTELLIGENCE_ICON = 'INTELLIGENCE_ICON',
  BUILDINGS_ICON = 'BUILDINGS_ICON',
  CISA_ICON = 'CISA_ICON',
}

export enum MUI_ICON_TYPES {}

export enum AVATAR_ICON_TYPES {}
