import {
  CategoryState,
  ExtendedFilterCategory,
  FilterType,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import * as yup from 'yup';
import { FilterOption as DataFilterOption } from 'shared/models/data/data-filter.model';
import { ConfigurationFieldValue } from 'shared/components/MultiSelectConfigurationField/MultiSelectConfigurationField';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { StaticFilterOptions } from 'shared/components/FilterDropdown/FilterDropdown';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { RuleCategory } from 'Settings/interfaces/RiskCustomizationConfig';

const dataFilterHandler = new DataFitlerHandler();

const booleanRiskFilterOptions: Array<DataFilterOption> = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export type SlaRuleBaseFormData = Record<'name' | 'description', string>;

export type SlaRuleAdditionalFormData = {
  properties: Record<string, CategoryState>;
  values: Array<ConfigurationFieldValue>;
};

export type SlaRuleFormData = SlaRuleBaseFormData & SlaRuleAdditionalFormData;

export const slaRuleSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  properties: yup.array().required(),
  values: yup.object().required(),
});

export const slaRuleInRangePropertyNames = [
  'intelligenceScore',
  'epssScore',
  'epssPercentile',
  'riskScore',
];
export const requiredDefaultFieldOptions = [
  FindingSeverity.CRITICAL,
  FindingSeverity.HIGH,
  FindingSeverity.MEDIUM,
];

export const slaRuleConditionOptions: Array<ExtendedFilterCategory> = [
  {
    id: RuleCategory.ORGANIZATION,
    label: 'Organization',
    categories: [
      {
        id: 'scope',
        label: 'Scope',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'organization-structures/getUserAvailableNodesOfType',
            body: {
              nodesType: 'scope',
            },
            optionsTransformer: (options: Array<OrganizationNode>) => {
              return options.map((option: OrganizationNode) => ({
                value: option.id,
                label: option.name,
              }));
            },
          },
        },
      },
      {
        id: 'group',
        label: 'Group',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'organization-structures/getUserAvailableNodesOfType',
            body: {
              nodesType: 'logical',
            },
            optionsTransformer: (options: Array<OrganizationNode>) => {
              return options.map((option: OrganizationNode) => ({
                value: option.id,
                label: option.name,
              }));
            },
          },
        },
      },
      {
        id: 'businessUnitId',
        label: 'Service',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/businessUnitId',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
      {
        id: `cloudWorkspaceId`,
        label: 'Workspace',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/workspace',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
    ],
  },
  {
    id: RuleCategory.INTELLIGENCE,
    label: 'Intelligence',
    categories: [
      {
        id: 'isDiscussed',
        label: 'Is Discussed',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPOC',
        label: 'POC',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasExploit',
        label: 'Has Exploit',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isExploitedInTheWild',
        label: 'In the Wild',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPenTesterFramework',
        label: 'Pentest Framework',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'cisaKev',
        label: 'Cisa Kev',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasRansomware',
        label: 'Ransomware',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasThreatActor',
        label: 'Threat Actors',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isTrending',
        label: 'Trending',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasMalware',
        label: 'Malware',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPatch',
        label: 'Has Patch',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isPrioritized',
        label: 'Prioritized',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'intelligenceScore',
        label: 'Intelligence Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 10,
        },
      },
      {
        id: 'epssScore',
        label: 'EPSS Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 1,
        },
      },
      {
        id: 'epssPercentile',
        label: 'EPSS Percentile',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 1,
        },
      },
    ],
  },
  {
    id: RuleCategory.FINDING,
    label: 'Risk',
    categories: [
      {
        id: 'title',
        label: 'Risk Name',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'config/filters/search/title',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'findingType',
        label: 'Domain',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/findingType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'category',
        label: 'Risk Category',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/category',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'riskScore',
        label: 'Risk Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 10,
        },
      },
      {
        id: 'findingSource',
        label: 'Event Source',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/findingSource',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
    ],
  },
  {
    id: RuleCategory.RESOURCE,
    label: 'Resource',
    categories: [
      {
        id: 'resourceType',
        label: 'Resource Type',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/resourceType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
    ],
  },
];
