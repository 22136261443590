import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import { FunctionComponent, useEffect } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import VerticalSimpleBarChart from 'shared/components/VerticalSimpleBarChart';
import DashboardChartCard from 'Dashboard/components/DashboardChartCard';
import { useGetDashboardPostureAnalyticsMutation } from 'Dashboard/store/api';
import { ChartOptions } from 'chart.js';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import DoughnutChartWidget from 'shared/components/DoughnutChartWidget';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';

interface DashboardReportsRiskFindingByCategoryWidgetProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {}

export const DashboardReportsRiskFindingByCategoryWidget: FunctionComponent<
  DashboardReportsRiskFindingByCategoryWidgetProps
> = ({ businessUnitIds }) => {
  const [
    getByCategoryWidgetData,
    { data: byCategoryWidgetData, isLoading: byCategoryWidgetDataLoading },
  ] = useGetDashboardPostureAnalyticsMutation();

  useEffect(() => {
    getByCategoryWidgetData({
      businessUnitIds: businessUnitIds,
      findingTypes: [],
      type: PostureWidgetAnalyticsType.ACCUMULATIVE_FINDINGS_BY_CATEGORY,
    });
  }, [businessUnitIds]);

  const renderChartWidget = (widgetData: any, isLoading: boolean) => {
    if ((!widgetData || widgetData?.datasets.length == 0) && !isLoading) {
      return <NoDataToDisplayCard displayIcon={true} />;
    }

    return (
      <>
        <DoughnutChartWidget
          fetchWidgetData={() => {}}
          isWidgetDataLoading={isLoading}
          widgetData={widgetData}
          aspectRatio={1.3}
          style={
            presentationalConfigurationForWidgets['posture_by_category'].style
          }
          applyColorList
          cutoutPercentage={45}
          customLegendContainerId="posture-dashboard-section-by-category-chart-legend-container"
          options={
            presentationalConfigurationForWidgets['posture_by_category']
              .options as ChartOptions<'doughnut'>
          }
        />
        {!isLoading && (
          <div id="posture-dashboard-section-by-category-chart-legend-container"></div>
        )}
      </>
    );
  };
  return (
    <div className="dashboard-reports-risk-trends-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.CHART_SIMPLE_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Findings by Category
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-trends-widget-body">
        <DashboardChartCard
          title=""
          className="posture-dashboard-section-by-category-chart-container"
        >
          {renderChartWidget(byCategoryWidgetData, byCategoryWidgetDataLoading)}
        </DashboardChartCard>
      </div>
    </div>
  );
};
