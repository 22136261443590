import { FilterOption } from 'shared/models/data/data-filter.model';

export class DataFitlerHandler {
  transformApiFilterOptionsPayload(payload: any): Array<FilterOption> {
    return payload.data
      ?.filter((dataItem: any) => dataItem?.value)
      ?.map((dataItem: any) => ({
        value: dataItem.value,
        label: dataItem.label || dataItem.value,
      }));
  }

  sortFilterOptions(
    options: Array<FilterOption>,
    priorityOptionValues?: Array<string>
  ): Array<FilterOption> {
    return options.sort((optionA, optionB) => {
      if (priorityOptionValues?.length) {
        const comparatorA = optionA.label || optionA.value;

        const comparatorB = optionB.label || optionB.value;

        const indexA = priorityOptionValues.findIndex(
          (item: string) => item === comparatorA
        );
        const indexB = priorityOptionValues.findIndex(
          (item: string) => item === comparatorB
        );

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        if (indexA !== -1) {
          return -1;
        }

        if (indexB !== -1) {
          return 1;
        }
      }

      const optionAProperty: string = optionA.label || optionA.value;

      const optionBProperty: string = optionB.label || optionB.value;

      return optionAProperty?.localeCompare(optionBProperty);
    });
  }

  searchFilterOptions(
    options: Array<FilterOption>,
    searchValue: string
  ): Array<FilterOption> {
    if (searchValue.length === 0) return options;

    return options.filter((option: FilterOption) =>
      (option.label || option.value)
        ?.toLowerCase()
        .includes(searchValue.toLowerCase().trim())
    );
  }
}
