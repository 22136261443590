import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { AgGridReactProps, AgGridReact } from 'ag-grid-react';
import {
  GridReadyEvent,
  ColDef,
  ColGroupDef,
  GridApi,
  ColumnApi,
  SortChangedEvent,
  SelectionChangedEvent,
  GetRowIdParams,
  FirstDataRenderedEvent,
  ColumnMovedEvent,
  ColumnResizedEvent,
} from 'ag-grid-community';
import CommonPagination from 'Common/components/Pagination';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { PaginationProps } from 'Common/components/Pagination/CommonPagination';
import {
  AdvancedGridVisibilityPanel,
  ColumnVisibilityDef,
} from '../AdvancedGridVisibilityPanel/AdvancedGridVisibilityPanel';
import { OrderState } from 'Common/utils/sort';
import { IRowNode } from '@ag-grid-community/core';

export interface VisibilityControlColumn {
  value: string;
  label: string;
}

export interface VisibilityControlProps {
  enableVisibilityControls?: boolean;
  columns?: Array<VisibilityControlColumn>;
}

export interface ColumnGetterFunctionParams extends VisibilityControlProps {}

export interface CommonSimpleDataGridProps extends AgGridReactProps {
  containerClassName?: string;
  paginationProps?: PaginationProps;
  visibilityControlProps?: VisibilityControlProps;
  isLoading?: boolean;
  onSort?: (sortedColumns: Array<OrderState>) => void;
  onSelect?: (selectedIds: Array<string>) => void;
  sortModel?: OrderState;
  selectionModel?: Array<string>;
  keepCurrentSelections?: boolean;
  getRowNavigationUrl?: (rowData?: any) => string;
  otherComponents?: {
    NoDataBackdropComponent?: JSX.Element;
  };
  gridRef?: any;
  isRowNavigable?: boolean;
}

export const CommonSimpleDataGrid: FunctionComponent<
  CommonSimpleDataGridProps
> = ({
  containerClassName,
  paginationProps,
  visibilityControlProps,
  isLoading = false,
  onSort,
  onSelect,
  sortModel,
  selectionModel,
  keepCurrentSelections = false,
  getRowNavigationUrl,
  otherComponents,
  gridRef,
  isRowNavigable,
  ...otherProps
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumns, setGridColumns] = useState<Array<ColDef | ColGroupDef>>(
    otherProps.columnDefs as Array<ColDef>
  );

  useEffect(() => {
    if (otherProps.columnDefs) {
      setGridColumns(otherProps.columnDefs);
    }
  }, [otherProps.columnDefs]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    otherProps.onGridReady && otherProps.onGridReady(params);
  };

  const toggleColumn = (columnId: string) => {
    const isVisible = !(
      gridColumns.find(
        (gridColumn) => (gridColumn as ColDef).field === columnId
      ) as ColDef
    )?.hide;

    setGridColumns((prevGridColumns) =>
      prevGridColumns.map((gridColumn: ColDef) => {
        if (gridColumn.field === columnId) {
          return {
            ...gridColumn,
            hide: isVisible,
          };
        }
        return gridColumn;
      })
    );
  };

  const visibilityOptions = useMemo<Array<ColumnVisibilityDef>>(() => {
    if (visibilityControlProps?.columns?.length) {
      return visibilityControlProps?.columns?.map((column) => {
        const isVisible = !(
          gridColumns.find(
            (gridColumn) => (gridColumn as ColDef).field === column.value
          ) as ColDef
        )?.hide;

        return {
          ...column,
          checked: isVisible,
        };
      });
    }

    return [];
  }, [visibilityControlProps?.columns, gridColumns]);

  function removeElement(element: any) {
    while (element.firstChild) {
      removeElement(element.firstChild);
      element.removeChild(element.firstChild);
    }
  }

  useEffect(() => {
    if (isLoading) {
      gridRef?.current?.api?.showLoadingOverlay();
    } else {
      gridRef?.current?.api?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  const populateDefaultSelections = () => {
    const api = gridRef?.current?.api;

    if (api) {
      if (selectionModel?.length) {
        const selected: Array<IRowNode> = [];
        const deselected: Array<IRowNode> = [];

        api.forEachNode((rowNode: IRowNode) => {
          const isRowNodeSelected = selectionModel.includes(
            rowNode.id as string
          );

          isRowNodeSelected ? selected.push(rowNode) : deselected.push(rowNode);
        });

        api.setNodesSelected({
          nodes: selected,
          newValue: true,
        });

        api.setNodesSelected({
          nodes: deselected,
          newValue: false,
        });
      } else {
        api?.deselectAll('rowDataChanged');
      }
    }
  };

  const populateDefaultSort = (eventColumnApi: ColumnApi) => {
    if (eventColumnApi && sortModel) {
      eventColumnApi.applyColumnState({
        state: [
          {
            colId: sortModel.field,
            sort: sortModel.type.toLocaleLowerCase() as
              | 'desc'
              | 'asc'
              | null
              | undefined,
          },
        ],
      });
    }
  };

  const firstDataRenderedHandler = (event: FirstDataRenderedEvent) => {
    populateDefaultSort(event.columnApi);
  };

  const rowDataUpdatedHandler = () => {
    populateDefaultSelections();
  };

  useEffect(() => {
    populateDefaultSelections();
  }, [selectionModel]);

  const sortHandler = (event: SortChangedEvent) => {
    const sortedColumns = (event?.api?.getColumnDefs() as Array<ColDef>)
      ?.filter((column: ColDef) => column.sort)
      ?.map((column: ColDef) => {
        return {
          field: column.field,
          type: column.sort?.toUpperCase(),
        };
      }) as Array<OrderState>;

    onSort && onSort(sortedColumns);
  };

  const selectionHandler = (event: SelectionChangedEvent) => {
    if (keepCurrentSelections && event.source === 'rowDataChanged') {
      return;
    }

    let cachedSelections: Array<string> = [];

    if (keepCurrentSelections) {
      for (const selectedItem of selectionModel || []) {
        const rowNode = gridApi?.getRowNode(selectedItem);

        if (rowNode === undefined) {
          cachedSelections.push(selectedItem);
        }
      }
    }

    const selectedItems = event.api.getSelectedRows();

    const selectedIds = selectedItems.map((selectedItem) =>
      otherProps.getRowId
        ? otherProps.getRowId({ data: selectedItem } as GetRowIdParams)
        : selectedItem.id
    );

    onSelect && onSelect([...cachedSelections, ...selectedIds]);
  };

  const columnMoveHandler = (event: ColumnMovedEvent) => {
    if (event.finished) {
      const newColumnOrder = event.columnApi
        .getAllGridColumns()
        .map((column) => column.getColId());

      setGridColumns((prevGridColumns) => {
        return newColumnOrder.map((columnId) => {
          const column = prevGridColumns.find(
            (column: ColDef) => column.field === columnId
          );

          return column as ColDef;
        });
      });
    }

    otherProps.onColumnMoved && otherProps.onColumnMoved(event);
  };

  const columnResizeHandler = (event: ColumnResizedEvent) => {
    if (event.finished) {
      setGridColumns((prevGridColumns) => {
        return prevGridColumns.map((gridColumn: ColDef) => {
          if (gridColumn.field === event.column?.getId()) {
            return {
              ...gridColumn,
              width: event.column?.getActualWidth(),
            };
          }

          return gridColumn;
        });
      });
    }

    otherProps.onColumnResized && otherProps.onColumnResized(event);
  };

  const renderGrid = () => {
    if (!isLoading && !otherProps?.rowData?.length) {
      return otherComponents?.NoDataBackdropComponent || <div>No data...</div>;
    }

    return (
      <div className="common-simple-data-grid-body">
        <AgGridReact
          {...otherProps}
          onGridReady={onGridReady}
          onFirstDataRendered={firstDataRenderedHandler}
          onSortChanged={otherProps.onSortChanged || sortHandler}
          onSelectionChanged={selectionHandler}
          onRowDataUpdated={rowDataUpdatedHandler}
          className="common-simple-data-grid"
          suppressAnimationFrame
          suppressColumnVirtualisation={isRowNavigable}
          onColumnMoved={columnMoveHandler}
          onColumnResized={columnResizeHandler}
          ref={gridRef}
          columnDefs={gridColumns}
          rowBuffer={isRowNavigable ? paginationProps?.totalItems : 10}
          suppressRowVirtualisation={isRowNavigable}
          suppressDragLeaveHidesColumns
          context={{
            getRowNavigationUrl,
            ...(otherProps.context || {}),
          }}
        />
        {visibilityControlProps?.enableVisibilityControls ? (
          <div className="common-simple-data-grid-visibility-control">
            <AdvancedGridVisibilityPanel
              title="Add / Remove Columns"
              columns={visibilityOptions}
              toggleColumn={toggleColumn}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderPagination = () => {
    if (paginationProps && otherProps?.rowData?.length) {
      return (
        <div className="common-simple-data-grid-pagination">
          <CommonPagination
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
            icons={{
              ExpandIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
              ),
            }}
            {...paginationProps}
          />
        </div>
      );
    }

    return <></>;
  };

  return (
    <div
      className={`ag-theme-alpine common-simple-data-grid-container ${
        containerClassName || ''
      }`}
    >
      {renderGrid()}
      {renderPagination()}
    </div>
  );
};
