import Severity from 'FindingDetails/components/Severity';
import React from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { RiskCustomizationConfigTypes } from './RiskCustomizationConfigTypes.enum';

export interface RiskFactorSettingsModel {
  weight: number;
  multiplier: number;
}
export type Factors = {
  [k in keyof typeof RiskCustomizationConfigTypes]: RiskFactorSettingsModel;
};

export interface RiskScoreValuesModel {
  severityRanges?: SeverityRanges;
  factors?: Factors;
}
export interface SeverityRange {
  min: number;
  base: number;
  max: number;
}
export type SeverityRanges = {
  [k in keyof typeof Severity]: SeverityRange;
};
export interface RiskCustomizationDto {
  factors: Factors;
}

export interface RiskCustomizationConfigItem {
  icon: SVG_ICON_TYPES;
  title: string;
  description: string;
  type: RiskCustomizationConfigTypes;
}

export enum RuleStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}
export enum RuleOperatorType {
  IN = 'in',
  IN_RANGE = 'in-range',
  HAS_ACCESS = 'has-access',
}
export enum RulePropertyType {
  BUSINESS_UNIT_ID = 'businessUnitId',
  RISK_SCORE = 'riskScore',
  SEVERITY = 'severity',
  BUSINESS_UNIT_ENVIRONMENT_TYPE = 'businessUnitEnvironmentType',
  FINDING_TYPE = 'findingType',
  FINDING_SOURCE = 'findingSource',
  TITLE = 'title',
  RESOURCE_TYPE = 'resourceType',
  SCOPE = 'scope',
  GROUP = 'group',
}

export enum RuleCategory {
  FINDING = 'finding',
  RESOURCE = 'resource',
  INTELLIGENCE = 'intelligence',
  ORGANIZATION = 'organization',
  WORKSPACE = 'workspace',
  SERVICE = 'service',
}

export interface RulePropertyInRange {
  start?: number;
  end?: number;
}
type RulePropertyValue = Array<any> | RulePropertyInRange;

export interface RuleProperty {
  type: RulePropertyType;
  operator: RuleOperatorType;
  values: RulePropertyValue;
  category?: RuleCategory;
}

export interface RiskConfig {
  id: string;
  name: string;
  description?: string;
  status: RuleStatus;
  properties: RuleProperty[];
  values: RiskScoreValuesModel;
  isDefault?: boolean;
}

export const RiskCustomizationConfig: Array<RiskCustomizationConfigItem> = [
  {
    icon: SVG_ICON_TYPES.BRIEFCASE_ICON,
    title: 'settings.table.columns.riskCustomization.serviceCriticality',
    description:
      'settings.table.columns.riskCustomization.serviceCriticalityDescription',
    type: RiskCustomizationConfigTypes.buCriticality,
  },
  {
    icon: SVG_ICON_TYPES.CLOUD_ICON,
    title: 'settings.table.columns.riskCustomization.envType',
    description: 'settings.table.columns.riskCustomization.envTypeDescription',
    type: RiskCustomizationConfigTypes.envType,
  },
  {
    icon: SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON,
    title: 'settings.table.columns.riskCustomization.complianceRequirements',
    description:
      'settings.table.columns.riskCustomization.complianceRequirementsDescription',
    type: RiskCustomizationConfigTypes.buCompliance,
  },
  {
    icon: SVG_ICON_TYPES.ARROW_UP_RIGHT_FROM_SQUARE_ICON,
    title: 'settings.table.columns.riskCustomization.externalFacing',
    description:
      'settings.table.columns.riskCustomization.externalFacingDescription',
    type: RiskCustomizationConfigTypes.buExternalFacing,
  },
  {
    icon: SVG_ICON_TYPES.EYES_ICON,
    title: 'settings.table.columns.riskCustomization.sensitiveData',
    description:
      'settings.table.columns.riskCustomization.sensitiveDataDescription',
    type: RiskCustomizationConfigTypes.buSensitiveData,
  },
  {
    icon: SVG_ICON_TYPES.INTELLIGENCE_ICON,
    title: 'settings.table.columns.riskCustomization.vulnerabilityIntelligence',
    description:
      'settings.table.columns.riskCustomization.vulnerabilityIntelligenceDescription',
    type: RiskCustomizationConfigTypes.vulnerabilityIntelligence,
  },
  {
    icon: SVG_ICON_TYPES.CUBES_ICON,
    title: 'settings.table.columns.riskCustomization.externalSource',
    description:
      'settings.table.columns.riskCustomization.externalSourceDescription',
    type: RiskCustomizationConfigTypes.external,
  },
];
