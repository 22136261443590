import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Grid, Stack, Typography } from '@mui/material';
import styles from './WidgetContainer.module.css';
import { Box } from '@mui/material';

interface WidgetContainerProps {
  children?: ReactJSXElement | Array<ReactJSXElement>;
  title: string;
  width?: string;
  height?: string;
}

// The WidgetContainer component is a simple wrapper which is used to display a simple card with a title
// The card will also contain the widget component or any other component which can be passed as a child

export function WidgetContainer({
  title,
  children,
  width = '50%',
}: WidgetContainerProps) {
  return (
    <Grid
      className="widgetContainer"
      item
      width={width === '50%' ? 'calc(50% - 1rem)' : '100%'}
      sx={{ minHeight: '35vh' }}
    >
      <Stack flexDirection="row" alignItems="center" gap={3}>
        <Typography variant="h5" className="title">
          {title}
        </Typography>
      </Stack>
      <Box className="widgetArea">{children}</Box>
    </Grid>
  );
}
