import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { OperationalWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import VerticalSimpleBarChart from 'shared/components/VerticalSimpleBarChart';
import DashboardChartCard from 'Dashboard/components/DashboardChartCard';
import {
  fillDataPlugin,
  presentationalConfigurationForWidgets,
} from 'Common/components/Widgets/fixtures/widget-data';
import { DashboardSection } from 'Dashboard/components/DashboardSection';
import { useTranslation } from 'react-i18next';
import { useFetchOperationalDashboardWidgetDataMutation } from 'Dashboard/store/api';
import { Grid } from '@mui/material';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import HorizontalStackedBarChart from 'shared/components/HorizontalStackedBarChart';
import { useSearchParams } from 'react-router-dom';
import { ReportRuleContentWidgetId } from 'shared/models/data/report-rule.model';

interface DashboardReportsRiskOperationalOverviewWidgetProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {}

export const DashboardReportsRiskOperationalOverviewWidget: FunctionComponent<
  DashboardReportsRiskOperationalOverviewWidgetProps
> = ({ businessUnitIds }) => {
  const [params] = useSearchParams();

  const widgetIds = useMemo<Array<ReportRuleContentWidgetId>>(() => {
    if (params.has('widgets')) {
      try {
        return JSON.parse(params.get('widgets') as string);
      } catch (err) {
        return [];
      }
    }

    return [];
  }, [params]);

  const { t: translation } = useTranslation();

  const [
    getByAssigneeWidgetData,
    { data: byAssigneeWidgetData, isLoading: byAssigneeWidgetDataLoading },
  ] = useFetchOperationalDashboardWidgetDataMutation();
  const [
    getByStatusWidgetData,
    { data: byStatusWidgetData, isLoading: byStatusWidgetDataLoading },
  ] = useFetchOperationalDashboardWidgetDataMutation();
  const [
    getByTicketStatusWidgetData,
    {
      data: byTicketStatusWidgetData,
      isLoading: byTicketStatusWidgetDataLoading,
    },
  ] = useFetchOperationalDashboardWidgetDataMutation();

  const [
    getByTicketAgeWidgetData,
    { data: byTicketAgeWidgetData, isLoading: byTicketAgeWidgetDataLoading },
  ] = useFetchOperationalDashboardWidgetDataMutation();

  useEffect(() => {
    getByAssigneeWidgetData({
      businessUnitIds,
      findingTypes: [],
      type: OperationalWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS_BY_ASSIGNEE,
    });
    getByStatusWidgetData({
      businessUnitIds,
      findingTypes: [],
      type: OperationalWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS_BY_STATUS,
    });

    getByTicketStatusWidgetData({
      businessUnitIds,
      findingTypes: [],
      type: OperationalWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS_BY_TICKET_STATUS,
    });

    getByTicketAgeWidgetData({
      businessUnitIds,
      findingTypes: [],
      type: OperationalWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS_BY_TICKET_AGE,
    });
  }, [businessUnitIds]);

  const renderChartWidget = (
    type: OperationalWidgetAnalyticsType,
    widgetData: any,
    isLoading: boolean
  ) => {
    if ((!widgetData || widgetData?.datasets.length == 0) && !isLoading) {
      return <NoDataToDisplayCard displayIcon={true} />;
    }
    if (type === OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_ASSIGNEE) {
      return (
        <>
          <VerticalSimpleBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={byAssigneeWidgetDataLoading}
            widgetData={byAssigneeWidgetData}
            displayLabels={false}
            enableTooltip
            style={presentationalConfigurationForWidgets['top_assigned'].style}
            applyColorList
            options={
              presentationalConfigurationForWidgets['top_assigned']
                .options as any
            }
          />
          {!isLoading && <div></div>}
        </>
      );
    } else if (type === OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_STATUS) {
      return (
        <>
          <VerticalSimpleBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={byStatusWidgetDataLoading}
            widgetData={byStatusWidgetData}
            displayLabels={false}
            aspectRatio={1.3}
            isResponsive
            enableTooltip
            style={presentationalConfigurationForWidgets['by_status'].style}
            applyColorList
            options={
              presentationalConfigurationForWidgets['by_status'].options as any
            }
          />
          {!isLoading && <div></div>}
        </>
      );
    } else if (
      type === OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_TICKET_STATUS
    ) {
      return (
        <>
          <HorizontalStackedBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={byTicketStatusWidgetDataLoading}
            widgetData={byTicketStatusWidgetData}
            displayLabels={true}
            aspectRatio={3}
            isResponsive
            legendPosition="bottom"
            style={
              presentationalConfigurationForWidgets['finding_by_ticket_status']
                .style
            }
            displayLegend
            customLegendContainerId={
              'operational-dashboard-section-by-ticket-status-chart-legend-container'
            }
            options={
              presentationalConfigurationForWidgets['finding_by_ticket_status']
                .options as any
            }
            plugins={[fillDataPlugin]}
          />
        </>
      );
    } else if (type === OperationalWidgetAnalyticsType.FINDING_BY_TICKET_AGE) {
      return (
        <>
          <HorizontalStackedBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={byTicketAgeWidgetDataLoading}
            widgetData={byTicketAgeWidgetData}
            displayLabels={false}
            aspectRatio={3}
            isResponsive
            legendPosition="bottom"
            style={
              presentationalConfigurationForWidgets['finding_by_ticket_age']
                .style
            }
            displayLegend
            customLegendContainerId={
              'operational-dashboard-section-by-ticket-age-chart-legend-container'
            }
            options={
              presentationalConfigurationForWidgets['finding_by_ticket_age']
                .options as any
            }
            plugins={[fillDataPlugin]}
          />
        </>
      );
    }
  };

  return (
    <div className="dashboard-reports-risk-operational-overview-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.CHART_SIMPLE_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Operational Overview
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-operational-overview-widget-body">
        {widgetIds.includes(ReportRuleContentWidgetId.FINDING_BY_STATUS) ? (
          <DashboardChartCard
            title="Findings by Status"
            className="operational-dashboard-section-chart-container"
          >
            {renderChartWidget(
              OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_STATUS,
              byStatusWidgetData,
              byStatusWidgetDataLoading
            )}
          </DashboardChartCard>
        ) : (
          <></>
        )}

        {widgetIds.includes(ReportRuleContentWidgetId.TOP_ASSIGNEES) ? (
          <DashboardChartCard
            title="Top Assignees"
            className="operational-dashboard-section-chart-container"
          >
            {renderChartWidget(
              OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_ASSIGNEE,
              byAssigneeWidgetData,
              byAssigneeWidgetDataLoading
            )}
          </DashboardChartCard>
        ) : (
          <></>
        )}

        {widgetIds.includes(
          ReportRuleContentWidgetId.FINDING_BY_TICKET_STATUS
        ) ? (
          <DashboardChartCard
            title="Findings by Ticket Status"
            className="operational-dashboard-section-chart-container operational-dashboard-section-chart-container-stacked-chart"
          >
            {renderChartWidget(
              OperationalWidgetAnalyticsType.NEW_FINDINGS_BY_TICKET_STATUS,
              byTicketStatusWidgetData,
              byTicketStatusWidgetDataLoading
            )}
          </DashboardChartCard>
        ) : (
          <></>
        )}

        {widgetIds.includes(ReportRuleContentWidgetId.FINDING_BY_TICKET_AGE) ? (
          <DashboardChartCard
            title={translation(`dashboards.widgets.findingsByTicketAge`)}
            className="operational-dashboard-section-chart-container operational-dashboard-section-chart-container-stacked-chart"
          >
            {renderChartWidget(
              OperationalWidgetAnalyticsType.FINDING_BY_TICKET_AGE,
              byTicketAgeWidgetData,
              byTicketAgeWidgetDataLoading
            )}
          </DashboardChartCard>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
