import classNames from 'classnames';
import { FunctionComponent, MouseEvent } from 'react';
import CommonCheckbox from 'shared/components/CommonCheckbox';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { FilterOption } from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface OptionListItemProps extends BaseComponentProps, FilterOption {
  enableCheckbox?: boolean;
  checked?: boolean;
  onClick?: (value: string, label?: string) => void;
}

export const OptionListItem: FunctionComponent<OptionListItemProps> = ({
  label,
  onClick,
  value,
  enableCheckbox,
  checked,
}) => {
  const renderCheckBox = () => {
    if (enableCheckbox)
      return (
        <CommonCheckbox
          checked={checked}
          inputProps={{ 'aria-label': 'controlled' }}
          readOnly
        />
      );

    return <></>;
  };

  const renderCheckIcon = () => {
    if (enableCheckbox) return <></>;

    if (checked) return <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />;

    return <></>;
  };

  return (
    <div
      className="option-list-item"
      onClick={(event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        onClick && onClick(value, label);
      }}
    >
      <div className="option-list-item-label">
        {renderCheckBox()}
        <span className="body-1 item-text" title={label || value}>
          {label || value}
        </span>
      </div>
      <div className="option-list-item-icons">{renderCheckIcon()}</div>
    </div>
  );
};
