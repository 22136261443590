import { AutocompleteOption } from 'FindingDetails/store/api';
import { SelectChipCategory } from 'shared/components/SelectChipGroup/SelectChipGroup';
import {
  ReportRuleContentGroupType,
  ReportRuleContentWidgetId,
  ReportRuleFrequencyType,
  ReportRuleRecipientType,
  ReportRuleType,
} from 'shared/models/data/report-rule.model';
import * as yup from 'yup';

export const reportRuleTypeOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleType.STATIC,
    label: 'Static',
  },
  {
    value: ReportRuleType.DYNAMIC,
    label: 'Dynamic',
  },
];

export const reportRuleFilterAllOption: AutocompleteOption = {
  value: 'All',
  label: 'All',
};

export const reportRuleRecipientsOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleRecipientType.EMAIL,
    label: ReportRuleRecipientType.EMAIL,
  },
  {
    value: ReportRuleRecipientType.GROUP_OWNERS,
    label: ReportRuleRecipientType.GROUP_OWNERS,
  },
];

export const reportRuleFrequencyOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleFrequencyType.DAYS,
    label: 'Daily',
  },
  {
    value: ReportRuleFrequencyType.WEEKS,
    label: 'Weekly',
  },
  {
    value: ReportRuleFrequencyType.MONTHS,
    label: 'Monthly',
  },
  {
    value: ReportRuleFrequencyType.QUARTERS,
    label: 'Quarterly',
  },
];

export const reportRuleDashboardTypeOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleContentGroupType.RISK,
    label: 'Risk',
  },
  {
    value: ReportRuleContentGroupType.COMPARATIVE_PER_GROUP,
    label: 'Comparative Per Group',
  },
  {
    value: ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE,
    label: 'Comparative Per Service',
  },
];

export const reportRuleFilterOptions: Array<SelectChipCategory> = [
  {
    id: 'scope',
    label: 'Scope',
    optionList: [
      {
        value: 'finance',
        label: 'Finance',
      },
    ],
    loaded: true,
  },
  {
    id: 'groups',
    label: 'Groups',
    optionList: [
      {
        value: 'apps',
        label: 'Apps',
      },
    ],
    loaded: true,
  },
];

export const reportRuleWidgetOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleContentWidgetId.ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_STATUS,
    label: 'Open Findings By SLA Status',
  },
  {
    value: ReportRuleContentWidgetId.ACCUMULATIVE_OPEN_FINDINGS_BY_SEVERITY,
    label: 'Open Findings By Severity',
  },
  {
    value:
      ReportRuleContentWidgetId.ACCUMULATIVE_OPEN_FINDINGS_BY_SLA_BREACH_AGE,
    label: 'Open Findings By SLA Breach Age',
  },
];

export const reportRuleRiskWidgetOptions: Array<AutocompleteOption> = [
  {
    value: ReportRuleContentWidgetId.TOTAL_METRICS,
    label: 'Total Metrics',
  },
  {
    value: ReportRuleContentWidgetId.FINDING_BY_SEVERITY,
    label: 'Finding by Severity',
  },
  {
    value: ReportRuleContentWidgetId.FINDING_BY_CATEGORY,
    label: 'Finding by Category',
  },
  {
    value: ReportRuleContentWidgetId.TRENDS_METRICS,
    label: 'Trends Metrics',
  },
  {
    value: ReportRuleContentWidgetId.TRENDS_GRAPH,
    label: 'Trends Graph',
  },

  {
    value: ReportRuleContentWidgetId.FINDING_BY_STATUS,
    label: 'Finding by Status',
  },
  {
    value: ReportRuleContentWidgetId.TOP_ASSIGNEES,
    label: 'Top Assignees',
  },
  {
    value: ReportRuleContentWidgetId.FINDING_BY_TICKET_STATUS,
    label: 'Finding by Ticket Status',
  },
  {
    value: ReportRuleContentWidgetId.FINDING_BY_TICKET_AGE,
    label: 'Finding by Ticket Age',
  },
  {
    value: ReportRuleContentWidgetId.ACTIVE_CAMPAIGNS,
    label: 'Active Campaigns',
  },
];

export const reportRuleContentTypeToLabel: Record<
  ReportRuleContentGroupType,
  string
> = {
  [ReportRuleContentGroupType.COMPARATIVE_PER_GROUP]: 'Comparative Per Group',
  [ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE]:
    'Comparative Per Service',
  [ReportRuleContentGroupType.RISK]: 'Risk',
};

export const reportRuleInitialSchema: any = {
  name: yup.string().required(),
  description: yup.string(),
  recipients: yup.object().required(),
  contentType: yup.object().required(),
  widgets: yup.array().required().min(1),
  scopes: yup.object().required(),
  groups: yup.object().required(),
  emails: yup.array().required().min(1),
  frequencyType: yup.object().required(),
  timeFrame: yup.object().nullable(),
  trendMetricsTypes: yup.array(),
};

export const reportRuleDefaultValues = {
  name: '',
  description: '',
  contentType: null,
  recipients: reportRuleRecipientsOptions[0],
  widgets: [],
  scopes: reportRuleFilterAllOption,
  groups: reportRuleFilterAllOption,
  emails: [],
  frequencyType: null,
  timeFrame: null,
  trendMetricsTypes: [],
};
