import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { CampaignCreateFormValues } from 'shared/models/data/campaigns.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CampaignCreateForm from '../CampaignCreateForm';

interface CampaignCreateModalProps extends BaseComponentProps {
  modalOpen: boolean;
  handleModalClose: () => void;
  handleCampaignCreation: (value: CampaignCreateFormValues) => void;
  campaignName?: string;
}

export const CampaignCreateModal: FunctionComponent<
  CampaignCreateModalProps
> = ({
  modalOpen,
  handleModalClose,
  handleCampaignCreation,
  campaignName = '',
}) => {
  return (
    <CommonModalContainer
      isOpen={modalOpen}
      handleClose={handleModalClose}
      title="Create Campaign"
      components={{
        CancelButton: (
          <Button
            onClick={handleModalClose}
            className="campaign-modal-cancel-button"
          >
            Cancel
          </Button>
        ),
        SubmitButton: (
          <Button className="campaign-modal-create-button">Create</Button>
        ),
      }}
    >
      <CampaignCreateForm
        campaignName={campaignName}
        onCloseModal={handleModalClose}
        onSubmit={handleCampaignCreation}
      />
    </CommonModalContainer>
  );
};
