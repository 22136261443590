import { findingSeverityListOptions } from 'FindingDetails/interfaces/severity';
import {
  RuleCategory,
  RuleOperatorType,
  RuleProperty,
  RulePropertyInRange,
  RulePropertyType,
  RuleStatus,
} from 'Settings/interfaces/RiskCustomizationConfig';
import { ConfigurationFieldValue } from 'shared/components/MultiSelectConfigurationField/MultiSelectConfigurationField';
import { hasAccessPropertyNames } from 'shared/fixtures/data/notification-rule.data';
import {
  SlaRuleAdditionalFormData,
  SlaRuleBaseFormData,
  SlaRuleFormData,
  slaRuleInRangePropertyNames,
} from 'shared/fixtures/data/sla-rules.data';
import {
  CategoryState,
  ExtendedFilterCategory,
  MultiSelectState,
  NumberRangeState,
} from 'shared/models/data/data-filter.model';
import {
  SlaConfigRuleModel,
  SlaConfigRuleValues,
} from 'shared/models/data/sla-config-rule.model';

export class SlaRuleDataHandler {
  transformSlaRuleDataToFormData(slaRule: SlaConfigRuleModel): SlaRuleFormData {
    let ruleProperties: Record<string, CategoryState> = {};
    let ruleValues: Array<ConfigurationFieldValue> = [];

    for (const property of slaRule.properties) {
      if (slaRuleInRangePropertyNames.includes(property.type)) {
        if (property.values)
          ruleProperties[property.type] = {
            startValue: (property.values as RulePropertyInRange).start,
            endValue: (property.values as RulePropertyInRange).end,
          } as NumberRangeState;
      } else {
        if ((property.values as Array<any>).length) {
          ruleProperties[property.type] = {
            selectedOptions: property.values as Array<any>,
          };
        }
      }
    }

    const sortedSeverityEntries = Object.entries(slaRule.values)
      .map(([severityKey, severityValue]) => {
        const severityOption = findingSeverityListOptions.find(
          (option) => option.value === severityKey
        );
        return {
          severityOption,
          severityValue: severityValue ?? 0,
        };
      })
      .filter(({ severityOption }) => severityOption !== undefined)
      .sort((a, b) => b.severityValue - a.severityValue);

    for (const { severityOption, severityValue } of sortedSeverityEntries) {
      if (severityOption && severityValue !== 0) {
        ruleValues.push({
          values: [
            severityOption,
            { value: String(Math.floor(severityValue / 3600)) },
          ],
          disableDelete: Boolean(slaRule.isDefault),
        });
      }
    }

    return {
      name: slaRule.name,
      description: slaRule.description || '',
      properties: ruleProperties,
      values: ruleValues,
    };
  }

  transformSlaRuleFormDataToPostData(
    baseFormData: SlaRuleBaseFormData,
    additionalFormData: SlaRuleAdditionalFormData,
    categories?: Array<ExtendedFilterCategory>
  ): SlaConfigRuleModel {
    let properties: Array<RuleProperty> = [];
    let values: Partial<SlaConfigRuleValues> = {};

    if (Object.keys(additionalFormData?.properties).length) {
      for (const propertyKey in additionalFormData.properties) {
        const parentCategory = categories?.find(
          (category: ExtendedFilterCategory) =>
            Boolean(
              category.categories?.find(
                (cat: ExtendedFilterCategory) => cat.id === propertyKey
              )
            )
        );

        if (slaRuleInRangePropertyNames.includes(propertyKey)) {
          const typedState = additionalFormData.properties[
            propertyKey
          ] as NumberRangeState;

          properties.push({
            operator: RuleOperatorType.IN_RANGE,
            type: propertyKey as RulePropertyType,
            values: { start: typedState.startValue, end: typedState.endValue },
            category: parentCategory?.id as RuleCategory,
          });
        } else {
          const typedState = additionalFormData.properties[
            propertyKey
          ] as MultiSelectState;
          properties.push({
            operator: hasAccessPropertyNames.includes(propertyKey)
              ? RuleOperatorType.HAS_ACCESS
              : RuleOperatorType.IN,
            type: propertyKey as RulePropertyType,
            values: typedState?.selectedOptions || [],
            category: parentCategory?.id as RuleCategory,
          });
        }
      }
    }

    for (const valueItem of additionalFormData.values) {
      const valueItemKey = valueItem.values[0].value;
      const valueItemValue = valueItem.values[1].value;

      values[valueItemKey as unknown as keyof SlaConfigRuleValues] =
        +valueItemValue * 3600;
    }

    return {
      name: baseFormData.name,
      description: baseFormData.description,
      properties: properties,
      values: values,
      status: RuleStatus.ENABLED,
    };
  }
  transformRuleResponse(data: Array<any>) {
    return data.sort((a, b) => {
      if (a.isDefault && !b.isDefault) return -1;
      if (!a.isDefault && b.isDefault) return 1;
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }
}
