import DashboardWidgetCard from 'Dashboard/components/DashboardWidgetCard';
import { useGetDashboardAnalyticsMutation } from 'Dashboard/store/api';
import { FunctionComponent, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { TimelineFilter } from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  OperationalWidgetAnalyticsType,
  OperationalWidgetDataTrend,
  OperationalWidgetDataTrendImpact,
} from 'shared/models/data/operational-widget-data.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardReportsRiskMetricCardProps extends BaseComponentProps {
  title: string;
  timeFrame: TimelineFilter;
  subTitle?: string;
  icon: ReactNode;
  type: OperationalWidgetAnalyticsType;
  businessUnitIds: Array<string>;
}

export const DashboardReportsRiskMetricCard: FunctionComponent<
  DashboardReportsRiskMetricCardProps
> = ({ title, subTitle, icon, type, businessUnitIds, timeFrame }) => {
  const { t: translation } = useTranslation();

  const [
    getDashboardAnalytics,
    { data: dashboardAnalytics, isLoading: dashboardAnalyticsLoading },
  ] = useGetDashboardAnalyticsMutation();

  useEffect(() => {
    getDashboardAnalytics({
      findingTypes: [],
      businessUnitIds: businessUnitIds,
      timeline: timeFrame,
      type,
    });
  }, [businessUnitIds, timeFrame, type]);

  const getTrendImpactClassName = (
    trendImpact: OperationalWidgetDataTrendImpact
  ): string => {
    switch (trendImpact) {
      case OperationalWidgetDataTrendImpact.NEGATIVE:
        return 'operational-widget-card-negative-trend';
      case OperationalWidgetDataTrendImpact.POSITIVE:
        return 'operational-widget-card-positive-trend';
      case OperationalWidgetDataTrendImpact.NEUTRAL:
        return 'operational-widget-card-neutral-trend';
      default:
        return '';
    }
  };

  const getTrendIcon = (trend: OperationalWidgetDataTrend) => {
    switch (trend) {
      case OperationalWidgetDataTrend.INCREASING:
        return <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_UP_ICON} />;
      case OperationalWidgetDataTrend.DECREASING:
        return <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_DOWN_ICON} />;
      case OperationalWidgetDataTrend.SAME:
        return <></>;
      default:
        return <></>;
    }
  };

  const getTrendPercentage = (value: number) => {
    return value <= 1000
      ? `${value}%`
      : translation('dashboards.widgets.overSpecificValue', {
          value: '1000%',
        });
  };

  const renderTrendArea = () => {
    return (
      <div
        className={`operational-widget-card-body-trend ${getTrendImpactClassName(
          dashboardAnalytics?.trendImpact
        )}`}
        title={subTitle}
      >
        <span>{getTrendPercentage(dashboardAnalytics?.trendPercentage)}</span>
        {getTrendIcon(dashboardAnalytics?.trend)}
      </div>
    );
  };

  return (
    <DashboardWidgetCard
      icon={icon}
      title={title}
      data={dashboardAnalytics}
      isLoading={dashboardAnalyticsLoading}
      renderAddornment={renderTrendArea}
      onClick={() => {}}
      classes={{
        container: `operational-widget-card-container`,
      }}
    />
  );
};
