import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import { FunctionComponent } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import DashboardReportsRiskTotalMetricsCard from './components/DashboardReportsRiskTotalMetricsCard';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import { useTranslation } from 'react-i18next';

interface DashboardReportsRiskTotalMetricsWidgetProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {}

export const DashboardReportsRiskTotalMetricsWidget: FunctionComponent<
  DashboardReportsRiskTotalMetricsWidgetProps
> = ({ businessUnitIds }) => {
  const { t: translation } = useTranslation();

  return (
    <div className="dashboard-reports-risk-metrics-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.CHART_PIE_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Total Metrics
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-metrics-widget-body">
        <DashboardReportsRiskTotalMetricsCard
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.BUG_ICON} />}
          title={translation(`dashboards.widgets.openFindings`)}
          type={PostureWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS}
          businessUnitIds={businessUnitIds}
        />
        <DashboardReportsRiskTotalMetricsCard
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.TELESCOPE_ICON} />}
          title={translation(`dashboards.widgets.rootCauses`)}
          type={PostureWidgetAnalyticsType.ACCUMULATIVE_ROOT_CAUSES}
          businessUnitIds={businessUnitIds}
        />
        <DashboardReportsRiskTotalMetricsCard
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.SIREN_ON_ICON} />}
          title={translation(`dashboards.widgets.totalRisk`)}
          type={PostureWidgetAnalyticsType.ACCUMULATIVE_RISKS}
          businessUnitIds={businessUnitIds}
        />

        <DashboardReportsRiskTotalMetricsCard
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.BRAKE_WARNING_ICON} />}
          title={translation(`dashboards.widgets.pendingExceptions`)}
          type={PostureWidgetAnalyticsType.ACCUMULATIVE_PENDING_EXCEPTIONS}
          businessUnitIds={businessUnitIds}
        />
        <DashboardReportsRiskTotalMetricsCard
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_CHECK_ICON} />}
          title={translation(`dashboards.widgets.resolvedFindings`)}
          type={PostureWidgetAnalyticsType.ACCUMULATIVE_RESOLVED_FINDINGS}
          businessUnitIds={businessUnitIds}
        />
        <DashboardReportsRiskTotalMetricsCard
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON} />}
          title={translation(`dashboards.widgets.slaAttainmentRate`)}
          type={PostureWidgetAnalyticsType.ACCUMULATIVE_SLA}
          businessUnitIds={businessUnitIds}
        />
        <DashboardReportsRiskTotalMetricsCard
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.SCREWDRIVER_WRENCH_ICON} />}
          title={translation(`dashboards.widgets.averageMttr`)}
          type={PostureWidgetAnalyticsType.ACCUMULATIVE_AVERAGE_MTTR}
          businessUnitIds={businessUnitIds}
        />
        <DashboardReportsRiskTotalMetricsCard
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.TRIANGLE_EXCLAMATION_ICON} />}
          title={translation(`dashboards.widgets.activeExceptions`)}
          type={PostureWidgetAnalyticsType.ACCUMULATIVE_ACTIVE_EXCEPTIONS}
          businessUnitIds={businessUnitIds}
        />
      </div>
    </div>
  );
};
