/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Stack, TextField } from '@mui/material';
import classNames from 'classnames';
import CardWrapper from 'Common/components/CardWrapper';
import FilterDropdown, {
  mutationProps,
} from 'shared/components/FilterDropdown/FilterDropdown';
import { MenuItems } from '../../../DashboardFilter/store';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import CommonFavoriteComponent from '../CommonFavouritesFilter';
import {
  FavouritesFilterItem,
  QuickFiltersList,
} from '../CommonFavouritesFilter/CommonFavouritesFilter';

import { AdvanceFilterGetter } from 'shared/hooks/useGetAdvanceFilterGetter';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';
import { CustomFilterType } from 'Risk/interfaces/CustomFilterType.enum';
import FilterChipGroup from '../DataFilters/FilterChipGroup';
import { FilterChipGroupProps } from '../DataFilters/FilterChipGroup/FilterChipGroup';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import {
  CategoryState,
  FilterType,
  KeyValueState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import FormattedMessage from '../FormattedMessage';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';

export interface FilterOption {
  id: any;
  name: string;
  checked?: boolean;
}

export interface FilterItem<T = FilterOption> {
  id: string;
  name: string;
  type?: string;
  dateRange?: any;
  numberInput?: number;
  items: Array<T>;
  loaded?: boolean;
  field?: string;
  nested?: boolean;
  keepExistingOptions?: boolean;
  filterItems?: Array<FilterItem<T>>;
  bodyParameters?: any;
  additionalBodyParameters?: any;
  optionGetterId?: AdvanceFilterGetter;
  resetFields?: Array<string>;
  getAdditionalBodyParameters?: (selection: any) => any;
}

type AdvanceFilterElements = 'button';

interface AdvanceFilterProps extends FilterChipGroupProps {
  onSearch: (searchInput: string) => void;
  searchValue?: string;
  searchEnabled?: boolean;
  mutationProps: mutationProps;
  getFilterList: any;
  setListFilter: any;
  quickFilterItems?: QuickFiltersList;
  initialFilterItems?: MenuItems;
  storeSynchronization?: boolean;
  querySynchronization?: boolean;
  labels?: Record<AdvanceFilterElements, string>;
  stateSelector?: any;
  rootClassName?: string;
  saveFilters?: () => void;
  customFilterType?: CustomFilterType;
  clearFilters: () => void;
  replaceFilterState: (state: Record<string, CategoryState>) => void;
}

const filterSearchParamsHandler = new FilterSearchParamsHandler();

const advanceFilterDataHandler = new AdvanceFilterHandler();

const AdvanceFilter: FunctionComponent<AdvanceFilterProps> = ({
  onSearch,
  searchValue = '',
  searchEnabled = true,
  mutationProps,
  getFilterList,
  setListFilter,
  quickFilterItems,
  labels,
  stateSelector = useCommonSelector,
  storeSynchronization = true,
  querySynchronization = true,
  rootClassName = '',
  initialFilterItems = [],
  saveFilters = () => {},
  customFilterType,
  categories,
  categoryState,
  staticCategoryIds,
  onChange,
  disableAddButton,
  onRemoveChip,
  clearFilters,
  replaceFilterState,
}) => {
  const [urlSearchParams, setUrlSearchParams, getUrlSearchParams] =
    useQueryParams();

  const { t: translation } = useTranslation();
  const dispatch = useCommonDispatch();
  const allDataMenu: MenuItems = stateSelector(getFilterList);

  const [menu, setMenu] = useState<any>([]);
  const [subMenu, setSubMenu] = useState<any>([]);
  const [nestedSubMenu, setNestedSubMenu] = useState<any>([]);
  const [showNestedSubMenu, setNestedShowSubMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElEdit, setAnchorElEdit] = useState<{
    [key: number]: HTMLButtonElement | null;
  }>({});
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [nestedItem, setNestedItem] = useState<FilterItem | null>(null);
  const [filteredData, setFilteredData] = useState<boolean>(false);
  const [chipData, setChipData] = useState<any>([]);
  const [initializedDefaultFilter, setInitializedDefaultFilter] =
    useState(false);

  let open = Boolean(anchorEl);
  let id = open ? 'simple-popover' : undefined;

  const isCategoryStateValid = useMemo<boolean>(() => {
    return Object.values(categoryState).some((state) => {
      if ((state as SingleSelectState).selectedOptions) {
        return (state as SingleSelectState)?.selectedOptions?.length;
      }

      if ((state as KeyValueState).selections) {
        return (state as KeyValueState)?.selections?.some(
          (selection) => selection.key.value.length > 0
        );
      }

      return Object.keys(state).length > 0;
    });
  }, [categoryState]);

  useEffect(() => {
    return () => {
      if (storeSynchronization) {
        dispatch(setListFilter(allDataMenu));
      } else {
        setListFilter(allDataMenu);
      }
    };
  }, []);

  useEffect(() => {
    if (allDataMenu.length === 0 && initialFilterItems.length > 0) {
      if (storeSynchronization) {
        dispatch(setListFilter(initialFilterItems));
      } else {
        setListFilter(initialFilterItems);
      }
    }
  }, [initialFilterItems, allDataMenu]);

  useEffect(() => {
    setMenu(allDataMenu);
    if (_.isArray(subMenu) === false && subMenu) {
      const updatedSubmenu = allDataMenu.filter(
        (item: any) => item.id === subMenu.id
      );
      setSubMenu(updatedSubmenu[0]);
    }

    if (nestedSubMenu && _.isArray(nestedSubMenu) === false) {
      const updatedNestedSubMenu = allDataMenu.filter(
        (item) =>
          item.nested &&
          item.filterItems?.find(
            (filterItem) => filterItem.id && nestedSubMenu.id
          )
      );

      const updatedSubMenuItem = updatedNestedSubMenu
        ?.find((nestedSubMenuItem) => nestedSubMenuItem?.id === nestedItem?.id)
        ?.filterItems?.find((filterItem) => filterItem.id === nestedSubMenu.id);

      setNestedSubMenu(updatedSubMenuItem);
    }
    setChipList();
  }, [allDataMenu]);

  const updateFilterList = () => {};

  const setChipList = () => {
    const nestedChipArray = allDataMenu
      ?.filter((filterItem) => filterItem.nested)
      .map((filterItem) =>
        filterItem.filterItems?.map((item) => ({
          ...item,
          name: item.name,
        }))
      )
      .flat()
      .map((obj: any) => {
        const filteredArray = obj.items.filter(
          (innerObj: any) => innerObj.checked === true
        );
        return { ...obj, items: filteredArray };
      })
      .filter(
        (e: any) =>
          e.items.length > 0 || e?.dateRange?.length > 0 || !!e?.numberInput
      );

    const newChipArray = allDataMenu
      ?.filter((filterItem) => !filterItem.nested)
      .map((obj: any) => {
        const filteredArray = obj.items.filter(
          (innerObj: any) => innerObj.checked === true
        );
        return { ...obj, items: filteredArray };
      })
      .filter(
        (e: any) =>
          e.items.length > 0 || e?.dateRange?.length > 0 || !!e?.numberInput
      );

    setChipData([...newChipArray, ...nestedChipArray]);
  };

  useEffect(() => {
    updateFilterList();
  }, [urlSearchParams]);

  // useEffect(() => {
  //   let params = getUrlSearchParams();
  //   if (!initializedDefaultFilter) {
  //     let parsedFilterParams: any = {};
  //     let disableDefaultFilter: boolean = false;

  //     if (params.filter?.length) {
  //       parsedFilterParams = JSON.parse(params.filter);
  //     }

  //     if (params.disableDefaultFilter?.length) {
  //       disableDefaultFilter = JSON.parse(params.disableDefaultFilter);
  //     }

  //     const defaultFilter = disableDefaultFilter
  //       ? {}
  //       : {
  //           state: [
  //             {
  //               id: FindingState.ACTIVE,
  //               name: FindingState.ACTIVE,
  //             },
  //           ],
  //         };

  //     const filter = JSON.stringify({
  //       ...defaultFilter,
  //       ...parsedFilterParams,
  //     });

  //     const searchParams = getUrlSearchParams();

  //     setUrlSearchParams({ ...searchParams, filter: filter });
  //     setInitializedDefaultFilter(true);
  //   }
  // });

  const handleQuickFilterSelection = (item: FavouritesFilterItem) => {
    const allCategories =
      advanceFilterDataHandler.extractAllCategories(categories);

    const filters = item.options.reduce((accumulator, currentOption) => {
      const category = allCategories.find(
        (cat) => cat.id === currentOption.type
      );
      if (category?.type === FilterType.SINGLE_SELECT) {
        accumulator[currentOption.type] = Array.isArray(currentOption.values)
          ? currentOption.values[0]
          : currentOption.values;
      } else {
        accumulator[currentOption.type] = currentOption.values;
      }
      return accumulator;
    }, {} as Record<string, any>);

    const filterState =
      advanceFilterDataHandler.translateQueryFiltersToFilterState(
        filters,
        categories
      );

    replaceFilterState(filterState);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setFilteredData(false);
    setShowSubMenu(false);
    setMenu(allDataMenu);
    setNestedItem(null);
    setNestedSubMenu(null);
  };

  const handleCloseEditMenu = () => {
    setAnchorElEdit({});
    setFilteredData(false);
    setShowSubMenu(false);
    setMenu(allDataMenu);
    setNestedItem(null);
    setNestedShowSubMenu(false);
    setNestedSubMenu(null);
  };

  const handleGoBack = () => {
    setShowSubMenu(false);
    setMenu(allDataMenu);
  };

  const handleFindItem = (id: string | number, data: []) => {
    return data.find((e: any) => e.id === id);
  };

  const handleSelectMenuItem = (id: any, data: any) => {
    const item = handleFindItem(id, data) as any;

    if (item?.nested) {
      setNestedItem(item);
    } else {
      setSubMenu(item);
      setShowSubMenu(true);
    }
  };

  const handleNestedSelectMenuItem = (id: any, data: any) => {
    const item = allDataMenu
      .find((allDataItem) => allDataItem.id === nestedItem?.id)
      ?.filterItems?.find((filterItem) => filterItem.id === id);

    if (item) {
      setNestedShowSubMenu(true);
      setNestedSubMenu(item);
    }
  };

  const selectSubMenuItem = (
    id: any,
    data: any,
    menuItems: any,
    idToRemove?: any
  ) => {
    // if (querySynchronization) {
    //   const searchParams = getUrlSearchParams();

    //   filterSearchParamsHandler.handleAdvanceFilterOptionSelection(
    //     data,
    //     id,
    //     searchParams,
    //     setUrlSearchParams
    //   );
    // }

    const updatedSubmenu = menuItems.filter(
      (item: any) => item.id === data.id
    )[0];

    let updatedObject;

    if (data.type === TypeFilter.DATE_PICKER_RANGE) {
      updatedObject = {
        ...updatedSubmenu,
        dateRange: id,
      };
    } else if (data.type === TypeFilter.NUMBER) {
      updatedObject = {
        ...updatedSubmenu,
        numberInput: id,
      };
    } else if (data.type === TypeFilter.SINGLE_SELECT) {
      updatedObject = {
        ...updatedSubmenu,
        items: updatedSubmenu.items.map((item: any) => {
          if (item.id === id.id) {
            return {
              ...item,
              checked: !id.checked,
            };
          }
          return {
            ...item,
            checked: false,
          };
        }),
      };
    } else {
      updatedObject = {
        ...updatedSubmenu,
        items: updatedSubmenu.items.map((item: any) => {
          if (item.id === id.id) {
            return {
              ...item,
              checked: !id.checked,
            };
          }
          return item;
        }),
      };
    }

    return updatedObject;
  };

  const handleSelectNestedSubMenuItem = (id: any, data: any) => {
    let updatedNestedItem: any = allDataMenu.find(
      (filterItem) =>
        filterItem.nested &&
        filterItem.filterItems?.find((item: FilterItem) => item.id === data.id)
    );

    const isUnchecking = data.items?.find(
      (item: FilterOption) => item.id === id.id && item.checked
    );

    const updatedObject = selectSubMenuItem(
      id,
      data,
      updatedNestedItem?.filterItems
    );

    // if (updatedObject.resetFields) {
    //   updatedNestedItem = {
    //     ...updatedNestedItem,
    //     filterItems: updatedNestedItem.filterItems?.map(
    //       (filterItem: FilterItem) => {
    //         if (updatedObject.resetFields?.includes(filterItem?.id)) {
    //           filterSearchParamsHandler.handleFilterCategoryRemovalFromSearchParams(
    //             filterItem?.id as string,
    //             getUrlSearchParams(),
    //             setUrlSearchParams
    //           );

    //           return {
    //             ...filterItem,
    //             loaded: false,
    //             items: isUnchecking ? filterItem.items : [],
    //             additionalBodyParameters: isUnchecking
    //               ? {}
    //               : filterItem?.getAdditionalBodyParameters
    //               ? filterItem.getAdditionalBodyParameters(id)
    //               : {},
    //           };
    //         }

    //         return filterItem;
    //       }
    //     ),
    //   };
    // }

    updateReduxData({
      ...updatedNestedItem,
      filterItems: updatedNestedItem?.filterItems?.map(
        (filterItem: FilterItem) => {
          if (filterItem.id === updatedObject.id) {
            return updatedObject;
          }
          return filterItem;
        }
      ),
    });
  };

  const handleSelectAllSubMenuItem = (filterItem: FilterItem) => {
    const updatedSubmenu = menu.filter(
      (item: any) => item.id === filterItem.id
    )[0];

    let updatedObject;

    const allSelected: boolean = updatedSubmenu.items.every(
      (item: FilterOption) => item.checked
    );

    updatedObject = {
      ...updatedSubmenu,
      items: updatedSubmenu.items.map((item: FilterOption) => ({
        ...item,
        checked: !allSelected,
      })),
    };

    updateReduxData(updatedObject);

    // if (querySynchronization) {
    //   const searchParams = getUrlSearchParams();

    //   filterSearchParamsHandler.handleAdvanceFilterAllOptionsSelection(
    //     filterItem,
    //     allSelected,
    //     searchParams,
    //     setUrlSearchParams
    //   );
    // }
  };

  const handleSelectAllNestedSubMenuItem = (filterItem: FilterItem) => {
    const parentItem = menu.find(
      (item: any) =>
        item.nested &&
        item.filterItems?.find((item: FilterItem) => item.id === filterItem.id)
    );

    const childItem = parentItem?.filterItems?.find(
      (item: FilterItem) => item.id === filterItem.id
    );

    let updatedObject;

    const allSelected: boolean = childItem.items.every(
      (item: FilterOption) => item.checked
    );

    updatedObject = {
      ...parentItem,
      filterItems: parentItem?.filterItems?.map((item: FilterItem) => {
        if (item.id === filterItem.id) {
          return {
            ...filterItem,
            items: item.items.map((item: FilterOption) => ({
              ...item,
              checked: !allSelected,
            })),
          };
        }

        return item;
      }),
    };

    updateReduxData(updatedObject);

    // if (querySynchronization) {
    //   const searchParams = getUrlSearchParams();

    //   filterSearchParamsHandler.handleAdvanceFilterAllOptionsSelection(
    //     filterItem,
    //     allSelected,
    //     searchParams,
    //     setUrlSearchParams
    //   );
    // }
  };

  const updateReduxData = (item: any) => {
    let updatedArray = menu.map((obj: any) => {
      if (obj.id === item.id) {
        return { ...item };
      }
      return obj;
    });

    if (storeSynchronization) {
      dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
    } else {
      setListFilter(updatedArray);
    }
  };

  const editChip = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemData: any
  ) => {
    const isNestedItem = menu.find(
      (item: any) =>
        item.nested &&
        item.filterItems.find(
          (filterItem: FilterItem) => filterItem.id === itemData.id
        )
    );

    if (isNestedItem) {
      const menuItem = isNestedItem?.filterItems?.find(
        (filterItem: FilterItem) => filterItem.id === itemData.id
      );

      setSubMenu(null);
      setNestedSubMenu(menuItem);
    } else {
      const updatedSubmenu = menu.filter(
        (item: any) => item.id === itemData.id
      );

      setNestedSubMenu(null);
      setSubMenu(updatedSubmenu[0]);
    }
    setAnchorElEdit({ [itemData.id]: event.currentTarget });
  };

  const renderNestedMenu = () => {
    if (nestedItem) {
      const nestedAnchorEl = document.querySelector(
        '.main-category-filter-dropdown-container .MuiPaper-root'
      );

      const nestedStateItem = allDataMenu.find(
        (filterItem) => filterItem.id === nestedItem.id
      );

      return (
        <FilterDropdown
          id={id}
          mutationProps={mutationProps}
          anchorEl={nestedAnchorEl}
          open={true}
          handleClose={handleCloseMenu}
          listItems={nestedItem.filterItems}
          setListItems={setMenu}
          data={nestedStateItem?.filterItems}
          handleItemSelect={handleNestedSelectMenuItem}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          className="nested-category-filter-dropdown-container"
          storeSynchronization={storeSynchronization}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      );
    }

    return <></>;
  };

  const renderNestedSubMenu = () => {
    if (showNestedSubMenu) {
      const nestedSubMenuAnchorEl = document.querySelector(
        '.nested-category-filter-dropdown-container .MuiPaper-root'
      );

      return (
        <FilterDropdown
          mutationProps={mutationProps}
          id={id}
          setListFilter={setListFilter}
          isSubMenu={true}
          anchorEl={nestedSubMenuAnchorEl}
          open={open}
          handleClose={() => {
            setNestedItem(null);
            setAnchorEl(null);
            setNestedShowSubMenu(false);
            setNestedSubMenu(null);
          }}
          listItems={nestedSubMenu}
          mainMenuItemsSort="ASC"
          handleGoBack={() => {
            setNestedShowSubMenu(false);
            setNestedSubMenu(null);
          }}
          setListItems={setNestedSubMenu}
          data={allDataMenu}
          handleItemSelect={handleSelectNestedSubMenuItem}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          className="sub-nested-category-filter-dropdown-container"
          storeSynchronization={storeSynchronization}
          handleSelectAll={handleSelectAllNestedSubMenuItem}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <CardWrapper
      sx={{ width: 'inherit' }}
      className={`advanced-filter-container ${rootClassName}`}
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={4}
        justifyContent="space-between"
      >
        <div className="filter-container">
          <>
            {quickFilterItems && (
              <>
                <CommonFavoriteComponent
                  items={quickFilterItems}
                  onSelect={handleQuickFilterSelection}
                  filterType={customFilterType}
                />
              </>
            )}
            {searchEnabled && (
              <TextField
                className={classNames(['filter-input filter-main-input'])}
                placeholder={translation(`common.searchPlaceholder`)}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
                InputProps={{
                  className: 'search-filter-input',
                  startAdornment: (
                    <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
                  ),
                }}
                value={searchValue}
              />
            )}

            <FilterChipGroup
              categories={categories}
              categoryState={categoryState}
              onChange={onChange}
              disableAddButton={disableAddButton}
              onRemoveChip={onRemoveChip}
              staticCategoryIds={staticCategoryIds}
            />
          </>

          <div className="advance-filter-button-area">
            {isCategoryStateValid ? (
              <div className="save-text label-4" onClick={saveFilters}>
                <FormattedMessage
                  id="common.save"
                  defaultMessage="Save"
                  capitalize
                />
              </div>
            ) : (
              <></>
            )}

            <div className="clear-text label-4" onClick={clearFilters}>
              <FormattedMessage
                capitalize
                id="common.clear"
                defaultMessage="Clear"
              />
            </div>
          </div>
        </div>
      </Stack>
    </CardWrapper>
  );
};

export default AdvanceFilter;
