import { CampaignCreateFormDateRange } from 'shared/enums/campaigns.enum';
import { DateService } from '../date.service';

interface CampaignCreateFormDateServiceFnPayload {
  startDate: string;
  endDate: string;
}

export class CampaignFormDateService {
  private dateService: DateService = new DateService();

  private calculateOneWeekRangeFromToday(): CampaignCreateFormDateServiceFnPayload {
    const endDate = this.dateService.addWeeks(new Date(), 1).toISOString();
    return {
      startDate: new Date().toISOString(),
      endDate,
    };
  }

  private calculateTwoWeekRangeFromToday(): CampaignCreateFormDateServiceFnPayload {
    const endDate = this.dateService.addWeeks(new Date(), 2).toISOString();
    return {
      startDate: new Date().toISOString(),
      endDate,
    };
  }

  private calculateOneMonthRangeFromToday(): CampaignCreateFormDateServiceFnPayload {
    const endDate = this.dateService.addMonths(new Date(), 1).toISOString();
    return {
      startDate: new Date().toISOString(),
      endDate,
    };
  }

  private calculateQuarterRangeFromToday(): CampaignCreateFormDateServiceFnPayload {
    const endDate = this.dateService.addMonths(new Date(), 3).toISOString();
    return {
      startDate: new Date().toISOString(),
      endDate,
    };
  }

  getRangeCalculatorFunctionByOptions(
    option: CampaignCreateFormDateRange
  ): () => CampaignCreateFormDateServiceFnPayload {
    switch (option) {
      case CampaignCreateFormDateRange.ONE_WEEK:
        return this.calculateOneWeekRangeFromToday.bind(this);
      case CampaignCreateFormDateRange.TWO_WEEKS:
        return this.calculateTwoWeekRangeFromToday.bind(this);
      case CampaignCreateFormDateRange.MONTH:
        return this.calculateOneMonthRangeFromToday.bind(this);
      case CampaignCreateFormDateRange.QUARTER:
        return this.calculateQuarterRangeFromToday.bind(this);
      default:
        return this.calculateOneWeekRangeFromToday.bind(this);
    }
  }
}
