import { FunctionComponent } from 'react';
import { BusinessUnitImpact } from 'shared/enums/business-unit.enum';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface BusinessImpactChipProps extends BaseComponentProps {
  impact: BusinessUnitImpact;
}

export const BusinessImpactChip: FunctionComponent<BusinessImpactChipProps> = ({
  impact,
}) => {
  const getChipClassname = (impact: BusinessUnitImpact) => {
    switch (impact) {
      case BusinessUnitImpact.HBI:
        return 'business-impact-high';
      case BusinessUnitImpact.MBI:
        return 'business-impact-medium';
      case BusinessUnitImpact.LBI:
        return 'business-impact-low';
    }
  };

  return (
    <div className={`business-impact-chip ${getChipClassname(impact)}`}>
      {impact}
    </div>
  );
};
