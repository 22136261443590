import DashboardReportsWidgetContainer from 'Analytics/DashboardReports/components/DashboardReportsWidgetContainer';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import HorizontalStackedBarChart from 'shared/components/HorizontalStackedBarChart';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import { AgGridEvent, GetRowIdParams } from 'ag-grid-community';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { CircularProgress } from '@mui/material';
import { useFetchComparativeDashboardWidgetDataMutation } from 'Dashboard/store/api';
import {
  ReportRuleContentWidgetId,
  ReportRuleProperty,
  ReportRulePropertyType,
} from 'shared/models/data/report-rule.model';
import { v4 as uuidv4 } from 'uuid';
import { AgGridRowData } from 'Dashboard/Comparative/components/ComparativeContent/ComparativeContent';
import { reportRuleWidgetOptions } from 'shared/fixtures/data/report-rule.data';
import { AutocompleteOption } from 'FindingDetails/store/api';

interface ReportOpenFindingsWidgetProps extends BaseComponentProps {
  type: 'group' | 'service';
  widgetId: ReportRuleContentWidgetId;
  searchParams?: Array<ReportRuleProperty>;
}

export const ReportOpenFindingsWidget: FunctionComponent<
  ReportOpenFindingsWidgetProps
> = ({ type, widgetId, searchParams }) => {
  const gridRef = useRef<AgGridEvent | null>(null);

  const [rowData, setRowData] = useState<any[]>([]);
  const [columnData, setColumnData] = useState<any[]>([]);

  const [fetchWidgetData, { data: widgetData, isLoading }] =
    useFetchComparativeDashboardWidgetDataMutation();

  const widgetTitle = useMemo(() => {
    return (
      reportRuleWidgetOptions.find(
        (widgetOption: AutocompleteOption) => widgetOption.value === widgetId
      )?.label || 'Open Findings'
    );
  }, [widgetId]);

  const scope = useMemo(() => {
    const scopeProperty = searchParams?.find(
      (param) => param.type === ReportRulePropertyType.SCOPE
    );

    return scopeProperty?.values[0]?.value;
  }, [searchParams]);

  const group = useMemo(() => {
    const groupProperty = searchParams?.find(
      (param) => param.type === ReportRulePropertyType.GROUP
    );

    return groupProperty?.values[0]?.value;
  }, [searchParams]);

  useEffect(() => {
    fetchWidgetData({
      scopeNodeId: scope,
      groupNodeId: group,
      filterEntity: type,
      type: widgetId,
    });
  }, [scope, group]);

  useEffect(() => {
    if (!isLoading && widgetData) {
      const agGridRowData: AgGridRowData[] = widgetData.labels.map(
        (label: string, rowIndex: number) => {
          const rowData: AgGridRowData = {
            id: uuidv4(),
            [type === 'service' ? 'services' : 'groups']: label,
          };
          widgetData.datasets.forEach((dataset: any) => {
            const columnName = dataset.label.toLowerCase().replace(' ', '_');
            rowData[columnName] = dataset.data[rowIndex];
          });

          return rowData;
        }
      );

      const columnDefs = [
        type === 'service'
          ? { headerName: 'Service', field: 'services', flex: 1 }
          : { headerName: 'Group', field: 'groups', flex: 1 },
        ...widgetData.datasets.map((dataset: any) => ({
          headerName: dataset.label,
          field: dataset.label.toLowerCase().replace(' ', '_'),
          flex: 1,
        })),
      ];

      setColumnData(columnDefs);
      setRowData(agGridRowData);
    }
  }, [isLoading, widgetData]);

  return (
    <DashboardReportsWidgetContainer title={widgetTitle}>
      <HorizontalStackedBarChart
        fetchWidgetData={() => {}}
        isWidgetDataLoading={isLoading}
        widgetData={widgetData}
        style={{
          ...presentationalConfigurationForWidgets[`comparative_${widgetId}`]
            ?.style,
          maxBarThickness: 20,
          borderRadius: 4,
        }}
        displayLabels={false}
        aspectRatio={0.3}
        dynamicHeight
        displayLegend
        legendPosition="bottom"
      />

      <CommonSimpleDataGrid
        rowData={rowData}
        columnDefs={columnData}
        rowSelection="multiple"
        defaultColDef={{
          resizable: true,
        }}
        gridRef={gridRef}
        containerClassName="settings-data-grid"
        suppressRowClickSelection
        onRowClicked={() => {}}
        visibilityControlProps={{
          enableVisibilityControls: false,
          columns: [],
        }}
        isLoading={isLoading}
        keepCurrentSelections
        otherComponents={{
          NoDataBackdropComponent: <NoDataBackdrop />,
        }}
        loadingOverlayComponent={() => <CircularProgress />}
        getRowId={(row: GetRowIdParams) => {
          return row.data.id;
        }}
        domLayout="autoHeight"
      />
    </DashboardReportsWidgetContainer>
  );
};
