import {
  FindingSeverity,
  FindingSeverityList,
} from 'FindingDetails/interfaces/severity';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { SelectChipCategory } from 'shared/components/SelectChipGroup/SelectChipGroup';
import { NotificationRuleTrigger } from 'shared/models/data/notification-rule.model';
import { capitalize } from 'lodash';
import * as yup from 'yup';
import { businessUnitEnvironmentTypesOptionList } from './business-unit.data';
import { useFetchFilterInformationForFieldMutation } from 'Risk/store/api';
import {
  ExtendedFilterCategory,
  FilterOption,
  FilterType,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import { StaticFilterOptions } from 'shared/components/FilterDropdown/FilterDropdown';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';

const dataFilterHandler = new DataFitlerHandler();

export const notificationRuleTriggerOptions: Array<AutocompleteOption> = [
  {
    value: NotificationRuleTrigger.FINDING_CLOSED,
    label: 'Finding closed',
  },
  {
    value: NotificationRuleTrigger.FINDING_CREATED,
    label: 'Finding opened',
  },
  {
    value: NotificationRuleTrigger.FINDING_SUPPRESSED,
    label: 'Finding suppressed',
  },
  {
    value: NotificationRuleTrigger.FINDING_IN_PROGRESS,
    label: 'Finding in-progress',
  },
  {
    value: NotificationRuleTrigger.FINDING_IN_REVIEW,
    label: 'Finding in-review',
  },
];

export const notificationRuleTicketTriggerOptions: Array<AutocompleteOption> = [
  {
    value: NotificationRuleTrigger.TICKET_CREATED,
    label: 'Ticket created',
  },
];

export const notificationRuleExceptionTriggerOptions: Array<AutocompleteOption> =
  [
    {
      value: NotificationRuleTrigger.EXCEPTION_REQUESTED,
      label: 'Exception Requested',
    },
    {
      value: NotificationRuleTrigger.EXCEPTION_APPROVED,
      label: 'Exception Approved',
    },
    {
      value: NotificationRuleTrigger.EXCEPTION_EXPIRED,
      label: 'Exception Expired',
    },
  ];

export const notificationRuleSLATriggerOptions: Array<AutocompleteOption> = [
  {
    value: NotificationRuleTrigger.FINDING_SLA_WARNING,
    label: 'finding nearly breaches SLA',
  },
  {
    value: NotificationRuleTrigger.FINDING_SLA_BREACHED,
    label: 'finding breaches SLA',
  },
];

export const notificationRuleStatusItems: Array<SelectChipCategory> = [
  {
    id: 'status',
    label: 'Status',
    loaded: true,
    optionList: notificationRuleTriggerOptions,
  },
];

const riskScoreOptionList = ['0-3', '3-6', '6-10'];

export const notificationRuleConditionOptions: Array<ExtendedFilterCategory> = [
  {
    id: 'title',
    label: 'Risk Name',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.API_DYNAMIC,
      source: {
        url: 'config/filters/search/title',
        method: 'POST',
        searchProperty: 'searchText',
        body: {
          take: 100,
          skip: 0,
        },
        optionsTransformer: dataFilterHandler.transformApiFilterOptionsPayload,
      },
    },
  },
  {
    id: 'resourceType',
    label: 'Resource Type',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.API_STATIC,
      source: {
        url: 'config/filters/search/resourceType',
        optionsTransformer: dataFilterHandler.transformApiFilterOptionsPayload,
      },
    },
  },

  {
    id: 'businessUnitId',
    label: 'Service',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.API_STATIC,
      source: {
        url: 'config/filters/search/businessUnitId',
        optionsTransformer: dataFilterHandler.transformApiFilterOptionsPayload,
      },
      extraOptions: [
        {
          value: null,
          label: StaticFilterOptions.NONE,
        },
      ],
      sortMethod: (options: Array<FilterOption>) =>
        dataFilterHandler.sortFilterOptions(options, ['None']),
    },
  },
  {
    id: 'businessUnitEnvironmentType',
    label: 'Environment',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.STATIC,
      source: {
        options: businessUnitEnvironmentTypesOptionList,
      },
    },
  },
  {
    id: 'riskScore',
    label: 'Risk Score',
    type: FilterType.NUMBER_RANGE,
    state: {
      minValue: 0,
      maxValue: 10,
    },
  },
  {
    id: 'findingType',
    label: 'Domain',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.API_STATIC,
      source: {
        url: 'config/filters/search/findingType',
        optionsTransformer: dataFilterHandler.transformApiFilterOptionsPayload,
      },
    },
  },
  {
    id: 'findingSource',
    label: 'Event Source',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.API_STATIC,
      source: {
        url: 'config/filters/search/findingSource',
        optionsTransformer: dataFilterHandler.transformApiFilterOptionsPayload,
      },
    },
  },
  {
    id: 'severity',
    label: 'Severity',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.API_STATIC,
      source: {
        url: 'config/filters/search/severity',
        optionsTransformer: dataFilterHandler.transformApiFilterOptionsPayload,
      },
    },
  },
];

export const notificationRuleConditionItems: Array<SelectChipCategory> = [
  {
    id: 'severity',
    label: 'Severity',
    optionList: FindingSeverityList.map((severityValue: number) => ({
      value: String(severityValue),
      label: capitalize(FindingSeverity[severityValue]),
    })),
    loaded: true,
  },
  {
    id: 'businessUnitId',
    label: 'Service',
    optionList: [],
    loaded: false,
  },
  {
    id: 'businessUnitEnvironmentType',
    label: 'Environment',
    optionList: businessUnitEnvironmentTypesOptionList,
    loaded: true,
  },
  {
    id: 'riskScore',
    label: 'Risk Score',
    optionList: riskScoreOptionList.map((riskSoreOption) => ({
      label: riskSoreOption,
      value: riskSoreOption,
    })),
    loaded: true,
  },
  {
    id: 'findingType',
    label: 'Domain',
    optionList: [],
    loaded: false,
    mutationProps: {
      fetchMutation: useFetchFilterInformationForFieldMutation,
      params: {},
    },
  },
  {
    id: 'findingSource',
    label: 'Event Source',
    optionList: [],
    loaded: false,
    mutationProps: {
      fetchMutation: useFetchFilterInformationForFieldMutation,
      params: {},
    },
  },
];

export const inRangePropertyNames = ['riskScore'];
export const hasAccessPropertyNames = ['group', 'scope'];

export const notificationRuleFormRequiredFields = ['name', 'email'];

export const notificationRuleSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  properties: yup.array().required(),
  triggers: yup.array().required(),
  emails: yup.array().required(),
  project: yup.string(),
});
