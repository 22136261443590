import { useAuthUser } from '@frontegg/react';
import { Avatar, Button, FormControl, TextField } from '@mui/material';
import Autocomplete from 'Common/components/Autocomplete';
import DatePicker from 'Common/components/DatePicker';
import useCommonSelector from 'Common/utils/use-selector';
import { AvailableUser } from 'FindingDetails/interfaces/Users';
import {
  AutocompleteOption,
  useFetchAvailableUsersToAssignMutation,
} from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getActiveFindingType } from 'Risk/store';
import {
  CampaignCreateFormBusinessPriority,
  CampaignCreateFormDateRange,
} from 'shared/enums/campaigns.enum';
import {
  campaignCreateFormBusinessPriorityOptions,
  campaignCreateFormDateRangeOptions,
  getInitialCampaignFormValidationErrors,
} from 'shared/fixtures/data/campaign-create-form.data';
import {
  CampaignCreateFormErrors,
  CampaignCreateFormValues,
} from 'shared/models/data/campaigns.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CampaignFormDateService } from 'shared/services/campaigns/campaign-form-date.service';

const campaignFormDateService = new CampaignFormDateService();

interface CampaignCreateFormProps extends BaseComponentProps {
  onCloseModal: () => void;
  onSubmit: (values: CampaignCreateFormValues) => void;
  campaignName?: string;
}

export const CampaignCreateForm: FunctionComponent<CampaignCreateFormProps> = ({
  onCloseModal,
  onSubmit,
  campaignName,
}) => {
  const { t: translation } = useTranslation();

  const initialCampaignFormValidationErrors =
    getInitialCampaignFormValidationErrors(translation);

  const activeFindingType = useCommonSelector(getActiveFindingType);

  const activeUser = useAuthUser();

  const [fetchUserList, { data: userList, isLoading: userListLoading }] =
    useFetchAvailableUsersToAssignMutation();

  const userOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (userList) {
      return userList?.map((user: AvailableUser) => ({
        value: user.id,
        label: user.name,
        iconUrl: user.profilePictureUrl,
      }));
    }

    return [];
  }, [userList]);

  useEffect(() => {
    fetchUserList({});
  }, []);

  const campaignFormDefaultValues = useMemo<CampaignCreateFormValues>(() => {
    const getDefaultDateRangeFunction =
      campaignFormDateService.getRangeCalculatorFunctionByOptions(
        CampaignCreateFormDateRange.MONTH
      );

    const defaultDateRange = getDefaultDateRangeFunction();

    return {
      name: activeFindingType?.findingTitle || campaignName,
      startTime: [defaultDateRange.startDate],
      endDate: [defaultDateRange.endDate],
      assignees: activeUser ? [activeUser?.id] : [],
      businessPriority: CampaignCreateFormBusinessPriority.MODERATE,
      dateRange: CampaignCreateFormDateRange.MONTH,
    };
  }, [activeFindingType, activeUser]);

  const campaignFormValidationErrors: CampaignCreateFormErrors = useMemo(
    () => ({
      ...initialCampaignFormValidationErrors,
      name:
        activeFindingType || campaignName
          ? null
          : initialCampaignFormValidationErrors['name'],
      businessPriority: null,
      assignees: activeUser
        ? null
        : initialCampaignFormValidationErrors['assignees'],
      dateRange: null,
      endDate: null,
      startTime: null,
    }),
    [activeFindingType, activeUser]
  );

  const [formErrors, setFormErrors] = useState<CampaignCreateFormErrors>(
    campaignFormValidationErrors
  );

  const [activeFormErrors, setActiveFormErrors] = useState<
    Partial<CampaignCreateFormErrors>
  >({});

  const [formValues, setFormValues] = useState<CampaignCreateFormValues>(
    campaignFormDefaultValues
  );

  const setFormValue = (
    inputName: keyof CampaignCreateFormValues,
    values: string | Array<string> | CampaignCreateFormBusinessPriority
  ) => {
    setFormValues({
      ...formValues,
      [inputName]: values,
    });

    if (values.length) {
      setFormErrors({
        ...formErrors,
        [inputName]: null,
      });
      setActiveFormErrors({
        ...activeFormErrors,
        [inputName]: null,
      });
    } else {
      setFormErrors({
        ...formErrors,
        [inputName]: initialCampaignFormValidationErrors[inputName],
      });
    }
  };

  const handleDateRangeSelection = (option: CampaignCreateFormDateRange) => {
    if (option === CampaignCreateFormDateRange.CUSTOM) {
      setFormValues({
        ...formValues,
        dateRange: option,
        startTime: '',
        endDate: '',
      });

      setFormErrors({
        ...formErrors,
        startTime: initialCampaignFormValidationErrors.startTime,
        endDate: initialCampaignFormValidationErrors.endDate,
      });
    } else {
      const getDateRangeFunction =
        campaignFormDateService.getRangeCalculatorFunctionByOptions(option);

      const dateRange = getDateRangeFunction();

      setFormValues({
        ...formValues,
        dateRange: option,
        startTime: [dateRange.startDate],
        endDate: [dateRange.endDate],
      });

      setFormErrors({
        ...formErrors,
        startTime: null,
        endDate: null,
      });
    }
  };

  const handleFormSubmit = () => {
    setActiveFormErrors(formErrors);

    const formHasError: boolean = Object.values(formErrors).some(
      (formErrorValue) => formErrorValue
    );
    if (!formHasError) {
      onSubmit(formValues);
    }
  };

  return (
    <div className="campaign-create-modal-form-container">
      <form className="campaign-create-modal-form">
        <FormControl>
          <div className="campaign-create-modal-form-label-text campaign-create-modal-form-name-label">
            What is the Campaign name?
          </div>
          <TextField
            className="campaign-create-modal-input"
            id="name"
            onChange={(event) => {
              setFormValue('name', event.target?.value);
            }}
            placeholder={'Campaign name'}
            value={formValues['name']}
          />
          {activeFormErrors.name && (
            <p className="campaign-create-form-error-text">
              {activeFormErrors.name}
            </p>
          )}
        </FormControl>
        <FormControl>
          <div className="campaign-create-modal-form-label-text">
            What is the length of the campaign, e.g., time to track?
          </div>
          <Autocomplete
            classes={{
              root: 'campaign-create-modal-select',
              popper: 'campaign-create-modal-select-popper',
            }}
            model="dateRange"
            onChangeCallBack={(
              model: string,
              AutocompleteOption: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              handleDateRangeSelection(
                (AutocompleteOption as AutocompleteOption)
                  .value as CampaignCreateFormDateRange
              );
            }}
            optionList={campaignCreateFormDateRangeOptions}
            single
            placeholder={translation(`campaigns.modal.placeholders.dateRange`)}
            initialSelectedValues={{
              value: formValues['dateRange'],
              label: formValues['dateRange'],
            }}
          />
          {activeFormErrors.dateRange && (
            <p className="campaign-create-form-error-text">
              {activeFormErrors.dateRange}
            </p>
          )}
        </FormControl>
        {formValues.dateRange === CampaignCreateFormDateRange.CUSTOM && (
          <>
            <FormControl className="campaign-create-form-group">
              <DatePicker
                label={'Start Date'}
                model="startTime"
                maxDate={formValues.endDate[0] as unknown as Date}
                onChangeCallBack={(model: string, values: Array<string>) => {
                  setFormValue(model as keyof CampaignCreateFormValues, values);
                }}
                classes={{
                  root: 'campaign-create-form-date-picker',
                }}
                range={false}
                placeholder={translation(
                  `campaigns.modal.placeholders.startTime`
                )}
              />
              {activeFormErrors.startTime && (
                <p className="campaign-create-form-error-text">
                  {activeFormErrors.startTime}
                </p>
              )}
            </FormControl>

            <FormControl className="campaign-create-form-group">
              <DatePicker
                label={'End Date'}
                model="endDate"
                minDate={formValues.startTime[0] as unknown as Date}
                onChangeCallBack={(model: string, values: Array<string>) => {
                  setFormValue(model as keyof CampaignCreateFormValues, values);
                }}
                classes={{
                  root: 'campaign-create-form-date-picker',
                }}
                range={false}
                placeholder={translation(
                  `campaigns.modal.placeholders.endDate`
                )}
              />
              {activeFormErrors.endDate && (
                <p className="campaign-create-form-error-text">
                  {activeFormErrors.endDate}
                </p>
              )}
            </FormControl>
          </>
        )}
        <FormControl>
          <div className="campaign-create-modal-form-label-text">
            Who are the relevant stakeholders?
          </div>
          <Autocomplete
            classes={{
              root: 'campaign-create-modal-select',
              popper: 'campaign-create-modal-select-popper',
            }}
            model="assignees"
            optionList={userOptionList}
            areOptionsLoaded
            getIcon={(option) => (
              <Avatar src={option.iconUrl} sx={{ width: 16, height: 16 }} />
            )}
            onChangeCallBack={(
              model: string,
              filterOptions: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(
                model as keyof CampaignCreateFormValues,
                (filterOptions as Array<AutocompleteOption>)?.map(
                  (AutocompleteOption: AutocompleteOption) =>
                    AutocompleteOption.value
                )
              );
            }}
            initialSelectedValues={[
              {
                value: activeUser?.id,
                label: activeUser?.name,
                iconUrl: activeUser?.profilePictureUrl as string,
              },
            ]}
          />
          {activeFormErrors.assignees && (
            <p className="campaign-create-form-error-text">
              {activeFormErrors.assignees}
            </p>
          )}
        </FormControl>
        <FormControl>
          <div className="campaign-create-modal-form-label-text">
            What is the current service priority of the campaign?
          </div>
          <Autocomplete
            classes={{
              root: 'campaign-create-modal-select',
              popper: 'campaign-create-modal-select-popper',
            }}
            model="businessPriority"
            optionList={campaignCreateFormBusinessPriorityOptions}
            single
            onChangeCallBack={(
              model: string,
              AutocompleteOption: Array<AutocompleteOption> | AutocompleteOption
            ) => {
              setFormValue(
                model as keyof CampaignCreateFormValues,
                (AutocompleteOption as AutocompleteOption).value
              );
            }}
            initialSelectedValues={{
              value: formValues['businessPriority'],
              label: formValues['businessPriority'],
            }}
          />
        </FormControl>
      </form>
      <div className="campaign-create-modal-form-buttons">
        <Button onClick={onCloseModal} className="campaign-modal-cancel-button">
          Cancel
        </Button>
        <Button
          className="campaign-modal-create-button"
          onClick={() => {
            handleFormSubmit();
          }}
        >
          Create
        </Button>
      </div>
    </div>
  );
};
