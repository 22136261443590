export class DateService {
  addWeeks(date: Date, weeks: number): Date {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
  }

  addMonths(date: Date, months: number): Date {
    return new Date(date.setMonth(date.getMonth() + months));
  }
}
