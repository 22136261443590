import { Dispatch, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
interface SearchFieldProps<T> {
  setTableItems: Dispatch<T[]>;
  items?: T[];
  filterPropertyName: string;
  fullWidth?: boolean;
  styleClass?: string;
}

export default function SearchField<T>({
  setTableItems,
  items,
  filterPropertyName,
  fullWidth = false,
  styleClass,
}: SearchFieldProps<T>) {
  const { t: translation } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  function onSearchChange(searchValue: string) {
    const matchesQuery = (item: any) =>
      item[filterPropertyName]
        .toLowerCase()
        .indexOf(searchValue.toLowerCase()) !== -1;
    const filteredItems = items?.filter(matchesQuery);
    setTableItems(filteredItems || []);
  }

  return (
    <div className="search-container">
      <TextField
        fullWidth={fullWidth}
        className={`${styleClass} search-input`}
        placeholder={
          isFocused
            ? translation('common.startTyping')
            : translation('common.searchPlaceholder')
        }
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => onSearchChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
