import { FunctionComponent, useEffect, useMemo } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import ImpactScoreChip from 'shared/components/ImpactScoreChip';
import { ElementWithLabel } from 'FindingDetails/components/FindingContent/components/FindingContentHeader/FindingContentHeader';
import { Box, Skeleton, Tooltip } from '@mui/material';
import CopyLinkButton from 'shared/components/CopyLinkButton/CopyLinkButton';
import { useSearchResourceAssetsMutation } from 'Risk/store/api';
import { useSearchParams } from 'react-router-dom';
import { AssetType } from 'Risk/interfaces/AssetType.enum';

interface ResourceAssetDetailsDrawerHeaderDataSource {
  name: string;
  logo: string;
}

export interface ResourceAssetDetailsDrawerHeaderData {
  title: string;
  logo: string;
  impactScore: number;
  findingsCount: number;
  criticalFindingsCount: number;
  highFindingsCount: number;
  mediumFindingsCount: number;
  sources: Array<ResourceAssetDetailsDrawerHeaderDataSource>;
}

interface ResourceAssetDetailsDrawerHeaderProps extends BaseComponentProps {
  resourceData: any;
  resourceDataLoading: boolean;
  resourceDataError: any;
}

export const ResourceAssetDetailsDrawerHeader: FunctionComponent<
  ResourceAssetDetailsDrawerHeaderProps
> = ({ resourceData, resourceDataLoading, resourceDataError }) => {
  const [searchParams] = useSearchParams();

  const [
    searchFindingsAggregation,
    { data: findingAggregationData, isLoading, error },
  ] = useSearchResourceAssetsMutation();

  const impactScore = useMemo<number>(() => {
    return findingAggregationData?.impactScore
      ? findingAggregationData.impactScore
      : 0;
  }, [findingAggregationData]);

  const openResourceId = useMemo<string | null>(() => {
    return searchParams.get('openResourceId') || null;
  }, [searchParams.get('openResourceId')]);

  useEffect(() => {
    if (openResourceId)
      searchFindingsAggregation({
        type: AssetType.FindingsAggregation,
        resourceExternalId: searchParams.get('openResourceId'),
      });
  }, [openResourceId]);

  if (isLoading)
    return (
      <Box className="main-content-skeleton">
        <Skeleton variant="rectangular" height={100} width={'100%'} />{' '}
      </Box>
    );

  if (error) return <></>;

  const renderSeverityChip = (
    count: number,
    label: string,
    className: string
  ) => {
    return (
      <div className={`resource-asset-details-severity-chip ${className}`}>
        <div className="resource-asset-details-severity-chip-count">
          {count}
        </div>
        <div className="resource-asset-details-severity-chip-label">
          {label}
        </div>
      </div>
    );
  };

  const renderSources = () => {
    return findingAggregationData?.findingsSources?.length ? (
      <div className="resource-asset-details-sources-container">
        {findingAggregationData?.findingsSources?.map(
          (source: ResourceAssetDetailsDrawerHeaderDataSource) => (
            <div className="resource-asset-details-sources-item">
              <img
                src={source.logo}
                alt={source.name}
                title={source.name}
              ></img>
            </div>
          )
        )}
      </div>
    ) : (
      <></>
    );
  };

  const renderSeverityChips = () => {
    return (
      <div className="resource-asset-details-severity-chip-container">
        {renderSeverityChip(
          findingAggregationData?.openFindingsBySeverityCounts['40'],
          'C',
          'resource-asset-details-severity-chip-critical'
        )}
        {renderSeverityChip(
          findingAggregationData?.openFindingsBySeverityCounts['30'],
          'H',
          'resource-asset-details-severity-chip-high'
        )}
        {renderSeverityChip(
          findingAggregationData?.openFindingsBySeverityCounts['20'],
          'M',
          'resource-asset-details-severity-chip-medium'
        )}
        {renderSeverityChip(
          findingAggregationData?.openFindingsBySeverityCounts['10'],
          'L',
          'resource-asset-details-severity-chip-low'
        )}
        {renderSeverityChip(
          findingAggregationData?.openFindingsBySeverityCounts['5'],
          'I',
          'resource-asset-details-severity-chip-informational'
        )}
      </div>
    );
  };

  const renderResourceTitleRow = () => {
    if (resourceDataLoading)
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={40} width={'100%'} />{' '}
        </div>
      );

    return (
      <div className="finding-content-header-title-row">
        <Tooltip title={''}>
          <div className="finding-content-header-icon">
            <img src={resourceData?.typeLogoUrl} alt={resourceData?.type} />
          </div>
        </Tooltip>
        <div className="finding-content-header-title">{resourceData?.name}</div>
        <CopyLinkButton
          excludeSearchParams={['filter', 'order', 'searchKeyword']}
        />
      </div>
    );
  };

  return (
    <div className="finding-content-header">
      {renderResourceTitleRow()}
      <div className="finding-content-header-elements">
        <ElementWithLabel label="Impact Score">
          <ImpactScoreChip value={+Number(impactScore).toFixed(2)} />
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="# Findings">
          <div className="resource-asset-details-findings-count">
            {findingAggregationData?.totalOpenFindingsCount}
          </div>{' '}
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        {renderSeverityChips()}
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="Sources">{renderSources()}</ElementWithLabel>
      </div>
    </div>
  );
};
