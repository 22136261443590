import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import { FindingBulkActionTypeEnum } from 'Risk/interfaces/FindingBulkActionType.enum';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { FindingTicketActionTypes } from 'shared/enums/FindingTicketActionTypes';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { ActionMenuListItem } from 'shared/models/data/action-menu-list.model';

export enum FindingActionMenuListType {
  JiraAction = 'jira-action-menu-list-item',
  AzureDevOpsAction = 'azure-action-menu-list-item',
  PullRequestAction = 'pull-request-action-menu-list-item',
  CodeAction = 'code-action-menu-list-item',
  ExceptionAction = 'exception-action',
  RequestExceptionAction = 'request_exception',
  OpenWorkItemAction = 'open_work_item',
  PullRequest = 'pull-request',
}

export const findingExceptionActionMenuItem: ActionMenuListItem = {
  id: FindingActionMenuListType.ExceptionAction,
  label: 'Request Exception',
  viewLabel: '',
  handleClick: () => {},
  getIcon: () => <></>,
  disabled: false,
  helperText: 'Request an exception for the resource',
  applicationId: ApplicationTypes.OPUS,
};

export const jiraActionMenuItem: ActionMenuListItem = {
  id: ApplicationTypes.JIRA_CLOUD_PLATFORM,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.JIRA_MAIN_ICON} />;
  },
  label: 'Open Jira Ticket',
  viewLabel: 'View Jira Ticket',
  handleClick: () => {},
  disabled: true,
  helperText: 'A Jira connection has not been yet configured.',
  applicationId: ApplicationTypes.JIRA_CLOUD_PLATFORM,
};

export const unlinkActionMenuItem: ActionMenuListItem = {
  id: FindingTicketActionTypes.UNLINK_TICKETS,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.LINK_SLASH_ICON} />;
  },
  label: 'Unlink Tickets',
  viewLabel: 'Unlink Tickets',
  handleClick: () => {},
  disabled: false,
  helperText: '',
  applicationId: FindingTicketActionTypes.UNLINK_TICKETS,
};

export const createTicketActionMenuItem: ActionMenuListItem = {
  id: FindingTicketActionTypes.CREATE_TICKETS,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.MULTI_TICKET_ICON} />;
  },
  label: 'Create Tickets',
  viewLabel: 'Create Tickets',
  handleClick: () => {},
  disabled: false,
  helperText: '',
  applicationId: FindingTicketActionTypes.CREATE_TICKETS,
};

export const createMultiFindingTicketActionMenuItem: ActionMenuListItem = {
  id: FindingTicketActionTypes.CREATE_MULTI_FINDING_TICKET,
  getIcon: () => {
    return (
      <OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} width={24} height={24} />
    );
  },
  label: 'Create Multi Finding Ticket',
  viewLabel: 'Create Multi Finding Ticket',
  handleClick: () => {},
  disabled: false,
  helperText: '',
  applicationId: FindingTicketActionTypes.CREATE_MULTI_FINDING_TICKET,
};

export const azureDevOpsMenuItem: ActionMenuListItem = {
  id: ApplicationTypes.AZURE_DEVOPS,
  getIcon: () => {
    return (
      <OpusImageIcon
        src="https://res.cloudinary.com/opus-security/image/upload/d_application-logos:application-default-logo.png/application-logos/AzureDevops.png"
        alt="azure-devops-menu-item"
        imageClassName="azure-devops-menu-item-icon"
      />
    );
  },
  label: 'Create Work Item',
  viewLabel: 'View Work Item',
  handleClick: () => {},
  disabled: true,
  helperText: 'An Azure DevOps connection has not been yet configured.',
  applicationId: ApplicationTypes.AZURE_DEVOPS,
};

export const codeFindingActionMenuItem: ActionMenuListItem = {
  id: ApplicationTypes.GITHUB,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.IAC_SOURCE_ICON} width="23" />;
  },
  label: 'Open Related Event',
  handleClick: () => {},
  disabled: false,
  helperText: '',
};
export const changeStatusActionMenuItem: ActionMenuListItem = {
  id: FindingBulkActionTypeEnum.CHANGE_STATUS,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_CHECK_ICON} />;
  },
  label: 'Change Status',
  viewLabel: 'Change Status',
  handleClick: () => {},
  disabled: false,
  helperText: '',
  permissions: [ApplicationPermission.FINDINGS_WRITE],
};
export const changeAssigneeActionMenuItem: ActionMenuListItem = {
  id: FindingBulkActionTypeEnum.ASSIGNEE_CHANGE,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.USER_PEN_ICON} />;
  },
  label: 'Change Assignee',
  viewLabel: 'Change Assignee',
  handleClick: () => {},
  disabled: false,
  helperText: '',
  permissions: [ApplicationPermission.FINDINGS_WRITE],
};
export const reRunActionMenuItem: ActionMenuListItem = {
  id: FindingBulkActionTypeEnum.RERUN,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.RERUN_ICON} width={18} />;
  },
  label: 'ReRun',
  viewLabel: 'ReRun',
  handleClick: () => {},
  disabled: false,
  helperText: '',
  permissions: [ApplicationPermission.SETTINGS_SYSTEM_ACTIONS],
};
export const reMapActionMenuItem: ActionMenuListItem = {
  id: FindingBulkActionTypeEnum.REMAP,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.SITEMAP_ICON} width={21} />;
  },
  label: 'ReMap',
  viewLabel: 'ReMap',
  handleClick: () => {},
  disabled: false,
  helperText: '',
  permissions: [ApplicationPermission.SETTINGS_SYSTEM_ACTIONS],
};

export const reAssociateActionMenuItem: ActionMenuListItem = {
  id: FindingBulkActionTypeEnum.REASSOCIATE,
  getIcon: () => {
    return <OpusSvgIcon type={SVG_ICON_TYPES.BRIEFCASE_ICON} width={21} />;
  },
  label: 'ReAssociate',
  viewLabel: 'ReAssociate',
  handleClick: () => {},
  disabled: false,
  helperText: '',
  permissions: [ApplicationPermission.SETTINGS_SYSTEM_ACTIONS],
};
