import { CircularProgress, Stack } from '@mui/material';
import { BusinessUnitDashboardSection } from './components/BusinessUnitDashboardSection';
import SectionBreaker from './components/Dashboard/SectionBreaker';
import { useEffect, useMemo } from 'react';
import HeaderComponent from 'shared/components/Header/HeaderComponent';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import TabList from 'shared/components/TabList';
import { DashboardTabPanel } from './interfaces/DashboardTabPanel.enum';
import { OperationalContent } from './components/OperationalContent/OperationalContent';
import CardWrapper from 'Common/components/CardWrapper';
import ScopeGroupFilter from './Comparative/components/ScopeGroupFilter/ScopeGroupFilter';
import { comparativeDashboardFilter } from './Comparative/interfaces/fixtures/data/comparative-dashboard-filter-option.data';
import {
  getFilterList,
  selectdashboardFilter,
  setListFilter,
  setListItems,
  setSelectedFindingTypes,
  setSelectedGroup,
  setSelectedScope,
  setSelectedServices,
  getSelectedScope,
  getSelectedGroup,
} from './store';
import PostureContent from './components/PostureContent';
import {
  useGetBusinessUnitListWithMetadataQuery,
  useGetUserAvailableNodesOfTypeMutation,
} from './store/api';
import { DashboardFilterType } from './interfaces/DashboardFilterType.enum';
import { MenuItems } from '../DashboardFilter/store';
import useCommonSelector from '../Common/utils/use-selector';
import useCommonDispatch from '../Common/utils/use-dispatch';
import DashboardFilterHandler from 'shared/handlers/dashboard-filter.handler';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';

export interface OrganizationalDataProps {
  scopeData?: Array<OrganizationNode>;
  groupData?: Array<OrganizationNode>;
}

export function DashboardPage() {
  const { data: businessUnitListWithMetadata, isLoading: businessUnitLoading } =
    useGetBusinessUnitListWithMetadataQuery('');
  const headerFilterIds: string[] = [
    DashboardFilterType.SCOPE,
    DashboardFilterType.GROUP,
    DashboardFilterType.SERVICES,
    DashboardFilterType.FINDING_TYPE,
  ];
  const filter = useCommonSelector(selectdashboardFilter);
  const allDataMenu: MenuItems = useCommonSelector(getFilterList);
  const dispatch = useCommonDispatch();

  const [getUserAvailableGroups, { data: groupData }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [getUserAvailableScopes, { data: scopeData }] =
    useGetUserAvailableNodesOfTypeMutation();

  const { t: translation } = useTranslation();
  let [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const activeTabId = useMemo<DashboardTabPanel>(() => {
    return urlSearchParams.activeTabId as DashboardTabPanel;
  }, [urlSearchParams]);
  const tabListItems = [
    {
      id: DashboardTabPanel.POSTURE,
      label: translation(`dashboards.tabs.posture`),
    },
    {
      id: DashboardTabPanel.OPERATIONAL,
      label: translation(`dashboards.tabs.operational`),
    },
  ];

  const renderContentForTab = () => {
    switch (activeTabId) {
      case DashboardTabPanel.OPERATIONAL:
        return (
          <OperationalContent scopeData={scopeData} groupData={groupData} />
        );
      case DashboardTabPanel.POSTURE:
        return <PostureContent scopeData={scopeData} groupData={groupData} />;

      default:
        if (!urlSearchParams.activeTabId) {
          setUrlSearchParams({
            ...urlSearchParams,
            activeTabId: DashboardTabPanel.POSTURE,
          });
        }
        return <PostureContent />;
    }
  };

  useEffect(() => {
    const findingTypeMenu = allDataMenu.find(
      (item) => item.id === DashboardFilterType.FINDING_TYPE,
    );
    if (findingTypeMenu) {
      const selectedFindingTypes = DashboardFilterHandler.getFilterValues(
        findingTypeMenu.items || [],
      );

      if (
        selectedFindingTypes.length !== 0 ||
        filter.findingTypes.length !== 0
      ) {
        dispatch(setSelectedFindingTypes(selectedFindingTypes));
      }
    }
  }, [allDataMenu]);

  useEffect(() => {
    getUserAvailableGroups({
      nodesType: OrganizationNodeType.LOGICAL,
    });
    getUserAvailableScopes({
      nodesType: OrganizationNodeType.SCOPE,
    });
  }, []);

  const renderTabSection = () => {
    if (businessUnitLoading) return <CircularProgress size={25} />;

    return (
      <>
        <Stack className="dashboard-page-body-control-row">
          <TabList
            items={tabListItems}
            activeItemId={activeTabId}
            onSelect={(activeItemId: DashboardTabPanel) => {
              setUrlSearchParams({
                ...urlSearchParams,

                activeTabId: activeItemId,
              });
            }}
            isUnderlined
          />
        </Stack>

        <div className="dashboard-page-content-body">
          {renderContentForTab()}
        </div>
      </>
    );
  };

  return (
    <div className="dashboard-page-container">
      <HeaderComponent text="Dashboard" />
      <div className="dashboard-page-body">
        <CardWrapper>
          <ScopeGroupFilter
            getters={{
              getFilterList: getFilterList,
              getSelectedGroup: getSelectedGroup,
              getSelectedScope: getSelectedScope,
            }}
            setters={{
              setListFilter: setListFilter,
              setListItems: setListItems,
              setSelectedGroup: setSelectedGroup,
              setSelectedScope: setSelectedScope,
              setSelectedServices: setSelectedServices,
            }}
            filterItems={comparativeDashboardFilter.filter((item) =>
              headerFilterIds.includes(item.id),
            )}
          />
        </CardWrapper>
        <BusinessUnitDashboardSection
          businessUnitLoading={businessUnitLoading}
          businessUnitListWithMetadata={businessUnitListWithMetadata}
          scopeData={scopeData}
          groupData={groupData}
        />
        <SectionBreaker />
        {renderTabSection()}
      </div>
    </div>
  );
}
