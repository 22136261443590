export enum BusinessUnitImpact {
  LBI = 'LBI',
  MBI = 'MBI',
  HBI = 'HBI',
}

export enum BusinessUnitRole {
  SECURITY_CHAMPION = 'Security Champion',
  APPLICATION_OWNER = 'Application Owner',
  DEVOPS_OWNER = 'DevOps Owner',
  IT_OWNER = 'IT Owner',
  APPSEC_OWNER = 'AppSec Owner',
}

export enum BusinessUnitRiskAttribute {
  INTERNET_FACING = 'Internet Facing',
  CROWN_JEWELS = 'Crown Jewels',
  CUSTOMER_FACING = 'Customer Facing',
  PII = 'PII',
  PCI = 'PCI',
  PHI = 'PHI',
}

export enum BusinessUnitComplianceRequirements {
  PCI_DSS = 'PCI-DSS',
  HIPAA = 'HIPAA',
  GDPR = 'GDPR',
  CCPA = 'CCPA',
  SOC2 = 'SOC2',
  ISO = 'ISO',
  FED_RAMP = 'FedRAMP',
  OTHER = 'Other',
}

export enum DrawerMode {
  EDIT = 'edit',
  CREATE = 'create',
  VIEW = 'view',
}
