import React, { FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContentSectionProps extends BaseComponentProps {
  title?: string;
  icon?: ReactNode;
  rootClassName?: string;
  iconClassName?: string;
  collapsable?: boolean;
  titleAdornment?: ReactNode;
}

export const ContentSection: FunctionComponent<ContentSectionProps> = ({
  title,
  icon,
  rootClassName,
  iconClassName,
  children,
  collapsable = false,
  titleAdornment,
}) => {
  const renderTitleAdornment = () => {
    if (titleAdornment) {
      return (
        <div className="content-section-title-adornment">{titleAdornment}</div>
      );
    }

    return <></>;
  };

  const renderSectionHeader = () => {
    return (
      <div className="content-section-header">
        {icon ? (
          <div className={`content-section-icon ${iconClassName || ''}`}>
            {icon}
          </div>
        ) : (
          <></>
        )}
        {title ? (
          <div className="content-section-title">
            <span className="content-section-title-text">{title}</span>
            {renderTitleAdornment()}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderSectionBody = () => {
    return (
      <div className={`content-section ${rootClassName || ''}`}>
        {(title || icon) && renderSectionHeader()}
        <div className="content-section-body">{children}</div>
      </div>
    );
  };

  return collapsable ? (
    <Accordion
      elevation={0}
      defaultExpanded={true}
      className={`content-section collapsable-section ${rootClassName || ''}`}
      sx={{ width: '100% !important' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        sx={{ padding: 0 }}
      >
        {renderSectionHeader()}
      </AccordionSummary>
      <AccordionDetails className="content-section-body" sx={{ padding: 0 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  ) : (
    renderSectionBody()
  );
};
