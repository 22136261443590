import { Alert, FormControl, Grid, Input, Typography } from '@mui/material';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import {
  CreateResourceOwner,
  ResourceOwnerCreateFormErrors,
  ResourceOwnerCreator,
} from 'FindingDetails/interfaces/ResourceOwner';
import {
  useCreateResourceOwnerMutation,
  useFetchResourcesQuery,
} from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonSnackbarContainer from 'shared/components/CommonSnackbarContainer';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { emailValidationExpression } from 'shared/fixtures/data/resource-owner.data';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface AddOwnerModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  eventId?: string;
}

export enum ItemType {
  RESOURCE = 'resource',
  CODE_EVENT = 'code-event',
}
export const AddOwnerModal: FunctionComponent<AddOwnerModalProps> = ({
  isOpen,
  setIsOpen,
  eventId,
}) => {
  const [urlSearchParams] = useQueryParams();
  const formElement = useRef<HTMLFormElement>(null);

  const [onSave, setOnSave] = useState<boolean>(false);
  const [displayErrorMessage, setDisplayErrorMessage] =
    useState<boolean>(false);
  const { t: translation } = useTranslation();
  const defaultErrors = {
    email: '',
    creationReason: '',
    createdBy: '',
    setAsDefault: '',
    itemType: '',
  };
  const { data: resources, isLoading: isLoadingResources } =
    useFetchResourcesQuery(urlSearchParams.openFindingId, {
      skip: !!eventId,
    });

  const handleDialogClose = (shouldReload?: boolean) => {
    setIsOpen(false);
    setOnSave(false);
    setFormErrors(defaultErrors);
    setFormValues(resourceOwnerFormDefaultValues);
    resetCreateResourceOwnerRequest();
  };

  const [
    createResourceOwner,
    {
      isLoading: creatingResourceOwner,
      isSuccess: createResourceOwnerSuccess,
      error: createResourceOwnerError,
      reset: resetCreateResourceOwnerRequest,
    },
  ] = useCreateResourceOwnerMutation();

  const resourceOwnerFormDefaultValues = useMemo<CreateResourceOwner>(() => {
    return {
      itemType: eventId ? ItemType.CODE_EVENT : ItemType.RESOURCE,
      email: '',
      creationReason: '',
      createdBy: ResourceOwnerCreator.USER,
      setAsDefault: false,
    };
  }, []);

  const resourceOwnerFormValidationErrors: ResourceOwnerCreateFormErrors =
    useMemo(
      () => ({
        email: translation('findings.details.emailRequired'),
        creationReason: translation('findings.details.reasonRequired'),
        createdBy: '',
        setAsDefault: '',
        itemType: '',
      }),
      []
    );
  const [formErrors, setFormErrors] =
    useState<ResourceOwnerCreateFormErrors>(defaultErrors);
  const [formValues, setFormValues] = useState<CreateResourceOwner>(
    resourceOwnerFormDefaultValues
  );

  useEffect(() => {
    if (onSave) onSubmit();
  }, [onSave]);

  const onSubmit = () => {
    const formHasError: boolean = Object.values(formErrors).some(
      (formErrorValue) => formErrorValue
    );
    if (!formHasError) {
      createResourceOwner({
        resourceId: eventId || (resources && resources[0].id),
        ...formValues,
      });
    }
    setOnSave(false);
  };

  useEffect(() => {
    if (createResourceOwnerSuccess) {
      handleDialogClose();
    }

    if (createResourceOwnerError) {
      setDisplayErrorMessage(true);
    }
  }, [createResourceOwnerError, createResourceOwnerSuccess]);

  const setFormValue = (
    inputName: keyof CreateResourceOwner,
    values: string | boolean
  ) => {
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      [inputName]: values,
    }));
    if ((values as string)?.length) {
      if (inputName === 'email') {
        const isValid = emailValidationExpression.test(values as string);
        setFormErrors({
          ...formErrors,
          [inputName]:
            inputName !== 'email'
              ? null
              : isValid
              ? null
              : 'Email field should be a valid email',
        });
      } else {
        setFormErrors({
          ...formErrors,
          [inputName]: null,
        });
      }
    } else {
      setFormErrors({
        ...formErrors,
        [inputName]: resourceOwnerFormValidationErrors[inputName],
      });
    }
  };

  return (
    <CommonDialog
      open={isOpen}
      onClose={handleDialogClose}
      title={translation('findings.details.addResourceOwner')}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.USER_ICON}></OpusSvgIcon>}
      loadingButtonText={translation('common.creating')}
      closeButtonText={translation('common.cancel')}
      saveButtonText={translation('common.create')}
      isLoading={creatingResourceOwner}
      onSave={() => {
        setOnSave(true);
      }}
    >
      <div className="common-form-container ">
        <form ref={formElement} onSubmit={onSubmit}>
          <Grid container minWidth={'500px'} rowGap={3}>
            <>
              <Grid item xs={12}>
                <Typography className="input-label">
                  <FormattedMessage
                    id="findings.details.email"
                    defaultMessage="Email"
                  />
                </Typography>
                <FormControl fullWidth className="input-dialog">
                  <Input
                    name="email"
                    placeholder=""
                    disableUnderline
                    className="email-input-field-1"
                    value={formValues.email}
                    onChange={(event: any) => {
                      setFormValue('email', event.target?.value);
                    }}
                    inputProps={{ required: true }}
                  />
                  {formErrors['email'] && (
                    <Typography variant="caption" color="error">
                      {formErrors['email']}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography className="input-label">
                  <FormattedMessage
                    id="findings.details.reason"
                    defaultMessage="Reason"
                  />
                </Typography>
                <FormControl fullWidth className="input-dialog">
                  <textarea
                    rows={2}
                    className="text-area-field-1"
                    name="creationReason"
                    placeholder=""
                    value={formValues.creationReason}
                    onChange={(event: any) => {
                      setFormValue('creationReason', event.target?.value);
                    }}
                    required
                  />
                  {formErrors['creationReason'] && (
                    <Typography variant="caption" color="error">
                      {formErrors['creationReason']}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </>
          </Grid>
        </form>
      </div>
      <CommonSnackbarContainer
        open={displayErrorMessage}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={3000}
        onClose={() => {
          setDisplayErrorMessage(false);
        }}
      >
        <Alert severity="error">
          {translation(`findings.details.addResourceOwnerError`)}
        </Alert>
      </CommonSnackbarContainer>
    </CommonDialog>
  );
};
