export enum SettingsTabPanel {
  SERVICE = 'service',
  CLOUD_WORKSPACES = 'cloud-workspaces',
  SLA = 'slas',
  TEAMS = 'teams',
  NOTIFICATION_RULES = 'notification-rules',
  REPORT_RULES = 'reports',
  ORGANIZATION = 'organization',
  USERS = 'users',
  RISK_CUSTOMIZATION = 'risk-customization',
}
