import { AvatarGroup, Avatar } from '@mui/material';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import AvatarBox from 'shared/components/AvatarBox';
import { BusinessUnitStakeholder } from 'shared/models/data/extended-business-unit.model';

const StakeholdersGroup = ({
  stakeholders,
}: {
  stakeholders: BusinessUnitStakeholder[];
}) => {
  return (
    <div className="stakeholders-group">
      {stakeholders.length === 1 ? (
        <AvatarBox
          name={stakeholders[0].fullName}
          profileUrl={stakeholders[0].avatarUrl}
          role={stakeholders[0].role}
        />
      ) : stakeholders.length > 1 ? (
        <AvatarGroup max={3} total={stakeholders.length}>
          {stakeholders
            .slice(0, 2)
            .map((stakeholder: BusinessUnitStakeholder, index: number) => (
              <CommonTooltip
                key={index}
                placement="top"
                title={
                  <AvatarBox
                    displayAvatar={false}
                    name={stakeholder.fullName}
                    role={stakeholder.role}
                  />
                }
              >
                <Avatar
                  className="avatar-container"
                  alt={stakeholder.fullName}
                  src={stakeholder.avatarUrl}
                />
              </CommonTooltip>
            ))}
        </AvatarGroup>
      ) : null}
    </div>
  );
};

export default StakeholdersGroup;
