import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from 'Auth/store';
import flowApi from 'Flow/store/api';
import diagramApi from 'WorkflowBuilder/store/api';
import connectionApi from 'Integrations/Connection/store/api';
import webhookApi from 'Integrations/Webhook/store/api';
import applicationApi from 'Application/store/api';
import settingsApi from 'Settings/store/api';
import connectionTemplateApi from 'ConnectionTemplate/store/api';
import diagramReducer from 'WorkflowBuilder/store';
import findingApi from 'FindingDetails/store/api';
import findingReducer from 'FindingDetails/store';
import dashboardApi from 'Dashboard/store/api';
import dashboardReducer from 'Dashboard/store';
import authApi from 'Auth/store/api';
import sharedApi from 'shared/store/api';
import automationManagementReducer from 'AutomationManagement/store';
import automationManagementApi from 'AutomationManagement/store/api';
import iacFindingsApi from 'IaCFindings/store/api';
import riskApi from 'Risk/store/api';
import riskReducer from 'Risk/store';
import CampaignsReducer from 'Campaigns/store';
import campaignsApi from 'Campaigns/store/api';
import filterDashboard from 'DashboardFilter/store';
import onboardingApi from '../../Onboarding/store/api';
import organizationApi from 'Organization/store/api';
import comparativeReducer from 'Dashboard/Comparative/store';
import settingsReducer from 'Settings/store';
import integrationsApi from 'Integrations/store/api';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    diagram: diagramReducer,
    finding: findingReducer,
    dashboard: dashboardReducer,
    automationManagement: automationManagementReducer,
    risks: riskReducer,
    campaigns: CampaignsReducer,
    filterDashboard: filterDashboard,
    comparative: comparativeReducer,
    settings: settingsReducer,
    [sharedApi.reducerPath]: sharedApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [findingApi.reducerPath]: findingApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    [connectionApi.reducerPath]: connectionApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [webhookApi.reducerPath]: webhookApi.reducer,
    [connectionTemplateApi.reducerPath]: connectionTemplateApi.reducer,
    [diagramApi.reducerPath]: diagramApi.reducer,
    [flowApi.reducerPath]: flowApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [automationManagementApi.reducerPath]: automationManagementApi.reducer,
    [iacFindingsApi.reducerPath]: iacFindingsApi.reducer,
    [riskApi.reducerPath]: riskApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [integrationsApi.reducerPath]: integrationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      sharedApi.middleware,
      findingApi.middleware,
      applicationApi.middleware,
      connectionApi.middleware,
      webhookApi.middleware,
      connectionTemplateApi.middleware,
      diagramApi.middleware,
      flowApi.middleware,
      dashboardApi.middleware,
      settingsApi.middleware,
      automationManagementApi.middleware,
      iacFindingsApi.middleware,
      riskApi.middleware,
      campaignsApi.middleware,
      onboardingApi.middleware,
      organizationApi.middleware,
      integrationsApi.middleware
    ),
});

export type CommonDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type CommonThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
