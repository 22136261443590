import { AutocompleteOption } from 'FindingDetails/store/api';
import { EnvironmentType } from 'Settings/interfaces/EnvironmentType.enum';
import { ConfigurationFieldValue } from 'shared/components/MultiSelectConfigurationField/MultiSelectConfigurationField';
import {
  BusinessUnitImpact,
  BusinessUnitRole,
} from 'shared/enums/business-unit.enum';
import * as yup from 'yup';

export const businessUnitFormSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  businessUnitImpact: yup.object().required(),
  associatedRepos: yup.array(),
  associatedAccounts: yup.array().min(1),
  associatedDomains: yup
    .string()
    .transform((value: Array<string>, originalValue: string) => {
      if (originalValue?.length) {
        return originalValue.split(',');
      }

      return [];
    }),
  assetGroups: yup
    .string()
    .transform((value: Array<string>, originalValue: string) => {
      if (originalValue?.length) {
        return originalValue.split(',');
      }

      return [];
    }),
  stakeholders: yup.array(),
  ticketingSystem: yup.object(),
  projectName: yup.string(),
  team: yup.object(),
  riskAttributes: yup.array(),
  complianceRequirements: yup.array(),
  associationRules: yup.array(),
});

export const businessUnitFormRequiredFields = [
  'name',
  'businessUnitImpact',
  'stakeholders',
  'groupNodeId',
  'orgScopeId',
];

export const businessUnitImpactOptionList: Array<AutocompleteOption> = [
  {
    value: BusinessUnitImpact.HBI,
    label: BusinessUnitImpact.HBI,
  },
  {
    value: BusinessUnitImpact.MBI,
    label: BusinessUnitImpact.MBI,
  },
  {
    value: BusinessUnitImpact.LBI,
    label: BusinessUnitImpact.LBI,
  },
];

export const businessImpactKeyToLabel: Record<BusinessUnitImpact, string> = {
  [BusinessUnitImpact.HBI]: 'High Service Impact',
  [BusinessUnitImpact.MBI]: 'Medium Service Impact',
  [BusinessUnitImpact.LBI]: 'Low Service Impact',
};

export const businessUnitEnvironmentTypesOptionList: Array<AutocompleteOption> =
  [
    {
      value: EnvironmentType.DEV,
      label: EnvironmentType.DEV,
    },
    {
      value: EnvironmentType.STAGING,
      label: EnvironmentType.STAGING,
    },
    {
      value: EnvironmentType.TESTING,
      label: EnvironmentType.TESTING,
    },
    {
      value: EnvironmentType.PROD,
      label: EnvironmentType.PROD,
    },
    {
      value: EnvironmentType.MIXED,
      label: EnvironmentType.MIXED,
    },
  ];

export const businessUnitRoleOptionList: Array<AutocompleteOption> = [
  {
    value: BusinessUnitRole.APPLICATION_OWNER,
    label: BusinessUnitRole.APPLICATION_OWNER,
  },
  {
    value: BusinessUnitRole.SECURITY_CHAMPION,
    label: BusinessUnitRole.SECURITY_CHAMPION,
  },
  {
    value: BusinessUnitRole.DEVOPS_OWNER,
    label: BusinessUnitRole.DEVOPS_OWNER,
  },
  {
    value: BusinessUnitRole.IT_OWNER,
    label: BusinessUnitRole.IT_OWNER,
  },
  {
    value: BusinessUnitRole.APPSEC_OWNER,
    label: BusinessUnitRole.APPSEC_OWNER,
  },
];

export const businessUnitStakeholdersDefaultValues: Array<ConfigurationFieldValue> =
  [
    {
      disableDelete: true,
      values: [
        {
          value: BusinessUnitRole.APPLICATION_OWNER,
          label: BusinessUnitRole.APPLICATION_OWNER,
          disabled: true,
        },
        {
          value: '',
          label: '',
        },
      ],
    },
  ];
