import { FunctionComponent, MouseEvent, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { ExtendedFilterCategory } from 'shared/models/data/data-filter.model';
import { FilterChipGroupChangeHandlerProps } from '../FilterChipGroup/FilterChipGroup';
import FilterContentDropdown from '../FilterContentDropdown';
import { useFeatureEntitlements } from '@frontegg/react';
import { NotEntitledJustification } from 'shared/components/EntitledContent/EntitledContent';

interface CategoryDropdownItemProps
  extends BaseComponentProps,
    ExtendedFilterCategory,
    FilterChipGroupChangeHandlerProps {}

export const CategoryDropdownItem: FunctionComponent<
  CategoryDropdownItemProps
> = ({
  id,
  label,
  type,
  state,
  categories,
  categoryState,
  onChange,
  entitlment,
}) => {
  const [itemAnchorEl, setItemAnchorEl] = useState<HTMLElement | null>(null);

  const { isEntitled, justification } = useFeatureEntitlements(
    entitlment || ''
  );

  const updatedState = useMemo(() => {
    const categoryStateItem = categoryState[id];

    return {
      ...state,
      ...(categoryStateItem || {}),
    };
  }, [state, categoryState, id]);

  const handleItemClick = (event: MouseEvent<HTMLDivElement>) => {
    setItemAnchorEl(event.currentTarget.parentElement);
  };

  const handleItemPopoverClose = () => {
    setItemAnchorEl(null);
  };

  if (
    !isEntitled &&
    justification !== NotEntitledJustification.MISSING_FEATURE
  ) {
    return <></>;
  }

  return (
    <>
      <div
        className={`category-dropdown-item ${
          itemAnchorEl ? 'category-dropdown-item-active' : ''
        }`}
        onClick={handleItemClick}
      >
        <span className="body-1 item-text">{label}</span>
        <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_RIGHT_ICON} />
      </div>

      <FilterContentDropdown
        {...{
          id,
          label,
          type,
          state: updatedState,
          anchorEl: itemAnchorEl,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          categoryState,
          handleClose: handleItemPopoverClose,
          onChange,
          categories,
          open: Boolean(itemAnchorEl),
        }}
      />
    </>
  );
};
