import {
  RiskCustomizationDto,
  RiskFactorSettingsModel,
  RuleCategory,
  RuleOperatorType,
  RuleProperty,
  RulePropertyInRange,
  RulePropertyType,
  RuleStatus,
} from 'Settings/interfaces/RiskCustomizationConfig';
import { RiskCustomizationConfigTypes } from 'Settings/interfaces/RiskCustomizationConfigTypes.enum';
import { hasAccessPropertyNames } from 'shared/fixtures/data/notification-rule.data';
import { inRangePropertyNames } from 'shared/fixtures/data/risk-customization-data';
import {
  CategoryState,
  ExtendedFilterCategory,
  MultiSelectState,
  NumberRangeState,
} from 'shared/models/data/data-filter.model';

export type RiskCustomizationBaseFormData = Record<
  'name' | 'description',
  string
>;

export type RiskCustomizationAdditionalFormData = {
  properties: Record<string, CategoryState>;
  values: RiskCustomizationDto;
};

export type RiskCustomizationDefaultFormData = RiskCustomizationBaseFormData &
  RiskCustomizationAdditionalFormData;

export class RiskCustomizationDataHandler {
  getDefaultFactors() {
    const defaultFactors = {} as any;

    for (const key of Object.values(RiskCustomizationConfigTypes)) {
      defaultFactors[key] = {
        weight: 0,
        multiplier: 0.1,
      };
    }
    return { factors: defaultFactors } as RiskCustomizationDto;
  }
  transformRiskCustomizationDataToFormData(
    riskConfig: any
  ): RiskCustomizationDefaultFormData {
    let ruleProperties: Record<string, CategoryState> = {};
    for (const property of riskConfig.properties) {
      if (inRangePropertyNames.includes(property.type)) {
        if (property.values)
          ruleProperties[property.type] = {
            startValue: (property.values as RulePropertyInRange).start,
            endValue: (property.values as RulePropertyInRange).end,
          } as NumberRangeState;
      } else {
        if ((property.values as Array<any>).length) {
          ruleProperties[property.type] = {
            selectedOptions: property.values as Array<any>,
          };
        }
      }
    }

    return {
      name: riskConfig.name,
      description: riskConfig.description as string,
      properties: ruleProperties,
      values: riskConfig.values,
    };
  }
  transformFactors(
    factors: Record<string, any>
  ): Record<string, RiskFactorSettingsModel> {
    return Object.fromEntries(
      Object.entries(factors).map(([key, value]) => [
        key,
        typeof value === 'number' ? { weight: value, multiplier: 0.1 } : value,
      ])
    );
  }
  transformRiskCustomizationFormDataToPostData(
    formData: RiskCustomizationBaseFormData,
    additionalFormData: RiskCustomizationAdditionalFormData,
    categories?: Array<ExtendedFilterCategory>
  ): any {
    let properties: Array<RuleProperty> = [];

    if (Object.keys(additionalFormData?.properties).length) {
      for (const propertyKey in additionalFormData.properties) {
        const parentCategory = categories?.find(
          (category: ExtendedFilterCategory) =>
            Boolean(
              category.categories?.find(
                (cat: ExtendedFilterCategory) => cat.id === propertyKey
              )
            )
        );

        if (inRangePropertyNames.includes(propertyKey)) {
          const typedState = additionalFormData.properties[
            propertyKey
          ] as NumberRangeState;

          properties.push({
            operator: RuleOperatorType.IN_RANGE,
            type: propertyKey as unknown as RulePropertyType,
            values: { start: typedState.startValue, end: typedState.endValue },
            category: parentCategory?.id as RuleCategory,
          });
        } else {
          const typedState = additionalFormData.properties[
            propertyKey
          ] as MultiSelectState;
          properties.push({
            operator: hasAccessPropertyNames.includes(propertyKey)
              ? RuleOperatorType.HAS_ACCESS
              : RuleOperatorType.IN,
            type: propertyKey as unknown as RulePropertyType,
            values: typedState?.selectedOptions || [],
            category: parentCategory?.id as RuleCategory,
          });
        }
      }
    }

    const transformedFactors = this.transformFactors(
      additionalFormData.values.factors
    );

    return {
      name: formData.name,
      description: formData.description,
      properties,
      values: { factors: transformedFactors },
      status: RuleStatus.ENABLED,
    };
  }
}
