import { DashboardReportsRiskWidgetProps } from 'Analytics/DashboardReports/components/DashboardReportsRisk/DashboardReportsRisk';
import DashboardWidgetCard from 'Dashboard/components/DashboardWidgetCard';
import { PostureWidgetCardProps } from 'Dashboard/components/PostureWidgetCard/PostureWidgetCard';
import { useGetDashboardPostureAnalyticsMutation } from 'Dashboard/store/api';
import { FunctionComponent, useEffect } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardReportsRiskTotalMetricsCardProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps,
    PostureWidgetCardProps {}

export const DashboardReportsRiskTotalMetricsCard: FunctionComponent<
  DashboardReportsRiskTotalMetricsCardProps
> = ({ title, icon, type, businessUnitIds }) => {
  const [
    getDashboardAnalytics,
    { data: dashboardAnalytics, isLoading: dashboardAnalyticsLoading },
  ] = useGetDashboardPostureAnalyticsMutation();

  useEffect(() => {
    if (businessUnitIds)
      getDashboardAnalytics({
        findingTypes: [],
        businessUnitIds,
        type,
      });
  }, [businessUnitIds]);

  return (
    <DashboardWidgetCard
      icon={icon}
      title={title}
      data={dashboardAnalytics}
      isLoading={dashboardAnalyticsLoading}
    />
  );
};
