import { Box, Skeleton, Typography, Tooltip, Alert } from '@mui/material';
import { formatDate } from 'Common/utils/utilsFunction';
import {
  SummaryResponse,
  useFindingSystemActionMutation,
  useOpenTicketMutation,
  useUnlinkFindingItemRelationMutation,
  useUpdateFindingMutation,
  useUpdateFindingStatusMutation,
} from 'FindingDetails/store/api';
import ActionButton from 'shared/components/ActionButton';
import { PersonPicker } from 'shared/components/DropdownPicker';
import { StatusPicker } from 'shared/components/DropdownPicker/StatusPicker';
import FindingExceptionCreateModal from 'shared/components/FindingExceptionCreateModal';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import MoreActionsMenu from 'shared/components/MoreActionsMenu';
import SlaChip from 'shared/components/SlaChip';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { TicketTypes } from 'shared/models/data/risk-table.model';
import { TicketModalConfiguration } from 'shared/models/data/ticket-modal-configuration.model';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import { useTranslation } from 'react-i18next';
import ActionTicketResponseModal from 'Risk/components/ActionTicketResponseModal/ActionTicketResponseModal';
import { OpenTicketData } from 'FindingDetails/interfaces/create-ticket';
import { FunctionComponent, useState, useEffect } from 'react';
import RiskChip from 'Risk/components/RiskChip';
import { BulkCreateTicketData } from 'shared/models/data/bulk-create-ticket-data.model';
import AddOwnerModal from '../AddOwnerModal';
import TicketCreateModal from 'Risk/components/TicketCreateModal';
import CommonSnackbarContainer from 'shared/components/CommonSnackbarContainer';
import FormattedMessage from 'shared/components/FormattedMessage';
import { AvailableUser } from '../../../../interfaces/Users';
import { AuthorizedContent } from '@frontegg/react';
import CopyLinkButton from 'shared/components/CopyLinkButton/CopyLinkButton';

export const ElementWithLabel = (props: any) => {
  return (
    <div className="element-with-label">
      {props.icon && <OpusSvgIcon type={props.icon} />}
      <Typography className="text-label">{props.label}</Typography>
      {props.children}
    </div>
  );
};

interface FindingContentHeaderProps extends BaseComponentProps {
  findingData?: SummaryResponse;
  refreshFindingData?: () => void;
  isLoadingData: boolean;
}

export const FindingContentHeader: FunctionComponent<
  FindingContentHeaderProps
> = ({ findingData, isLoadingData, refreshFindingData }) => {
  const [urlSearchParams] = useQueryParams();
  const permissionActions = usePermissions();
  const { t: translation } = useTranslation();

  const [ticketModalConfiguration, setTicketModalConfiguration] =
    useState<TicketModalConfiguration>({
      title: '',
      isOpen: false,
      errorMessage: '',
      findingsIds: [urlSearchParams.openFindingId],
      ticketType: TicketTypes.SINGLE_FINDING_TICKET,
    });

  const [bulkActionResponseModalOpen, setBulkActionResponseModalOpen] =
    useState<boolean>(false);
  const [ticketType, setTicketType] = useState<TicketTypes>();
  const [unlinkResponse, setUnlinkResponse] = useState<any>();
  const [createTicketResponse, setCreateTicketResponse] = useState<any>();
  const [isAddOwnerModalOpen, setIsAddOwnerModalOpen] =
    useState<boolean>(false);

  const [performFindingSystemAction, {}] = useFindingSystemActionMutation();

  const hasCreateItemRelationsPermission = permissionActions.includes(
    ApplicationPermission.FINDINGS_EXTERNAL_ITEM_WRITE
  );
  const hasFindingWritePermission = permissionActions.includes(
    ApplicationPermission.FINDINGS_WRITE
  );
  const hasExceptionWritePermission = permissionActions.includes(
    ApplicationPermission.EXCEPTIONS_WRITE_REQUEST
  );

  const [
    openTicket,
    {
      isLoading: isCreatingTicket,
      data: ticketCreateData,
      reset: resetTicketData,
    },
  ] = useOpenTicketMutation();

  useEffect(() => {
    if (unlinkResponse?.length || ticketCreateData?.length) {
      setBulkActionResponseModalOpen(true);
      setCreateTicketResponse(ticketCreateData);
      setUnlinkResponse(unlinkResponse);
    }
  }, [unlinkResponse, ticketCreateData]);

  useEffect(() => {
    if (unlinkResponse?.length && unlinkResponse[0]?.isSuccess) {
      refreshFindingDataHandler();
    }
  }, [unlinkResponse, bulkActionResponseModalOpen]);

  const [createExceptionModalOpen, setCreateExceptionModalOpen] =
    useState<boolean>(false);

  const refreshFindingDataHandler = () => {
    refreshFindingData && refreshFindingData();
  };

  if (isLoadingData || !findingData) {
    return (
      <Box className="main-content-skeleton">
        <Skeleton variant="rectangular" height={40} width={'100%'} />
        <Skeleton variant="rectangular" height={50} width={'100%'} />
        <Skeleton variant="rectangular" height={30} width={'100%'} />
      </Box>
    );
  }
  const handleCreateExceptionModalOpen = () => {
    setCreateExceptionModalOpen(true);
  };

  const handleCreateExceptionModalClose = () => {
    setCreateExceptionModalOpen(false);
  };

  const handleUnlinkAction = (decision: boolean, response: any) => {
    setTicketType(TicketTypes.UNLINK_TICKET);
    setUnlinkResponse(response);
  };

  const handleCreateTicketsModalOpen = () => {
    setTicketModalConfiguration({
      isOpen: true,
      errorMessage: translation('findings.ticket.createTicketFailed'),
      title: translation('findings.ticket.createTicket'),
      findingsIds: [urlSearchParams.openFindingId],
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.MULTI_TICKET_ICON} />,
      ticketType: TicketTypes.SINGLE_FINDING_TICKET,
    });
  };

  const handleResponseModalClose = () => {
    setBulkActionResponseModalOpen(false);
    resetTicketData();
    setUnlinkResponse([]);
  };

  const renderNotificationForOperation = () => {
    const createNotification = (
      responseItems: any,
      isOpen: boolean,
      handleClose: () => void
    ) => (
      <ActionTicketResponseModal
        isOpen={isOpen}
        handleClose={handleClose}
        responsePayload={responseItems}
        type={ticketType}
      />
    );

    if (unlinkResponse && unlinkResponse.length) {
      return createNotification(
        unlinkResponse,
        bulkActionResponseModalOpen,
        handleResponseModalClose
      );
    }

    if (createTicketResponse && createTicketResponse.length) {
      return createNotification(
        createTicketResponse,
        bulkActionResponseModalOpen,
        handleResponseModalClose
      );
    }

    return <></>;
  };
  const handleBulkTicketCreation = (
    ticketCreatePayload: BulkCreateTicketData
  ) => {
    setTicketType(ticketCreatePayload?.ticketType);
    openTicket({
      notifyTicketAssignee: false,
      findingId: urlSearchParams.openFindingId,
      ticketApplication: ticketCreatePayload.ticketApplication,
      projectKey: ticketCreatePayload.projectKey,
      assigneeEmail: ticketCreatePayload.assigneeEmail,
    } as OpenTicketData);
  };

  const handleAddOwner = () => {
    setIsAddOwnerModalOpen(true);
  };

  return (
    <div className="finding-content-header">
      {isCreatingTicket && (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Alert severity="info">
            <FormattedMessage
              id="findings.ticket.creatingTicket"
              defaultMessage="Creating Ticket"
            ></FormattedMessage>
            ...{' '}
          </Alert>
        </CommonSnackbarContainer>
      )}
      <div className="finding-content-header-title-row">
        <Tooltip title={findingData.sensor?.type || ''}>
          <div className="finding-content-header-icon">
            <img src={findingData.sensor?.logoUrl} />
          </div>
        </Tooltip>
        <div className="finding-content-header-title">{findingData?.title}</div>
        <CopyLinkButton
          excludeSearchParams={['filter', 'order', 'searchKeyword']}
        />
      </div>
      <div className="finding-content-header-elements">
        <ElementWithLabel label="Risk Score">
          <RiskChip riskScore={findingData.riskScore} />
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="SLA">
          <SlaChip slaDate={findingData.slaBreachDate} isUtc={true} />
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="Category">
          <Typography className="text-display">
            {findingData.category || translation(`common.missingValue`)}
          </Typography>
        </ElementWithLabel>
        <div className="finding-content-dot"></div>

        <ElementWithLabel label="Assignee">
          <PersonPicker
            selectedPerson={
              findingData.assignee
                ? ({
                    id: findingData.assignee.id,
                    name: findingData.assignee.name,
                    profilePictureUrl: findingData?.assignee.avatarUrl,
                  } as AvailableUser)
                : undefined
            }
            itemId={findingData.id}
            property={'findingId'}
            updateItemMutation={useUpdateFindingMutation}
            readonly={!hasFindingWritePermission}
          />
        </ElementWithLabel>
        <div className="finding-content-dot"></div>
        <ElementWithLabel label="Status">
          <StatusPicker
            readonly={!hasFindingWritePermission}
            selectedStatusValue={findingData.status}
            itemId={findingData.id}
            property={'findingId'}
            updateItemMutation={useUpdateFindingStatusMutation}
          />
        </ElementWithLabel>
      </div>
      <div className="finding-content-header-actions">
        {hasCreateItemRelationsPermission && (
          <>
            {findingData.hasTicket ? (
              <DeleteWithConfirm
                id={urlSearchParams.openFindingId}
                setShouldIgnoreRowClick={null}
                useMutation={useUnlinkFindingItemRelationMutation}
                title={translation('findings.ticket.unlinkTicket')}
                modalIcon={
                  <OpusSvgIcon type={SVG_ICON_TYPES.LINK_SLASH_ICON} />
                }
                message={translation('findings.ticket.unlinkTicketWarning')}
                callback={handleUnlinkAction}
                confimButtonLabel={translation('findings.ticket.unlink')}
                loadingConfimButtonLabel={translation(
                  'findings.ticket.unlinking'
                )}
              >
                <ActionButton icon={SVG_ICON_TYPES.LINK_SLASH_ICON}>
                  {translation('findings.ticket.unlinkTicket')}
                </ActionButton>
              </DeleteWithConfirm>
            ) : (
              <ActionButton
                icon={SVG_ICON_TYPES.TICKET_ICON}
                onClick={handleCreateTicketsModalOpen}
                disabled={isCreatingTicket}
              >
                {translation('findings.ticket.createTicket')}
              </ActionButton>
            )}
          </>
        )}

        <ActionButton
          disabled={!hasExceptionWritePermission}
          icon={SVG_ICON_TYPES.LIGHTBULB_ICON}
          onClick={handleCreateExceptionModalOpen}
        >
          {translation(`findings.details.status.exception`)}
        </ActionButton>
        <ActionButton
          icon={SVG_ICON_TYPES.USER_ADD_ICON}
          onClick={handleAddOwner}
          disabled={!hasFindingWritePermission}
        >
          {translation(`findings.details.addOwner`)}
        </ActionButton>
        <AuthorizedContent
          requiredPermissions={[ApplicationPermission.SETTINGS_SYSTEM_ACTIONS]}
        >
          <MoreActionsMenu
            actions={[
              {
                name: translation(
                  `findings.details.systemActions.rerun.shortTitle`
                ),
                icon: SVG_ICON_TYPES.RERUN_ICON,
                onClick: () => {
                  performFindingSystemAction({
                    findingId: urlSearchParams.openFindingId,
                    action: 'rerun',
                  });
                },
              },
              {
                name: translation(
                  `findings.details.systemActions.remap.shortTitle`
                ),
                icon: SVG_ICON_TYPES.SITEMAP_BLACK_ICON,
                onClick: () => {
                  performFindingSystemAction({
                    findingId: urlSearchParams.openFindingId,
                    action: 'remap',
                  });
                },
              },
              {
                name: translation(`findings.details.systemActions.reAssociate`),
                icon: SVG_ICON_TYPES.BRIEFCASE_ICON,
                onClick: () => {
                  performFindingSystemAction({
                    findingId: urlSearchParams.openFindingId,
                    action: 'reAssociate',
                  });
                },
              },
            ]}
          />
        </AuthorizedContent>
        <div className="updates-info-container">
          <ElementWithLabel
            label={translation(`findings.details.lastUpdated`)}
            icon={SVG_ICON_TYPES.SIMPLE_CALENDAR_ICON}
          >
            <Typography className="text-display">
              {formatDate(findingData.updatedAt)}
            </Typography>
          </ElementWithLabel>
          <ElementWithLabel
            label={translation(`findings.details.firstSeen`)}
            icon={SVG_ICON_TYPES.VISIBILITY_ICON}
          >
            <Typography className="text-display">
              {formatDate(findingData.externalCreatedAt)}
            </Typography>
          </ElementWithLabel>
        </div>
      </div>

      <FindingExceptionCreateModal
        isOpen={createExceptionModalOpen}
        handleClose={handleCreateExceptionModalClose}
      />
      {renderNotificationForOperation()}
      <TicketCreateModal
        handleModalClose={() => {
          setTicketModalConfiguration({
            ...ticketModalConfiguration,
            isOpen: false,
          });
        }}
        findingsCount={1}
        configuration={ticketModalConfiguration}
        handleBulkTicketCreation={handleBulkTicketCreation}
        ticketType={ticketModalConfiguration.ticketType}
      />
      <AddOwnerModal
        isOpen={isAddOwnerModalOpen}
        setIsOpen={setIsAddOwnerModalOpen}
      ></AddOwnerModal>
    </div>
  );
};
