import { Button, Skeleton } from '@mui/material';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import {
  AutocompleteOption,
  useGetFindingEvidenceContentQuery,
} from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import FindingItem from 'shared/components/FindingItem';
import IconBox from 'shared/components/IconBox';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import Cloud2CodeWorkflow from 'Cloud2Code/components/Cloud2CodeWorkflow';
import { ResourceAssetDetailsDrawerContentSectionProps } from '../ResourceAssetDetailsDrawerContent/ResourceAssetDetailsDrawerContent';
import {
  useFetchFilterInformationForFieldMutation,
  useSearchResourceAssetsMutation,
} from 'Risk/store/api';
import { useSearchParams } from 'react-router-dom';
import { AssetType } from 'Risk/interfaces/AssetType.enum';
import { CloudWorkspaceType } from 'Settings/interfaces/CloudWorkspaceType.enum';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { GridType } from 'Risk/store';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';

export interface ResourceAssetDetailsDrawerResourceInfoData {
  resource: {
    id: string;
    name: string;
    resourceId: string;
    resourceTypeLogo: string;
    type: string;
    additionalProperties: any;
    tags: Array<{ tagName: string; tagValue: string }>;
  };
  environment: {
    businessUnitEnvironmentType: string;
    businessUnitId: string;
    businessUnitName: string;
    businessUnitOwner: string;
    cloudEnvironmentAlias: string;
    cloudProvider: string;
    cloudProviderLogo: string;
    cloudWorkspaceExternalId: string;
    cloudWorkspaceId: string;
    businessUnitGroupName: string;
  };
  cloudToCode: {
    codeFixSuggestion: string;
    fileName: string;
    findingsCount: number;
    flow: any;
    repositoryLink: string;
    repositoryLogoUrl: string;
    repositoryName: string;
    id: string;
  };
}

interface ResourceAssetDetailsDrawerResourceInfoProps
  extends BaseComponentProps {
  resourceData: any;
  resourceDataLoading: boolean;
  resourceDataError: any;
}

const navigationHandler = new NavigationHandler();

export const ResourceAssetDetailsDrawerResourceInfo: FunctionComponent<
  ResourceAssetDetailsDrawerResourceInfoProps
> = ({ resourceData, resourceDataError, resourceDataLoading }) => {
  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  let [urlSearchParams, setUrlSearchParams, getUrlSearchParams] =
    useQueryParams();
  const { t: translation } = useTranslation();
  const [showMoreTags, setShowMoreTags] = useState<boolean>(false);

  const [fetchServiceFilterInformationForField, { data: serviceOptions }] =
    useFetchFilterInformationForFieldMutation();

  const [getUserAvailableNodesOfType, { data: nodeData }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [
    searchCloudToCodeData,
    {
      data: cloudToCodeData,
      isLoading: cloudToCodeDataLoading,
      error: cloudToCodeDataError,
    },
  ] = useSearchResourceAssetsMutation();

  useEffect(() => {
    getUserAvailableNodesOfType({
      nodesType: OrganizationNodeType.LOGICAL,
    });
    fetchServiceFilterInformationForField({
      field: 'businessUnitId',
    });
  }, []);

  const [
    searchEnvironmentData,
    {
      data: environmentData,
      isLoading: environmentDataLoading,
      error: environmentDataError,
    },
  ] = useSearchResourceAssetsMutation();

  const openResourceId = useMemo<string | null>(() => {
    return reactiveSearchParams.get('openResourceId') || null;
  }, [reactiveSearchParams.get('openResourceId')]);

  useEffect(() => {
    if (openResourceId) {
      searchEnvironmentData({
        type: AssetType.EnvironmentData,
        resourceExternalId: openResourceId,
      });

      searchCloudToCodeData({
        type: AssetType.CloudToCodeData,
        resourceExternalId: openResourceId,
      });
    }
  }, [openResourceId]);

  const renderResourceTags = () => {
    if (!resourceData?.tags || resourceData?.tags?.length === 0) {
      return <p>{translation(`common.noTagsAvailable`)}</p>;
    }

    const tags = resourceData?.tags as Array<any>;

    if (showMoreTags) {
      return (
        <>
          {tags.map((tag) => (
            <div className="finding-section-resource-tag">
              <div className="finding-section-resource-tag-name">
                {tag.tagName}:
              </div>
              <div className="finding-section-resource-tag-value">
                {tag.tagValue}
              </div>
            </div>
          ))}

          <Button
            className="base-opus-text-button finding-section-resource-tag-show-more"
            onClick={() => {
              setShowMoreTags(false);
            }}
          >
            {translation(`common.showLess`)}
          </Button>
        </>
      );
    }

    const mainTags = tags.slice(0, 5);
    const additionalTags = tags.slice(5);

    if (additionalTags.length) {
      return (
        <>
          {mainTags.map((tag) => {
            return (
              <div className="finding-section-resource-tag">
                <div className="finding-section-resource-tag-name">
                  {tag.tagName}:
                </div>
                <div className="finding-section-resource-tag-value">
                  {tag.tagValue}
                </div>
              </div>
            );
          })}
          <Button
            className="base-opus-text-button finding-section-resource-tag-show-more"
            onClick={() => {
              setShowMoreTags(true);
            }}
          >
            {translation(`common.showMore`)}
          </Button>
        </>
      );
    }

    return mainTags.map((tag) => {
      return (
        <div className="finding-section-resource-tag">
          <div className="finding-section-resource-tag-name">
            {tag.tagName}:
          </div>
          <div className="finding-section-resource-tag-value">
            {tag.tagValue}
          </div>
        </div>
      );
    });
  };

  const renderEnvironmentSubSection = () => {
    if (environmentDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={100} width={'100%'} />
        </div>
      );
    }

    if (environmentData) {
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.SQUARE_CODE_ICON} />}
          title={translation(`findings.subSections.environment`)}
        >
          <div className="finding-content-environment-sub-section-body">
            <FindingItem title={translation(`findings.subSections.service`)}>
              {environmentData?.serviceName ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    const service = serviceOptions?.find(
                      (serviceOption: AutocompleteOption) =>
                        serviceOption.label === environmentData?.serviceName
                    );

                    navigationHandler.handleRiskNavigation(GridType.None, {
                      businessUnitId: [
                        {
                          id: service?.value,
                          name: service?.label,
                        },
                      ],
                    });
                  }}
                >
                  <InteractiveLabel
                    icon={<OpusSvgIcon type={SVG_ICON_TYPES.BRIEFCASE_ICON} />}
                    label={environmentData?.serviceName}
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem title={translation(`findings.subSections.group`)}>
              {environmentData?.groupName ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    const groupNode = nodeData?.find(
                      (nodeDataItem: OrganizationNode) =>
                        nodeDataItem.name === environmentData?.groupName
                    );

                    navigationHandler.handleRiskNavigation(GridType.None, {
                      groupId: {
                        id: groupNode?.id,
                        name: groupNode?.name,
                      },
                    });
                  }}
                >
                  <InteractiveLabel
                    icon={
                      <OpusSvgIcon type={SVG_ICON_TYPES.PEOPLE_GROUP_ICON} />
                    }
                    label={environmentData?.groupName}
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem title={translation(`findings.subSections.workspace`)}>
              {environmentData?.workspaceData?.alias ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    navigationHandler.handleRiskNavigation(GridType.None, {
                      workspace: [
                        {
                          id: environmentData?.workspaceData?.id,
                          name: environmentData?.workspaceData?.alias,
                        },
                      ] as Array<FilterOption>,
                    });
                  }}
                >
                  <IconBox
                    icon={
                      <div className="finding-content-workspace-icon">
                        <img
                          src={environmentData?.workspaceData?.providerLogoUrl}
                        ></img>
                      </div>
                    }
                    helperText={environmentData?.workspaceData?.externalId}
                    label={environmentData?.workspaceData?.alias}
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem title={translation(`findings.subSections.type`)}>
              {environmentData?.workspaceData?.environmentType ? (
                <InteractiveLabel
                  icon={<OpusSvgIcon type={SVG_ICON_TYPES.CLOUD_ICON} />}
                  label={environmentData?.workspaceData?.environmentType}
                />
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
          </div>
        </ContentSection>
      );
    }

    return <></>;
  };

  const renderResourceAdditionalProperties = () => {
    if (
      resourceData?.additionalProperties &&
      Object.keys(resourceData?.additionalProperties)?.length
    ) {
      const additionalProperties = resourceData?.additionalProperties;

      return (
        <div className="finding-content-resource-sub-section-additonal-properties-row">
          {Object.keys(additionalProperties)?.map((additionalPropertyKey) => (
            <FindingItem title={additionalPropertyKey}>
              <p
                title={
                  additionalProperties[
                    additionalPropertyKey as keyof typeof additionalProperties
                  ]
                }
              >
                {
                  additionalProperties[
                    additionalPropertyKey as keyof typeof additionalProperties
                  ]
                }
              </p>
            </FindingItem>
          ))}
        </div>
      );
    }

    return <></>;
  };
  const handleGoToRootCause = () => {
    const codeEventId = cloudToCodeData?.codeEventDetails?.id;
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    updatedReactiveSearchParams.set('openCodeEventId', codeEventId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const renderResourceSubSection = () => {
    if (resourceDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={150} width={'100%'} />
        </div>
      );
    }

    if ((!resourceDataLoading && !resourceData) || resourceDataError) {
      return <></>;
    }

    if (resourceData) {
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section finding-content-sub-section-no-title"
          icon={<></>}
          title=""
        >
          <div className="finding-content-resource-sub-section-body">
            <div className="finding-content-resource-sub-section-main-row">
              <FindingItem
                title={translation(`findings.subSections.resourceName`)}
              >
                <IconBox
                  icon={
                    <div className="finding-content-resource-logo">
                      <img
                        src={resourceData?.typeLogoUrl}
                        alt={resourceData?.type}
                      ></img>
                    </div>
                  }
                  helperText={resourceData?.type}
                  label={resourceData?.name}
                />
              </FindingItem>
              <FindingItem
                title={translation(`findings.subSections.resourceId`)}
              >
                <div
                  className="finding-content-resource-item-body-collapsable-text"
                  title={resourceData?.resourceId}
                >
                  {resourceData?.resourceId}
                </div>
              </FindingItem>
            </div>
            {renderResourceAdditionalProperties()}
            <div className="finding-content-resource-sub-section-tags-row">
              <FindingItem title={translation(`findings.subSections.tags`)}>
                {renderResourceTags()}
              </FindingItem>
            </div>
          </div>
        </ContentSection>
      );
    }

    return <></>;
  };

  const renderCloudToCodeSection = () => {
    if (cloudToCodeDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={200} width={'100%'} />
        </div>
      );
    }

    if (cloudToCodeData?.codeEventDetails) {
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.CHART_NETWORK_ICON} />}
          title={translation(`findings.subSections.cloudToCode`)}
        >
          <div className="finding-content-cloud-to-code-sub-section-body">
            <div className="finding-content-cloud-to-code-sub-section-main-row finding-content-section-row">
              <FindingItem title={translation(`findings.subSections.fileName`)}>
                {' '}
                <p>{cloudToCodeData?.codeEventDetails?.fileName}</p>
              </FindingItem>
              <FindingItem
                title={translation(`findings.subSections.repository`)}
              >
                <InteractiveLabel
                  label={cloudToCodeData?.codeEventDetails?.repositoryName}
                  icon={
                    <div className="finding-content-application-icon">
                      <img
                        src={
                          cloudToCodeData?.codeEventDetails?.repositoryLogoUrl
                        }
                      ></img>
                    </div>
                  }
                  link={cloudToCodeData?.codeEventDetails?.repositoryLink}
                />
              </FindingItem>

              <FindingItem
                title={translation(`findings.subSections.associatedFindings`)}
              >
                <p>{cloudToCodeData?.codeEventDetails?.findingsCount}</p>
              </FindingItem>

              <FindingItem
                title={translation(
                  `findings.subSections.suggestedFixAvailable`
                )}
              >
                <p>
                  {translation(
                    `common.${
                      cloudToCodeData?.codeEventDetails?.codeFixSuggestion
                        ? 'yes'
                        : 'no'
                    }`
                  )}
                </p>
              </FindingItem>
            </div>
            <div className="finding-content-cloud-to-code-sub-section-workflow-row">
              <Cloud2CodeWorkflow
                flowData={cloudToCodeData?.codeEventDetails?.flow}
              />
            </div>
            <div
              className="finding-content-link clickable-item"
              onClick={handleGoToRootCause}
            >
              <span>{translation(`findings.subSections.goToRootCause`)}</span>
              <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
            </div>
          </div>
        </ContentSection>
      );
    }

    return <></>;
  };

  const renderSectionBody = () => {
    return (
      <div className="finding-content-section-body">
        {renderResourceSubSection()}
        {renderCloudToCodeSection()}
        {renderEnvironmentSubSection()}
      </div>
    );
  };

  return (
    <div className="finding-content-item finding-content-evidence-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.BOX_ICON} />}
        title="Resource Info"
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};
