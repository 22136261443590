import * as yup from 'yup';
import { useAuthUser } from '@frontegg/react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { usePostFindingTimelineCommentMutation } from '../../store/api';
import OpusAvatarIcon from 'shared/components/IconComponents/OpusAvatarIcon';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import {
  CommonButton,
  CommonButtonType,
} from '../../../shared/components/CommonButton/CommonButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

interface Props {
  findingId: string;
  onNewComment?: (userId: string, comment: string) => void;
}

export default function TimelineEventAddComment({
  findingId,
  onNewComment,
}: Props) {
  const { t: translation } = useTranslation();

  const { id: userId, profilePictureUrl, email } = useAuthUser();

  const [addTimelineComment, { isLoading }] =
    usePostFindingTimelineCommentMutation();
  const {
    register,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        message: yup.string().required(),
      }),
    ),
    defaultValues: {
      message: '',
    },
  });

  const formSubmitHandler = (event: any) => {
    event.preventDefault();
    handleSubmit(formValidHandler)();
  };

  const formValidHandler = (formValues: any) => {
    addTimelineComment({
      findingId,
      body: {
        message: formValues.message,
        applicationId: 'Opus',
        actorType: 0,
      },
    });
    onNewComment && onNewComment(userId, formValues.message);
    resetForm();
  };

  return (
    <Box className="add-comment-container">
      <div className="comment-indicator default-indicator" />
      <form onSubmit={formSubmitHandler}>
        <Stack direction="row" spacing={1}>
          <OpusAvatarIcon
            alt={email}
            src={profilePictureUrl as string}
            title={email}
            sx={{ width: '24px', height: '24px' }}
          />
          <textarea
            rows={4}
            className={'text-area-field-1 add-comment'}
            placeholder={translation(`findings.details.postComment`)}
            {...register('message')}
          ></textarea>
        </Stack>
        <Box mb="1rem" />
        <Stack direction="row" justifyContent="flex-end">
          <CommonButton type={CommonButtonType.PRIMARY} buttonType={'submit'}>
            <FormattedMessage
              id="common.comment"
              defaultMessage="Comment"
              capitalize
            />
          </CommonButton>
        </Stack>
      </form>
      {isLoading && <CircularProgress size={24} />}
    </Box>
  );
}
