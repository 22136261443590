import { FunctionComponent, useEffect, useMemo } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import ResourceAssetDetailsDrawerHeader from '../ResourceAssetDetailsDrawerHeader';
import { ResourceAssetDetailsDrawerHeaderData } from '../ResourceAssetDetailsDrawerHeader/ResourceAssetDetailsDrawerHeader';
import ResourceAssetDetailsDrawerResourceInfo from '../ResourceAssetDetailsDrawerResourceInfo';
import ResourceAssetDetailsDrawerOwners from '../ResourceAssetDetailsDrawerOwners';
import ResourceAssetDetailsDrawerAdditionalData from '../ResourceAssetDetailsDrawerAdditionalData';
import { ResourceAssetDetailsDrawerResourceInfoData } from '../ResourceAssetDetailsDrawerResourceInfo/ResourceAssetDetailsDrawerResourceInfo';
import { ResourceAssetDetailsDrawerOwnersData } from '../ResourceAssetDetailsDrawerOwners/ResourceAssetDetailsDrawerOwners';
import { ResourceOwnerCreator } from 'FindingDetails/interfaces/ResourceOwner';
import ResourceAssetDetailsDrawerAssociatedFindings from '../ResourceAssetDetailsDrawerAssociatedFindings';
import { ResourceAssetDetailsDrawerAssociatedFindingsData } from '../ResourceAssetDetailsDrawerAssociatedFindings/ResourceAssetDetailsDrawerAssociatedFindings';
import { FindingSource } from 'FindingDetails/interfaces/FindingsWithResources';
import { DuplicationState } from 'Risk/interfaces/DuplicationState.enum';
import { ResourceAssetDetailsDrawerAdditionalDataPayload } from '../ResourceAssetDetailsDrawerAdditionalData/ResourceAssetDetailsDrawerAdditionalData';
import { useSearchResourceAssetsMutation } from 'Risk/store/api';
import { AssetType } from 'Risk/interfaces/AssetType.enum';
import { useSearchParams } from 'react-router-dom';

interface ResourceAssetDetailsDrawerContentProps extends BaseComponentProps {}

export interface ResourceAssetDetailsDrawerContentSectionProps<T> {
  isLoading: boolean;
  getData: () => T;
  error?: any;
}

export const ResourceAssetDetailsDrawerContent: FunctionComponent<
  ResourceAssetDetailsDrawerContentProps
> = () => {
  const [searchParams] = useSearchParams();

  const [
    searchResourceData,
    {
      data: resourceData,
      isLoading: resourceDataLoading,
      error: resourceDataError,
    },
  ] = useSearchResourceAssetsMutation();

  const openResourceId = useMemo<string | null>(() => {
    return searchParams.get('openResourceId') || null;
  }, [searchParams.get('openResourceId')]);

  useEffect(() => {
    if (openResourceId) {
      searchResourceData({
        type: AssetType.ResourceData,
        resourceExternalId: openResourceId,
      });
    }
  }, [openResourceId]);

  return (
    <div className="resource-asset-details-drawer-content-container">
      <ResourceAssetDetailsDrawerHeader
        resourceData={resourceData}
        resourceDataError={resourceDataError}
        resourceDataLoading={resourceDataLoading}
      />
      <ResourceAssetDetailsDrawerResourceInfo
        resourceData={resourceData}
        resourceDataError={resourceDataError}
        resourceDataLoading={resourceDataLoading}
      />
      <ResourceAssetDetailsDrawerOwners resourceId={resourceData?.id} />
      <ResourceAssetDetailsDrawerAssociatedFindings />
      <ResourceAssetDetailsDrawerAdditionalData />
    </div>
  );
};
