import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface RiskChipProps extends BaseComponentProps {
  riskScore: number;
}

export const RiskChip: FunctionComponent<RiskChipProps> = ({ riskScore }) => {
  const getRiskClassname = (riskScore: number) => {
    if (riskScore < 4) {
      return 'risk-chip-low';
    }

    if (riskScore >= 4 && riskScore < 7) {
      return 'risk-chip-medium';
    }
    if (riskScore >= 7 && riskScore < 9) {
      return 'risk-chip-high';
    }

    return 'risk-chip-critical';
  };

  return (
    <div className={`risk-chip ${getRiskClassname(riskScore)}`}>
      {riskScore}
    </div>
  );
};
