import { AutocompleteOption } from 'FindingDetails/store/api';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';

export enum FilterOperator {
  IN = 'In',
  RANGE = 'Range',
  ANY_TAG = 'anyTag',
}

export enum FilterType {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DATE_RANGE = 'DATE_RANGE',
  NUMBER = 'NUMBER',
  NUMBER_RANGE = 'NUMBER_RANGE',
  KEY_VALUE = 'KEY_VALUE',
}

export interface FilterCategoryBase {
  id: string;
  label: string;
  type?: FilterType;
  categories?: Array<FilterCategoryBase>;
  entitlment?: SystemFeatureFlag;
}

export enum OptionSourceType {
  STATIC = 'STATIC',
  API_STATIC = 'API_STATIC',
  API_DYNAMIC = 'API_DYNAMIC',
}
export interface StaticOptionSourceProperties {
  options: Array<FilterOption>;
}

export interface ApiStaticOptionSourceProperties {
  url?: string;
  method?: string;
  body?: any;
  optionsTransformer?: (optionData: any) => Array<FilterOption>;
}

export interface ApiDynamicOptionSourceProperties
  extends ApiStaticOptionSourceProperties {
  searchProperty?: string;
}

export type OptionSource =
  | StaticOptionSourceProperties
  | ApiStaticOptionSourceProperties
  | ApiDynamicOptionSourceProperties;

export interface SingleSelectState {
  sourceType?: OptionSourceType;
  source?: OptionSource;
  extraOptions?: Array<FilterOption>;
  selectedOptions?: Array<FilterOption>;
  sortMethod?: (optionData: Array<FilterOption>) => Array<FilterOption>;
}

export interface MultiSelectState extends SingleSelectState {
  allSelected?: boolean;
}

export interface DateRangeState {
  startDate: Date;
  endDate: Date;
}

export interface NumberRangeState {
  startValue?: number;
  endValue?: number;
  minValue: number;
  maxValue: number;
  step?: number;
}

export interface NumberState {
  value: number;
}

export interface KeyValueSelection {
  key: AutocompleteOption;
  values?: Array<AutocompleteOption>;
}

export interface KeyValueSource {
  type: OptionSourceType;
  payload: OptionSource;
}

export interface KeyValueState {
  selections?: Array<KeyValueSelection>;
  keySource: KeyValueSource;
  valueSource: KeyValueSource;
}

export type CategoryState =
  | SingleSelectState
  | MultiSelectState
  | DateRangeState
  | NumberRangeState
  | NumberState
  | KeyValueState;

export interface FilterCategory extends FilterCategoryBase {
  state?: CategoryState;
}

export interface ExtendedFilterCategory extends FilterCategory {
  categories?: Array<ExtendedFilterCategory>;
}

export interface FilterOption {
  value: any;
  label?: string;
}
