import { Button, CircularProgress } from '@mui/material';
import { OrderState, OrderType } from 'Common/utils/sort';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import {
  GridType,
  RiskFilterItem,
  getGridColumnsByGridType,
  getGridFilterSelections,
  getRiskSearchParams,
  getRootCauseGroupTableSelectionProps,
  setGridColumns,
  setRootCauseGroupTableSelection,
  getGridDuplicationState,
  getFilterState,
} from 'Risk/store';
import { useSearchRootCauseGroupsMutation } from 'Risk/store/api';
import {
  ColumnMovedEvent,
  ColumnVisibleEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { CommonSimpleDataGrid } from 'shared/components/CommonSimpleDataGrid/CommonSimpleDataGrid';
import { NoDataBackdrop } from 'shared/components/NoDataBackdrop/NoDataBackdrop';
import {
  defaultRootCauseGridHiddenColumns,
  getAllColumnDefs,
  getRiskSearchParamsRequestDependencies,
  riskViewFilterCategories,
  excludedRiskFilters,
  vulnerabilityRiskFilters,
} from 'shared/fixtures/data/risk-grid.data';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { KeyValueState } from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

const advanceFilterDataHandler = new AdvanceFilterHandler();
const riskSearchParamsHandler = new FilterSearchParamsHandler();

interface RootCauseGroupGridProps extends BaseComponentProps {
  clearFilters: () => void;
  onRowClicked?: (event: RowClickedEvent) => void;
  gridRef: any;
}

export const RootCauseGroupGrid: FunctionComponent<RootCauseGroupGridProps> = ({
  clearFilters,
  onRowClicked,
  gridRef,
}) => {
  const [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const isDuplicatedStateEnabled = useCommonSelector(getGridDuplicationState);
  const dispatch = useCommonDispatch();

  const rootCauseGridColumns = useCommonSelector(
    getGridColumnsByGridType(GridType.RootCause)
  );

  const filterState = useCommonSelector(getFilterState);

  const advanceFilterSelections = useCommonSelector(getGridFilterSelections);

  const additionalSearchParams = useMemo(() => {
    const updatedFilterState = Object.keys(filterState).reduce(
      (accumulator, filterStateKey: string) => {
        if (!excludedRiskFilters.includes(filterStateKey)) {
          accumulator[filterStateKey] = filterState[filterStateKey];
        }
        return accumulator;
      },
      {} as any
    );

    return advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
      updatedFilterState,
      riskViewFilterCategories
    );
  }, [filterState]);

  const vulnerabilitySearchParams = useMemo(() => {
    const updatedFilterState = Object.keys(filterState).reduce(
      (accumulator, filterStateKey: string) => {
        if (vulnerabilityRiskFilters.includes(filterStateKey)) {
          accumulator[filterStateKey] = filterState[filterStateKey];
        }
        return accumulator;
      },
      {} as any
    );

    return advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
      updatedFilterState,
      riskViewFilterCategories
    );
  }, [filterState]);

  const tagsSearchParams = useMemo(() => {
    if (filterState.tags) {
      const tagsState = filterState.tags as KeyValueState;

      const tagsFilterPayload =
        advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
          {
            tags: {
              ...tagsState,
              selections: tagsState.selections?.filter(
                (selection) => selection.key.value.length > 0
              ),
            },
          },
          riskViewFilterCategories
        );

      return tagsFilterPayload.tags;
    }

    return {};
  }, [filterState]);

  const searchParams = useCommonSelector(getRiskSearchParams);
  const rootCauseGroupGridSelectionProps = useCommonSelector(
    getRootCauseGroupTableSelectionProps
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);

  const resetPageNumber = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    resetPageNumber();
  }, [additionalSearchParams, searchParams?.filter?.title]);

  const orderParams = useMemo<OrderState | null>(() => {
    try {
      if (urlSearchParams.order) {
        const parsedOrderParams = JSON.parse(
          urlSearchParams.order as string
        ) as OrderState;

        return parsedOrderParams;
      }

      return null;
    } catch (err) {
      return null;
    }
  }, [urlSearchParams.order]);

  const [
    searchRootCauseGroups,
    { data: rootCauseGroupsPayload, isLoading: isLoading },
  ] = useSearchRootCauseGroupsMutation();

  const titleValue = useMemo<string>(() => {
    const titleRiskFilterItem = searchParams?.filter?.title as RiskFilterItem;
    return titleRiskFilterItem?.value;
  }, [searchParams]);

  useEffect(() => {
    searchRootCauseGroups({
      filter: {
        ...additionalSearchParams,
        ...(searchParams?.filter || {}),
      },
      order: orderParams,
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
      removeDuplications: isDuplicatedStateEnabled,
      vulnerabilityFilter: vulnerabilitySearchParams,
      tagFilter: tagsSearchParams,
    });
  }, [
    ...getRiskSearchParamsRequestDependencies(additionalSearchParams),
    ...getRiskSearchParamsRequestDependencies(vulnerabilitySearchParams),
    ...getRiskSearchParamsRequestDependencies({
      tags: tagsSearchParams,
    }),
    currentPage,
    pageSize,
    orderParams,
    titleValue,
    isDuplicatedStateEnabled,
  ]);

  const onPageChange = (value: number) => {
    setCurrentPage(value);
  };

  const onPageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    const existingParams = riskSearchParamsHandler.setSearchParamsForKeys(
      urlSearchParams,
      ['activeTabId', 'filter', 'searchKeyword']
    );
    if (columnOrderPayload.length) {
      setUrlSearchParams({
        ...existingParams,
        order: JSON.stringify({
          field: columnOrderPayload[0].field,
          type: columnOrderPayload[0].type,
        }),
      });
    } else {
      delete existingParams.order;

      setUrlSearchParams({
        ...existingParams,
      });
    }
  };

  const onHandleSelectionChange = (selections: Array<string>) => {
    const updatedSelections: Array<string> = selections.map(
      (selection: any) => selection.split(',')[0]
    );

    dispatch(
      setRootCauseGroupTableSelection({
        ...rootCauseGroupGridSelectionProps,
        selectedChildren: selections,
        totalSelectedChildren: updatedSelections.length,
      })
    );
  };

  return (
    <CommonSimpleDataGrid
      isLoading={isLoading}
      columnDefs={rootCauseGridColumns}
      rowData={
        rootCauseGroupsPayload?.data?.map((dataItem: any) => ({
          ...dataItem,
        })) || null
      }
      onColumnMoved={(event: ColumnMovedEvent) => {
        if (event.finished) {
          const updatedColumnDefs = event.api.getColumnDefs();

          dispatch(
            setGridColumns({
              gridType: GridType.RootCause,
              columns: getAllColumnDefs(
                rootCauseGridColumns,
                updatedColumnDefs || []
              ),
            })
          );
        }
      }}
      onColumnVisible={(event: ColumnVisibleEvent) => {
        const updatedColumnDefs = event.api.getColumnDefs();

        dispatch(
          setGridColumns({
            gridType: GridType.RootCause,
            columns: getAllColumnDefs(
              rootCauseGridColumns,
              updatedColumnDefs || []
            ),
          })
        );
      }}
      containerClassName="root-cause-grid-container"
      rowSelection="multiple"
      defaultColDef={{
        resizable: true,
        sortable: true,
      }}
      suppressRowClickSelection
      gridRef={gridRef}
      visibilityControlProps={{
        enableVisibilityControls: true,
        columns: defaultRootCauseGridHiddenColumns,
      }}
      selectionModel={
        rootCauseGroupGridSelectionProps.selectedChildren as Array<string>
      }
      onRowClicked={(event: RowClickedEvent) => {
        onRowClicked && onRowClicked(event);
      }}
      paginationProps={{
        pageSize,
        currentPage,
        totalItems:
          (rootCauseGroupsPayload && rootCauseGroupsPayload?.totalItems) || 0,
        onPageChange,
        onPageSizeChange,
      }}
      onSort={onHandleColumnOrderChange}
      sortModel={orderParams as OrderState}
      onSelect={onHandleSelectionChange}
      keepCurrentSelections
      otherComponents={{
        NoDataBackdropComponent: (
          <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
        ),
      }}
      getRowId={(params) => {
        return params.data.findingIds.join(',');
      }}
      loadingOverlayComponent={() => <CircularProgress />}
    />
  );
};
