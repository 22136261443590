import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import {
  useCreateRiskCustomuzationMutation,
  useFetchRiskCustomizationItemQuery,
  useUpdateRiskCustomuzationMutation,
} from 'Settings/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { ContentSection } from 'shared/components/ContentSection/ContentSection';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { InputLabelWrapper } from 'shared/components/InputLabelWrapper/InputLabelWrapper';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import FilterChipGroup from 'shared/components/DataFilters/FilterChipGroup';
import {
  CategoryState,
  MultiSelectState,
} from 'shared/models/data/data-filter.model';
import {
  riskCustomizationConditionOptions,
  riskCustomizationFormRequiredFields,
  riskCustomizationSchema,
} from 'shared/fixtures/data/risk-customization-data';
import {
  RiskCustomizationDataHandler,
  RiskCustomizationDefaultFormData,
} from 'shared/handlers/risk-customization-data.handler';
import {
  Factors,
  RiskCustomizationConfig,
  RiskCustomizationDto,
  RiskScoreValuesModel,
} from 'Settings/interfaces/RiskCustomizationConfig';
import { RiskCustomizationConfigTypes } from 'Settings/interfaces/RiskCustomizationConfigTypes.enum';
import { RiskConfigItem } from '../RiskConfigItem/RiskConfigItem';
import { useFeatureEntitlements } from '@frontegg/react';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';

interface RiskCustomizationDrawerPanelProps extends BaseComponentProps {
  onCancel?: () => void;
  defaultAttributes?: RiskScoreValuesModel;
}

const riskCustomizationDataHandler = new RiskCustomizationDataHandler();

export const RiskCustomizationDrawerPanel: FunctionComponent<
  RiskCustomizationDrawerPanelProps
> = ({ onCancel, defaultAttributes }) => {
  const [searchParams] = useQueryParams();

  const { isEntitled: isEntitledToIntelligenceFeature } =
    useFeatureEntitlements(SystemFeatureFlag.FINDING_VIEW_INTELLIGENCE_TAB);

  const { data: riskConfigData, isFetching: isLoadingRiskConfigData } =
    useFetchRiskCustomizationItemQuery(searchParams?.ruleId as string, {
      skip: !searchParams?.ruleId,
      refetchOnMountOrArgChange: true,
    });

  const { t: translation } = useTranslation();

  const [additionalFormData, setAdditionalFormData] =
    useState<RiskCustomizationDefaultFormData>({
      properties: {},
      name: '',
      description: '',
      values: defaultAttributes
        ? ({ factors: defaultAttributes?.factors } as RiskCustomizationDto)
        : riskCustomizationDataHandler.getDefaultFactors(),
    });

  const [formCancelationModalOpen, setFormCancelationModalOpen] =
    useState<boolean>(false);

  const defaultFormValues = useMemo(() => {
    if (riskConfigData) {
      return riskCustomizationDataHandler.transformRiskCustomizationDataToFormData(
        riskConfigData
      );
    }

    return undefined;
  }, [riskConfigData]);

  const [
    createRiskCustomization,
    {
      isLoading: createRiskCustomizationLoading,
      isSuccess: createRiskCustomizationSuccess,
    },
  ] = useCreateRiskCustomuzationMutation();

  const [
    updateRiskCustomization,
    {
      isLoading: updateRiskCustomizationLoading,
      isSuccess: updateRiskCustomizationSuccess,
    },
  ] = useUpdateRiskCustomuzationMutation();

  useEffect(() => {
    if (createRiskCustomizationSuccess || updateRiskCustomizationSuccess) {
      onCancel && onCancel();
    }
  }, [createRiskCustomizationSuccess, updateRiskCustomizationSuccess]);

  useEffect(() => {
    if (defaultFormValues) {
      setAdditionalFormData({
        ...defaultFormValues,
      });

      for (const formValueKey in defaultFormValues) {
        if (formValueKey === 'advanceFilterItems') {
          return;
        } else {
          setFormValue(
            formValueKey as any,
            defaultFormValues[
              formValueKey as keyof RiskCustomizationDefaultFormData
            ]
          );
        }
      }
    }
  }, [defaultFormValues]);

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    setError,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(riskCustomizationSchema),
  });

  const validateFormBeforeSubmit = () => {
    const formValues = getValues();

    let isValid = true;

    const isPropertyChecked =
      Object.keys(additionalFormData.properties).length > 0;

    if (!isPropertyChecked && !riskConfigData?.isDefault) {
      setError('properties', { message: 'Properties are required' });
      isValid = false;
    }

    for (const requiredField of riskCustomizationFormRequiredFields) {
      if (formValues[requiredField]?.length === 0) {
        setError(requiredField as any, {
          message: `${requiredField} is required`,
        });
        isValid = false;
      } else {
        clearErrors(requiredField as any);
      }
    }

    return isValid;
  };

  const onChangeRiskValue = (type: keyof Factors, value: number) => {
    setAdditionalFormData((prevAdditionalFormData) => {
      const updatedFactors = {
        ...prevAdditionalFormData?.values?.factors,
        [type]: { weight: value, multiplier: 0.1 },
      };

      return {
        ...prevAdditionalFormData,
        values: {
          factors: updatedFactors,
        },
      };
    });
  };

  const getWeight = (type: any) => {
    return additionalFormData?.values?.factors[type as keyof Factors]?.weight;
  };
  return isLoadingRiskConfigData ? (
    <div className="risk-customization-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="risk-customization-view-panel">
      <CommonModalContainer
        handleClose={() => {
          setFormCancelationModalOpen(false);
        }}
        isOpen={formCancelationModalOpen}
        title={`Cancel Risk Customization ${
          riskConfigData ? 'Update' : 'Creation'
        }`}
      >
        <div className="business-unit-form-modal-body">
          <div className="business-unit-form-modal-description">
            <span>Are you sure you want to cancel this action?</span>
            <span>All data will be lost</span>
          </div>
          <div className="business-unit-form-modal-buttons">
            <Button
              className="base-opus-text-button"
              onClick={() => {
                setFormCancelationModalOpen(false);
              }}
            >
              Stay
            </Button>
            <Button
              className="base-opus-text-button"
              onClick={() => {
                setFormCancelationModalOpen(false);
                onCancel && onCancel();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </CommonModalContainer>
      <div className="risk-customization-view-panel-header">
        <div className="risk-customization-view-panel-header-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />
        </div>
        <div className="risk-customization-view-panel-header-text">
          <div className="risk-customization-view-panel-header-title">
            Risk Customization
          </div>
        </div>
      </div>

      <div className="risk-customization-view-panel-body">
        <form className="risk-customization-view-panel-form">
          <ContentSection
            title=""
            rootClassName="risk-customization-content-section risk-customization-base-content-section"
          >
            <InputLabelWrapper label="Name">
              <input
                type="text"
                className={`text-field-1 ${
                  errors.name ? 'input-validation-error-1' : ''
                }`}
                {...register('name')}
              ></input>
            </InputLabelWrapper>
            <InputLabelWrapper label="Description">
              <input
                type="text"
                className={`text-field-1`}
                {...register('description')}
              ></input>
            </InputLabelWrapper>
            {!riskConfigData?.isDefault && (
              <InputLabelWrapper label="Match Filter">
                <div
                  className={`select-chip-group-container risk-customization-filter-chip-group ${
                    errors.properties ? 'input-validation-error-1' : ''
                  }`}
                >
                  <FilterChipGroup
                    separator="AND"
                    categories={riskCustomizationConditionOptions}
                    categoryState={additionalFormData.properties}
                    onChange={(id: string, state: CategoryState) => {
                      setAdditionalFormData((prevAdditionalFormData) => {
                        const updatedProperties = {
                          ...prevAdditionalFormData.properties,
                        };

                        const typedState = state as MultiSelectState;

                        if (typedState.selectedOptions?.length === 0) {
                          if (updatedProperties[id])
                            delete updatedProperties[id];
                        } else {
                          updatedProperties[id] = state;
                        }

                        return {
                          ...prevAdditionalFormData,
                          properties: updatedProperties,
                        };
                      });
                    }}
                    Components={{
                      AddButtonContent: (
                        <>
                          <span>Add</span>
                          <OpusSvgIcon
                            type={SVG_ICON_TYPES.SORT_DESCENDING_ICON}
                          />
                        </>
                      ),
                    }}
                    addButtonAlignment="right"
                    onRemoveChip={(categoryId: string) => {
                      setAdditionalFormData((prevAdditionalFormData) => {
                        const updatedProperties = {
                          ...prevAdditionalFormData.properties,
                        };

                        if (updatedProperties[categoryId])
                          delete updatedProperties[categoryId];

                        return {
                          ...prevAdditionalFormData,
                          properties: updatedProperties,
                        };
                      });
                    }}
                  />
                </div>
              </InputLabelWrapper>
            )}

            <div className="risk-customization-list-container">
              <div className="risk-customization-header">
                <OpusSvgIcon type={SVG_ICON_TYPES.TAGS_ICON} />
                <p className="label-2">Risk Customization</p>
                <CommonTooltip
                  title={
                    <p className="opus-body-text-1 tooltip-content">
                      {translation(
                        'settings.details.riskConfigDefaultValuesMessage'
                      )}
                    </p>
                  }
                >
                  <div className="information-badge">
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </div>
                </CommonTooltip>
              </div>
              {RiskCustomizationConfig.filter((itemConfig) => {
                if (
                  itemConfig.type ===
                    RiskCustomizationConfigTypes.vulnerabilityIntelligence &&
                  !isEntitledToIntelligenceFeature
                )
                  return false;

                return true;
              }).map((element) => (
                <RiskConfigItem
                  {...element}
                  key={element.type}
                  weight={getWeight(element.type)}
                  onChange={(newValue: number) =>
                    onChangeRiskValue(element.type, newValue)
                  }
                />
              ))}
            </div>
          </ContentSection>
        </form>
      </div>

      <div className="risk-customization-view-panel-footer">
        <div className="risk-customization-view-panel-footer-validation-error">
          {Object.keys(errors)?.length ? (
            <div className="risk-customization-validation-error-text">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />
              <span>
                {translation(
                  `settings.details.notificationRuleFormRequiredErrorMessage`
                )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="risk-customization-view-panel-footer-buttons">
          <Button
            className="opus-secondary-button"
            onClick={() => {
              setFormCancelationModalOpen(true);
            }}
          >
            Cancel
          </Button>
          <Button
            className="opus-primary-button"
            onClick={() => {
              const isValid = validateFormBeforeSubmit();

              if (isValid) {
                const formValues = getValues();
                const transformedData =
                  riskCustomizationDataHandler.transformRiskCustomizationFormDataToPostData(
                    formValues as any,
                    additionalFormData,
                    riskCustomizationConditionOptions
                  );
                if (searchParams?.ruleId) {
                  updateRiskCustomization({
                    id: riskConfigData.id,
                    ...transformedData,
                  });
                } else {
                  createRiskCustomization(transformedData);
                }
              }
            }}
          >
            {createRiskCustomizationLoading || updateRiskCustomizationLoading
              ? 'Saving'
              : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};
