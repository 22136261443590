import { useFetchUsersMutation } from 'Auth/store/api';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { useEffect, useMemo } from 'react';
import { reportRuleFilterAllOption } from 'shared/fixtures/data/report-rule.data';
import { OrganizationNodeDataHandler } from 'shared/handlers/organization-node-data.handler';
import { UserDataHandler } from 'shared/handlers/user-data.handler';

type NotificationSettingsOptionList = {
  emailOptionList: Array<AutocompleteOption>;
  scopesOptionList: Array<AutocompleteOption>;
  groupsOptionList: Array<AutocompleteOption>;
  getGroupsOptionList: (scopeId: string) => void;
};

const userDataHandler = new UserDataHandler();

const organizationNodeDataHandler = new OrganizationNodeDataHandler();

export function useFetchReportRulesOptionItems(): NotificationSettingsOptionList {
  const [fetchUsers, { data: userList }] = useFetchUsersMutation();

  const [fetchScopeList, { data: scopeList, isLoading: scopeListLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [fetchGroupList, { data: groupList, isLoading: groupListLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  useEffect(() => {
    fetchUsers();

    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
    fetchGroupList({
      nodesType: OrganizationNodeType.LOGICAL,
    });
  }, []);

  const userEmailList = useMemo(() => {
    if (userList) {
      return userDataHandler.transformUserListToAutocompleteEmailList(userList);
    }

    return [];
  }, [userList]);

  const scopesOptionList = useMemo(() => {
    if (scopeList) {
      const options =
        organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeList
        );

      return [reportRuleFilterAllOption, ...options];
    }

    return [reportRuleFilterAllOption];
  }, [scopeList]);

  const groupsOptionList = useMemo(() => {
    if (groupList) {
      const options =
        organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          groupList
        );

      return [reportRuleFilterAllOption, ...options];
    }

    return [reportRuleFilterAllOption];
  }, [groupList]);

  const getGroupsOptionList = (scopeId: string) => {
    fetchGroupList({
      nodesType: OrganizationNodeType.LOGICAL,
      startNodeId: scopeId,
    });
  };

  return {
    emailOptionList: userEmailList,
    scopesOptionList: scopesOptionList,
    groupsOptionList: groupsOptionList,
    getGroupsOptionList: getGroupsOptionList,
  };
}
