import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  CampaignCreateFormBusinessPriority,
  CampaignCreateFormDateRange,
} from 'shared/enums/campaigns.enum';
import { CampaignCreateFormErrors } from 'shared/models/data/campaigns.model';

export const campaignCreateFormBusinessPriorityOptions: Array<AutocompleteOption> =
  [
    {
      value: CampaignCreateFormBusinessPriority.LOW,
      label: CampaignCreateFormBusinessPriority.LOW,
    },
    {
      value: CampaignCreateFormBusinessPriority.MODERATE,
      label: CampaignCreateFormBusinessPriority.MODERATE,
    },
    {
      value: CampaignCreateFormBusinessPriority.HIGH,
      label: CampaignCreateFormBusinessPriority.HIGH,
    },
    {
      value: CampaignCreateFormBusinessPriority.URGENT,
      label: CampaignCreateFormBusinessPriority.URGENT,
    },
  ];

export const campaignCreateFormDateRangeOptions: Array<AutocompleteOption> = [
  {
    value: CampaignCreateFormDateRange.ONE_WEEK,
    label: CampaignCreateFormDateRange.ONE_WEEK,
  },
  {
    value: CampaignCreateFormDateRange.TWO_WEEKS,
    label: CampaignCreateFormDateRange.TWO_WEEKS,
  },
  {
    value: CampaignCreateFormDateRange.MONTH,
    label: CampaignCreateFormDateRange.MONTH,
  },
  {
    value: CampaignCreateFormDateRange.QUARTER,
    label: CampaignCreateFormDateRange.QUARTER,
  },
  {
    value: CampaignCreateFormDateRange.CUSTOM,
    label: CampaignCreateFormDateRange.CUSTOM,
  },
];

export const getInitialCampaignFormValidationErrors = (
  translation: any
): CampaignCreateFormErrors => ({
  name: translation(`campaigns.modal.validationErrors.name`),
  startTime: translation(`campaigns.modal.validationErrors.startTime`),
  endDate: translation(`campaigns.modal.validationErrors.endDate`),
  assignees: translation(`campaigns.modal.validationErrors.assignees`),
  businessPriority: translation(
    `campaigns.modal.validationErrors.businessPriority`
  ),
  dateRange: translation(`campaigns.modal.validationErrors.dateRange`),
});
