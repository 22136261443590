import { ForbiddenPage } from 'Common/components/ForbiddenPage/ForbiddenPage';
import { FunctionComponent, useMemo } from 'react';
import { RoutePermissionModel } from 'shared/models/data/route.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { PermissionContext } from '../PermissionContext/PermissionContext';

interface PermissionGuardProps
  extends BaseComponentProps,
    RoutePermissionModel {}

export const PermissionGuard: FunctionComponent<PermissionGuardProps> = ({
  name,
  permissions,
  userPermissions,
  children,
}) => {
  const userHasPermission = useMemo<boolean>(() => {
    if (permissions?.length === 0) {
      return true;
    }

    return Boolean(
      userPermissions?.some((userPermission) =>
        permissions?.includes(userPermission)
      )
    );
  }, [permissions, userPermissions]);

  return userHasPermission ? (
    <PermissionContext.Provider value={{ permissions: userPermissions }}>
      {children}
    </PermissionContext.Provider>
  ) : (
    <ForbiddenPage />
  );
};
