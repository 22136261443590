import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Stack } from '@mui/material';
import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  useCreateReportRuleMutation,
  useFetchReportRuleQuery,
  useUpdateReportRuleMutation,
} from 'Settings/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { ContentSection } from 'shared/components/ContentSection/ContentSection';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { InputLabelWrapper } from 'shared/components/InputLabelWrapper/InputLabelWrapper';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import Autocomplete from 'Common/components/Autocomplete';
import {
  reportRuleDashboardTypeOptions,
  reportRuleDefaultValues,
  reportRuleFrequencyOptions,
  reportRuleInitialSchema,
  reportRuleRecipientsOptions,
  reportRuleRiskWidgetOptions,
  reportRuleWidgetOptions,
} from 'shared/fixtures/data/report-rule.data';
import { useFetchReportRulesOptionItems } from 'shared/hooks/useFetchReportRuleOptionItems';
import { ReportRuleDataHandler } from 'shared/handlers/report-rule-data.handler';
import {
  ReportRuleContentGroupType,
  ReportRuleFormData,
  ReportRuleRecipientType,
} from 'shared/models/data/report-rule.model';
import * as yup from 'yup';
import {
  operationalDashboardFilterOptions,
  operationalDashboardTrendMetricsTypeOptions,
} from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { OptionDataHandler } from 'shared/handlers/option-data.handler';

interface ReportRuleDrawerPanelProps extends BaseComponentProps {
  onCancel?: () => void;
  cancelationModalProps?: {
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
  };
}

const reportRuleDataHandler = new ReportRuleDataHandler();

const optionDataHandler = new OptionDataHandler();

export const ReportRuleDrawerPanel: FunctionComponent<
  ReportRuleDrawerPanelProps
> = ({ onCancel, cancelationModalProps }) => {
  const [reportRuleSchema, setReportRuleSchema] = useState(
    reportRuleInitialSchema,
  );

  const [searchParams] = useQueryParams();

  const {
    emailOptionList,
    scopesOptionList,
    groupsOptionList,
    getGroupsOptionList,
  } = useFetchReportRulesOptionItems();

  const { t: translation } = useTranslation();

  const { data: reportRule, isFetching: reportRuleLoading } =
    useFetchReportRuleQuery(searchParams?.ruleId as string, {
      skip: !searchParams?.ruleId,
      refetchOnMountOrArgChange: true,
    });

  const defaultFormValues = useMemo(() => {
    if (reportRuleLoading) return undefined;

    if (reportRule) {
      return reportRuleDataHandler.transformReportRuleToFormData(reportRule);
    }

    return undefined;
  }, [reportRule, reportRuleLoading]);

  const [
    createReportRule,
    { isLoading: createReportRuleLoading, isSuccess: createReportRuleSuccess },
  ] = useCreateReportRuleMutation();

  const [
    updateReportRule,
    { isLoading: updateReportRuleLoading, isSuccess: updateReportRuleSuccess },
  ] = useUpdateReportRuleMutation();

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    getValues: getFormValues,
    reset: resetForm,
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object().shape(reportRuleSchema)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (defaultFormValues) {
      if (
        defaultFormValues['recipients'].value ===
        ReportRuleRecipientType.GROUP_OWNERS
      ) {
        setSchemaForDynamicRule();
      } else {
        setSchemaForStaticRule();
      }

      for (const formValueKey in defaultFormValues) {
        setFormValue(
          formValueKey as any,
          defaultFormValues[formValueKey as keyof ReportRuleFormData],
        );
      }
    } else {
      for (const formValueKey in reportRuleDefaultValues) {
        setFormValue(
          formValueKey as any,
          reportRuleDefaultValues[formValueKey as keyof ReportRuleFormData],
        );
      }
    }
  }, [defaultFormValues]);

  const onDrawerClose = () => {
    resetForm();
    onCancel && onCancel();
  };

  useEffect(() => {
    if (createReportRuleSuccess || updateReportRuleSuccess) {
      onDrawerClose();
    }
  }, [createReportRuleSuccess, updateReportRuleSuccess]);

  const recipientsValue = watch('recipients');

  const groupsValue = watch('groups');

  const scopeValue = watch('scopes');

  const contentType = watch('contentType');

  const reportRuleAllWidgetOptions = useMemo<Array<AutocompleteOption>>(() => {
    if (!contentType) {
      return [...reportRuleWidgetOptions, ...reportRuleRiskWidgetOptions];
    }

    if (contentType?.value === ReportRuleContentGroupType.RISK)
      return reportRuleRiskWidgetOptions;

    return reportRuleWidgetOptions;
  }, [contentType]);

  const setSchemaForStaticRule = () => {
    const schema = {
      ...reportRuleInitialSchema,
      groups: yup.object().required(),
    };

    setReportRuleSchema(() => ({ ...schema }));
  };

  const setSchemaForDynamicRule = () => {
    const schema = {
      ...reportRuleInitialSchema,
      groups: yup.array().required().min(1),
    };

    delete schema.emails;

    setReportRuleSchema(() => ({ ...schema }));
  };

  const onAutocompleteOptionChange = (
    model: any,
    autocompletePayload: AutocompleteOption | Array<AutocompleteOption>,
  ) => {
    setFormValue(model, autocompletePayload);
  };

  const onRecipientsOptionChange = (
    model: any,
    autocompletePayload: AutocompleteOption | Array<AutocompleteOption>,
  ) => {
    const selectedOption = autocompletePayload as AutocompleteOption;

    if (selectedOption.value === ReportRuleRecipientType.GROUP_OWNERS) {
      setSchemaForDynamicRule();
      setFormValue('groups', []);
    } else {
      setSchemaForStaticRule();
      setFormValue('groups', null);
    }

    onAutocompleteOptionChange(model, autocompletePayload);
  };

  const onScopeOptionChange = (
    model: string,
    autocompletePayload: AutocompleteOption | Array<AutocompleteOption>,
  ) => {
    const singleOption = autocompletePayload as AutocompleteOption;

    getGroupsOptionList(singleOption.value);

    onAutocompleteOptionChange(model, autocompletePayload);
  };

  const submitForm = () => {
    handleSubmit((values) => {
      const reportRulePostData =
        reportRuleDataHandler.transformReportRuleFormDataToPostData(
          values as ReportRuleFormData,
        );

      if (reportRule) {
        updateReportRule({ id: reportRule.id, ...reportRulePostData });
      } else {
        createReportRule(reportRulePostData);
      }
    })();
  };

  const renderEmailField = () => {
    if (recipientsValue?.value === ReportRuleRecipientType.EMAIL) {
      return (
        <InputLabelWrapper
          label={translation(`settings.forms.labels.reportRules.sendAnEmail`)}
        >
          <Autocomplete
            model="emails"
            onChangeCallBack={onAutocompleteOptionChange}
            optionList={optionDataHandler.sortOptionList(
              emailOptionList,
              'desc',
              'label',
            )}
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.emails ? 'input-validation-error-1' : '',
            }}
            enableCheckbox
            freeSolo
            initialSelectedValues={defaultFormValues?.emails}
          />
        </InputLabelWrapper>
      );
    }

    return <></>;
  };

  const renderScopeFieldInput = () => {
    if (scopeValue) {
      return (
        <Autocomplete
          single
          optionList={optionDataHandler.sortOptionList(
            scopesOptionList,
            'desc',
            'label',
          )}
          model="scopes"
          onChangeCallBack={onScopeOptionChange}
          classes={{
            root: 'multi-select-field-1 ',
            paper: 'multi-select-field-paper-1',
            inputRoot: errors.scopes ? 'input-validation-error-1' : '',
          }}
          enableCheckbox
          values={scopeValue}
          initialSelectedValues={scopeValue}
          areOptionsLoaded
        />
      );
    }

    return <></>;
  };

  const renderGroupFieldInput = () => {
    let options: Array<AutocompleteOption> = [];

    if (scopeValue) {
      if (recipientsValue?.value === ReportRuleRecipientType.EMAIL) {
        options = [...groupsOptionList];
      } else {
        options = groupsOptionList.filter((option) => option.value !== 'All');
      }
    }

    if (groupsValue !== undefined) {
      return (
        <Autocomplete
          single={recipientsValue?.value === ReportRuleRecipientType.EMAIL}
          optionList={optionDataHandler.sortOptionList(
            options,
            'desc',
            'label',
          )}
          model="groups"
          onChangeCallBack={onAutocompleteOptionChange}
          classes={{
            root: 'multi-select-field-1 ',
            paper: 'multi-select-field-paper-1',
            inputRoot: errors.groups ? 'input-validation-error-1' : '',
          }}
          enableCheckbox
          values={groupsValue}
          initialSelectedValues={groupsValue}
          areOptionsLoaded
        />
      );
    }

    return <></>;
  };

  const renderRiskReportAdditionalConfiguration = () => {
    if (contentType?.value === ReportRuleContentGroupType.RISK) {
      return (
        <Stack flexDirection="row" gap={3}>
          <InputLabelWrapper
            label={translation(`settings.forms.labels.reportRules.timeFrame`)}
          >
            <Autocomplete
              single
              optionList={optionDataHandler.sortOptionList(
                operationalDashboardFilterOptions,
                'desc',
                'label',
              )}
              model="timeFrame"
              onChangeCallBack={onAutocompleteOptionChange}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.contentType ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              initialSelectedValues={defaultFormValues?.timeFrame}
              areOptionsLoaded
            />
          </InputLabelWrapper>

          <InputLabelWrapper
            label={translation(
              `settings.forms.labels.reportRules.trendMetricsTypes`,
            )}
          >
            <Autocomplete
              optionList={optionDataHandler.sortOptionList(
                operationalDashboardTrendMetricsTypeOptions,
                'desc',
                'label',
              )}
              model="trendMetricsTypes"
              onChangeCallBack={onAutocompleteOptionChange}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.recipients ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              initialSelectedValues={defaultFormValues?.trendMetricsTypes}
              areOptionsLoaded
            />
          </InputLabelWrapper>
        </Stack>
      );
    }

    return <></>;
  };

  return reportRuleLoading ? (
    <div className="notification-rule-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="notification-rule-view-panel">
      <CommonModalContainer
        handleClose={() => {
          cancelationModalProps?.onClose && cancelationModalProps?.onClose();
        }}
        isOpen={Boolean(cancelationModalProps?.open)}
        title={`Cancel Report Rule ${reportRule ? 'Update' : 'Creation'}`}
      >
        <div className="business-unit-form-modal-body">
          <div className="business-unit-form-modal-description">
            <span>Are you sure you want to cancel this action?</span>
            <span>All data will be lost</span>
          </div>
          <div className="business-unit-form-modal-buttons">
            <Button
              className="base-opus-text-button"
              onClick={() => {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
              }}
            >
              Stay
            </Button>
            <Button
              className="base-opus-text-button"
              onClick={() => {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
                onDrawerClose();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </CommonModalContainer>
      <div className="notification-rule-view-panel-header">
        <div className="notification-rule-view-panel-header-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />
        </div>
        <div className="notification-rule-view-panel-header-text">
          <div className="notification-rule-view-panel-header-title">
            {translation(`settings.details.reportRuleDrawerTitle`)}{' '}
            {reportRule ? 'Update' : 'Creation'}
          </div>
          <div className="notification-rule-view-panel-header-description">
            {translation(`settings.details.reportRuleDrawerDescription`)}
          </div>
        </div>
      </div>

      <div className="notification-rule-view-panel-body">
        <form className="notification-rule-view-panel-form">
          <ContentSection
            title=""
            rootClassName="notification-rule-content-section notification-rule-base-content-section"
          >
            <InputLabelWrapper
              label={translation(`settings.forms.labels.reportRules.name`)}
            >
              <input
                type="text"
                className={`text-field-1 ${
                  errors.name ? 'input-validation-error-1' : ''
                }`}
                {...register('name')}
              ></input>
            </InputLabelWrapper>

            <InputLabelWrapper
              label={translation(
                `settings.forms.labels.reportRules.description`,
              )}
            >
              <input
                type="text"
                className={`text-field-1`}
                {...register('description')}
              ></input>
            </InputLabelWrapper>
            <Stack flexDirection="row" gap={3}>
              <InputLabelWrapper
                label={translation(`settings.forms.labels.reportRules.type`)}
              >
                <Autocomplete
                  single
                  optionList={optionDataHandler.sortOptionList(
                    reportRuleDashboardTypeOptions,
                    'desc',
                    'label',
                  )}
                  model="contentType"
                  onChangeCallBack={(
                    model: string,
                    options: AutocompleteOption | Array<AutocompleteOption>,
                  ) => {
                    const selectedOption = options as AutocompleteOption;

                    if (
                      selectedOption?.value !== ReportRuleContentGroupType.RISK
                    ) {
                      setFormValue('timeFrame', undefined);
                      setFormValue('trendMetricsTypes', undefined);
                    }

                    setFormValue('widgets', undefined);
                    onAutocompleteOptionChange(model, options);
                  }}
                  classes={{
                    root: 'multi-select-field-1 ',
                    paper: 'multi-select-field-paper-1',
                    inputRoot: errors.contentType
                      ? 'input-validation-error-1'
                      : '',
                  }}
                  enableCheckbox
                  initialSelectedValues={defaultFormValues?.contentType}
                />
              </InputLabelWrapper>

              <InputLabelWrapper
                label={translation(
                  `settings.forms.labels.reportRules.recipients`,
                )}
              >
                <Autocomplete
                  single
                  optionList={optionDataHandler.sortOptionList(
                    reportRuleRecipientsOptions,
                    'desc',
                    'label',
                  )}
                  model="recipients"
                  onChangeCallBack={onRecipientsOptionChange}
                  classes={{
                    root: 'multi-select-field-1 ',
                    paper: 'multi-select-field-paper-1',
                    inputRoot: errors.recipients
                      ? 'input-validation-error-1'
                      : '',
                  }}
                  enableCheckbox
                  values={recipientsValue}
                  initialSelectedValues={recipientsValue}
                  areOptionsLoaded
                />
              </InputLabelWrapper>
            </Stack>

            <Stack flexDirection="row" gap={3}>
              <InputLabelWrapper
                label={translation(`settings.forms.labels.reportRules.scopes`)}
              >
                {renderScopeFieldInput()}
              </InputLabelWrapper>
              <InputLabelWrapper
                label={translation(`settings.forms.labels.reportRules.groups`)}
              >
                {renderGroupFieldInput()}
              </InputLabelWrapper>
            </Stack>
            {renderRiskReportAdditionalConfiguration()}

            <InputLabelWrapper
              label={translation(`settings.forms.labels.reportRules.widgets`)}
            >
              <Autocomplete
                optionList={optionDataHandler.sortOptionList(
                  reportRuleAllWidgetOptions,
                  'desc',
                  'label',
                )}
                model="widgets"
                onChangeCallBack={onAutocompleteOptionChange}
                classes={{
                  root: 'multi-select-field-1 ',
                  paper: 'multi-select-field-paper-1',
                  inputRoot: errors.widgets ? 'input-validation-error-1' : '',
                }}
                enableCheckbox
                initialSelectedValues={defaultFormValues?.widgets}
              />
            </InputLabelWrapper>

            <InputLabelWrapper
              label={translation(`settings.forms.labels.reportRules.when`)}
            >
              <Autocomplete
                model="frequencyType"
                onChangeCallBack={onAutocompleteOptionChange}
                single
                optionList={optionDataHandler.sortOptionList(
                  reportRuleFrequencyOptions,
                  'desc',
                  'label',
                )}
                classes={{
                  root: 'multi-select-field-1 ',
                  paper: 'multi-select-field-paper-1',
                  inputRoot: errors.frequencyType
                    ? 'input-validation-error-1'
                    : '',
                }}
                enableCheckbox
                initialSelectedValues={defaultFormValues?.frequencyType}
              />
            </InputLabelWrapper>
            {renderEmailField()}
          </ContentSection>
        </form>
      </div>

      <div className="notification-rule-view-panel-footer">
        <div className="notification-rule-view-panel-footer-validation-error">
          {Object.keys(errors)?.length ? (
            <div className="notification-rule-validation-error-text">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />
              <span>
                {translation(
                  `settings.details.notificationRuleFormRequiredErrorMessage`,
                )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="notification-rule-view-panel-footer-buttons">
          <Button
            className="opus-secondary-button"
            onClick={() => {
              cancelationModalProps?.onOpen && cancelationModalProps?.onOpen();
            }}
          >
            Cancel
          </Button>
          <Button className="opus-primary-button" onClick={submitForm}>
            {createReportRuleLoading || updateReportRuleLoading
              ? 'Saving'
              : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};
