import {
  CampaignState,
  CampaignStatus,
} from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { FilterItem } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { TypeFilter } from 'shared/enums/campaigns.enum';

export const campaignsFilterItems: Array<FilterItem> = [
  {
    id: 'state',
    name: 'State',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: CampaignState.ACTIVE,
        name: CampaignState.ACTIVE,
      },
      {
        id: CampaignState.NON_ACTIVE,
        name: CampaignState.NON_ACTIVE,
      },
    ],
    loaded: true,
  },
  {
    id: 'status',
    name: 'Status',
    type: TypeFilter.MULTI_SELECT,
    items: [
      {
        id: CampaignStatus.NEW,
        name: CampaignStatus.NEW,
      },
      {
        id: CampaignStatus.ACTIVE,
        name: CampaignStatus.ACTIVE,
      },
      {
        id: CampaignStatus.FINISHED,
        name: CampaignStatus.FINISHED,
      },
      {
        id: CampaignStatus.ARCHIVED,
        name: CampaignStatus.ARCHIVED,
      },
    ],
    loaded: true,
  },
  {
    id: 'businessPriority',
    name: 'Service Severity',
    type: TypeFilter.MULTI_SELECT,
    items: [
      {
        id: 'Low',
        name: 'Low',
      },
      {
        id: 'Moderate',
        name: 'Medium',
      },
      {
        id: 'High',
        name: 'High',
      },
      {
        id: 'Urgent',
        name: 'Urgent',
      },
    ],
    loaded: true,
  },
  {
    id: 'startTime',
    name: 'Time Frame',
    type: TypeFilter.DATE_PICKER_RANGE,
    items: [],
  },
  {
    id: 'assignees',
    name: 'Assigned Person',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'days',
    name: 'Remaining Days',
    type: TypeFilter.NUMBER,
    loaded: true,
    items: [],
  },
];
