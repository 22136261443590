import Autocomplete from 'Common/components/Autocomplete';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, useMemo, useState } from 'react';
import {
  ApiStaticOptionSourceProperties,
  KeyValueSelection,
  KeyValueSource,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useKeyValueOptions } from '../useKeyValueOptions';
import { Button } from '@mui/material';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { debounce } from 'lodash';

export interface KeyValueSelectionRowProps extends BaseComponentProps {
  selection: KeyValueSelection;
  keySource: KeyValueSource;
  valueSource: KeyValueSource;
  onChange: (selection: KeyValueSelection) => void;
  onRemove: () => void;
  disableRemoveButton?: boolean;
}

export const KeyValueSelectionRow: FunctionComponent<
  KeyValueSelectionRowProps
> = ({
  selection,
  keySource,
  valueSource,
  onChange,
  onRemove,
  disableRemoveButton = false,
}) => {
  const [valueSearchKeyword, setValueSearchKeyword] = useState<string>('');

  const [keySearchKeyword, setKeySearchKeyword] = useState<string>('');

  const updatedKeySource = useMemo<KeyValueSource>(() => {
    if (keySource.type === OptionSourceType.STATIC) {
      return keySource;
    }

    if (keySource.type === OptionSourceType.API_STATIC) {
      const payload = keySource.payload as ApiStaticOptionSourceProperties;

      return {
        ...keySource,
        payload: {
          ...keySource.payload,
          url: `${payload.url}${
            keySearchKeyword.length > 0 ? `&searchText=${keySearchKeyword}` : ''
          }`,
        },
      };
    }

    return valueSource;
  }, [keySource, keySearchKeyword]);

  const updatedValueSource = useMemo<KeyValueSource>(() => {
    if (valueSource.type === OptionSourceType.STATIC) {
      return valueSource;
    }

    if (valueSource.type === OptionSourceType.API_STATIC) {
      const payload = valueSource.payload as ApiStaticOptionSourceProperties;

      return {
        ...valueSource,
        payload: {
          ...valueSource.payload,
          url: `${payload.url?.replace(
            ':key',
            encodeURIComponent(selection.key.value)
          )}?fieldName=value${
            valueSearchKeyword.length > 0
              ? `&searchText=${valueSearchKeyword}`
              : ''
          }`,
        },
      };
    }

    return valueSource;
  }, [selection.key.value, valueSource, valueSearchKeyword]);

  const [keyOptions, keyOptionsLoading] = useKeyValueOptions(updatedKeySource);

  const [valueOptions, valueOptionsLoading] = useKeyValueOptions(
    updatedValueSource,
    selection.key.value === ''
  );

  const onKeySearchChange = (searchTerm: string) => {
    setKeySearchKeyword && setKeySearchKeyword(searchTerm);
  };

  const onValueSearchChange = (searchTerm: string) => {
    setValueSearchKeyword(searchTerm);
  };

  return (
    <div className="key-value-selection">
      <div className="key-value-selection-inputs">
        <Autocomplete
          model="key"
          single
          optionList={keyOptions}
          loading={keyOptionsLoading}
          onChangeCallBack={(model, value) => {
            const selectedOption = value as AutocompleteOption;

            onChange({
              key: selectedOption,
              values: [],
            });
          }}
          areOptionsLoaded
          classes={{
            root: 'multi-select-field-1',
            paper: 'multi-select-field-paper-1',
          }}
          values={selection.key}
          placeholder="Any Value"
          onSearch={debounce(onKeySearchChange, 300)}
        />

        <Autocomplete
          model="value"
          optionList={valueOptions}
          loading={valueOptionsLoading}
          onChangeCallBack={(model, value) => {
            const selectedOptions = value as Array<AutocompleteOption>;

            onChange({
              ...selection,
              values: selectedOptions,
            });
          }}
          areOptionsLoaded
          classes={{
            root: 'multi-select-field-1',
            paper: 'multi-select-field-paper-1',
          }}
          values={selection.values}
          initialSelectedValues={selection.values}
          enableCheckbox
          placeholder="Any Value"
          onSearch={debounce(onValueSearchChange, 300)}
          limitTags={1}
        />
      </div>

      <div className="key-value-selection-delete-button">
        <Button onClick={onRemove} disabled={disableRemoveButton}>
          <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON} />
        </Button>
      </div>
    </div>
  );
};
